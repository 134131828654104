/* eslint-disable implicit-arrow-linebreak */
import hasAllDocuments from './check-all-documents';

const requiredDocuments = [
  { name: 'Balance', code: 'balance', years: 3, template: null },
  { name: 'Estados Financieros', code: 'eerr', years: 3, template: null },
  {
    name: 'Acta constitutiva',
    code: 'constitutive_act',
    years: null,
    template: null,
  },
];

const chileRequiredDocuments = () => false;

const mexicoRequiredDocuments = (companyDocuments) =>
  hasAllDocuments(companyDocuments, requiredDocuments);

const countryMethod = {
  Mexico: mexicoRequiredDocuments,
  Chile: chileRequiredDocuments,
};

const hasAllRequiredDocuments = (company) => {
  if (!company) {
    return false;
  }
  const companyDocuments = company.documents || [];
  const documentsMethod = countryMethod[company.masterEntity.country.name];

  return documentsMethod(companyDocuments);
};

export { hasAllRequiredDocuments, requiredDocuments };
