import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MARK_COMPANY_TUTORIAL_SEEN from '../../../../graphql/mutations/mark-company-tutorial-as-seen';

const HomeTutorialCompletedBox = ({ text, tutorialCode }) => {
  const company = useSelectedCompany();
  const [markCompanyTutorialAsSeen] = useMutation(MARK_COMPANY_TUTORIAL_SEEN);

  useEffect(() => {
    if (tutorialCode && company?.id) {
      markCompanyTutorialAsSeen({
        variables: {
          companyId: company.id,
          tutorialCode,
        },
      });
    }
  }, [markCompanyTutorialAsSeen, tutorialCode, company]);

  return (
    <Box
      component={Stack}
      spacing={3}
      direction="row"
      alignItems="center"
      justifyContent="center"
      bgcolor="primary.main"
      borderColor="gray.A300"
      borderRadius={2}
      border="1px"
      width="100%"
      height="100%"
      minHeight={72}
    >
      <CheckCircleOutlineIcon color="success" size="large" />
      <Typography variant="medium_2" color="common.white" fontWeight={600}>
        {text}
      </Typography>
    </Box>
  );
};

HomeTutorialCompletedBox.propTypes = {
  text: PropTypes.string.isRequired,
  tutorialCode: PropTypes.string.isRequired,
};

export default HomeTutorialCompletedBox;
