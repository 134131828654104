import { gql } from '@apollo/client';

const CONFIRMING_CREDIT_LINE = gql`
  {
    confirmingAnalytics {
      payrollId
      totalInterest
      totalAmortization
      operation_ExpirationDate
    }
  }
`;

export default CONFIRMING_CREDIT_LINE;
