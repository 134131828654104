import { gql } from '@apollo/client';
import {
  MailInvoicesFields,
  sharedInvoiceInput,
  sharedInvoiceInputFields,
  TypeConnectionFields,
} from '../fragments';
import {
  CessionsFields,
  DocumentFinanceStateFields,
  InvoiceDteTypeFields,
  InvoiceIdFields,
  InvoiceReferenceFields,
  InvoiceStatusFields,
} from '../invoices/fragments';
import { MasterEntityBasicField } from '../customers/fragment';

const MY_SALES_PURCHASES = gql`
  query mySalesPurchases(${sharedInvoiceInputFields}) {
    invoices(${sharedInvoiceInput}) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          dteType {
            ... InvoiceDteTypeFields
          }
          company {
            id
            masterEntity {
              ... MasterEntityBasicField
            }
          }
          receiver {
            ... MasterEntityBasicField
          }
          cessions {
            ... CessionsFields
          }
          uniqueDocumentFinanceState {
            ... DocumentFinanceStateFields
          }
          mailInvoices {
            ... MailInvoicesFields
          }
          ... InvoiceReferenceFields
        }
      }
    }
  }
  ${TypeConnectionFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${InvoiceDteTypeFields}
  ${MasterEntityBasicField}
  ${InvoiceReferenceFields}
  ${CessionsFields}
  ${DocumentFinanceStateFields}
  ${MailInvoicesFields}
`;

export default MY_SALES_PURCHASES;
