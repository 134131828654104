import React, { useState } from 'react';
import { FingoSnackBar } from '@fingo/lib/components/snackBars';
import { useBooleanState } from '@fingo/lib/hooks';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useMutation, useQuery } from '@apollo/client';
import Save from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { PaperHeader } from '@fingo/lib/components/headers';
import { FingoDivider } from '@fingo/lib/components/dividers';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { useTranslation } from 'react-i18next';
import FormControlCheckBox from '../../../components/checkbox/FormControlCheckBox';
import GET_PREFERENCES from '../../../features/profile/graphql/queries/get-preferences';
import SET_PREFERENCES from '../../../features/profile/graphql/mutations/set-preferences';

const MySettings = () => {
  const { t } = useTranslation();
  const country = useGetCountryFromUrl();
  const [mailUnsuscriptions, setMailUnsuscriptions] = useState([]);
  const [whatsappUnsuscriptions, setWhatsappUnsuscriptions] = useState([]);
  const [mailPreferencesOptions, setMailPreferencesOptions] = useState([]);
  const [whatsappPreferencesOptions, setWhatsappPreferencesOptions] = useState([]);
  const [preferenceChange, setPreferenceChange] = useState(false);
  const [openSnackbar, toggleSnackbar] = useBooleanState(false);

  const handleMailPreferenceChange = (event) => {
    const { value } = event.target;
    setMailUnsuscriptions((prev) => (
      prev.includes(value) ? prev.filter((id) => id !== value) : [...prev, value]
    ));
    setPreferenceChange(true);
  };

  const handleWhatsappPreferenceChange = (event) => {
    const { value } = event.target;
    setWhatsappUnsuscriptions((prev) => (
      prev.includes(value) ? prev.filter((id) => id !== value) : [...prev, value]
    ));
    setPreferenceChange(true);
  };

  useQuery(GET_PREFERENCES, {
    variables: { country: country.id },
    onCompleted: (data) => {
      const {
        mail: mailPreferencesData, whatsapp: whatsappPreferencesData,
      } = data.getUserPreferences;
      const unSubscribedMailPreferences = mailPreferencesData.filter((pref) => pref.isUnsubscribed);
      const unSubscribedWhatsappPreferences = whatsappPreferencesData
        .filter((pref) => pref.isUnsubscribed);
      setMailUnsuscriptions(unSubscribedMailPreferences.map((pref) => pref.id));
      setWhatsappUnsuscriptions(unSubscribedWhatsappPreferences.map((pref) => pref.id));
      setMailPreferencesOptions(mailPreferencesData);
      setWhatsappPreferencesOptions(whatsappPreferencesData);
    },
  });

  const [setNewPreferences, { loading: loadingPreferences }] = useMutation(SET_PREFERENCES, {
    variables: {
      input: {
        mail: mailUnsuscriptions,
        whatsapp: whatsappUnsuscriptions,
      },
    },
    onCompleted: () => {
      setPreferenceChange(false);
      toggleSnackbar();
    },
    refetchQueries: [{ query: GET_PREFERENCES, variables: { country: country.id } }],
  });

  const handleSelectAllMail = (event) => {
    const isChecked = event.target.checked;
    setMailUnsuscriptions(isChecked ? [] : mailPreferencesOptions.map((option) => option.id));
    setPreferenceChange(true);
  };

  const handleSelectAllWhatsapp = (event) => {
    const isChecked = event.target.checked;
    setWhatsappUnsuscriptions(
      isChecked ? [] : whatsappPreferencesOptions.map((option) => option.id),
    );
    setPreferenceChange(true);
  };

  return (
    <Stack spacing={1} py={3} px={4}>
      <PaperHeader viewTitle="Configuraciones" />
      <FingoDivider />
      <Grid container sx={{ py: 2 }}>
        <Typography>
          ¡Configura la plataforma de Fingo cómo más te acomode! Recuerda que puedes cambiar las
          configuraciones en cualquier momento
        </Typography>
      </Grid>
      <Grid item container>
        <FormControl component="fieldset">
          <Typography variant="h5" fontWeight={700} sx={{ marginTop: 2, marginBottom: 2 }}>
            Notificaciones por Correo Electrónico
          </Typography>
          <Stack marginLeft={1}>
            <FormGroup>
              <FormControlCheckBox
                handleChange={handleSelectAllMail}
                name="selectAllMailNotifications"
                message="Seleccionar todas las notificaciones de correo electrónico"
              />
              <Stack marginLeft={2}>
                {mailPreferencesOptions.map((option) => (
                  <FormControlCheckBox
                    key={option.id}
                    checked={!mailUnsuscriptions.includes(option.id)}
                    handleChange={handleMailPreferenceChange}
                    name={`mail_preference_${option.id}`}
                    value={option.id.toString()}
                    message={`Recibir correos de ${option.displayName}`}
                  />
                ))}
              </Stack>
            </FormGroup>
          </Stack>
          <Typography variant="h5" fontWeight={700} sx={{ marginTop: 2, marginBottom: 2 }}>
            Notificaciones por WhatsApp
          </Typography>
          <Stack marginLeft={1}>
            <FormGroup>
              <FormControlCheckBox
                handleChange={handleSelectAllWhatsapp}
                name="selectAllWhatsappNotifications"
                message="Seleccionar todas las notificaciones de WhatsApp"
              />
              <Stack marginLeft={2}>
                {whatsappPreferencesOptions.map((option) => (
                  <FormControlCheckBox
                    key={option.id}
                    checked={!whatsappUnsuscriptions.includes(option.id)}
                    handleChange={handleWhatsappPreferenceChange}
                    name={`whatsapp_preference_${option.id}`}
                    value={option.id.toString()}
                    message={`Recibir mensajes de WhatsApp de ${t(option.code)}`}
                  />
                ))}
              </Stack>
            </FormGroup>
          </Stack>
        </FormControl>
      </Grid>
      <Stack>
        <LoadingButton
          loading={loadingPreferences}
          loadingPosition="start"
          startIcon={<Save />}
          variant="contained"
          color="primary"
          size="small"
          disabled={!preferenceChange}
          sx={{ width: '200px', alignSelf: 'start', height: '30px', marginTop: '20px' }}
          onClick={() => setNewPreferences()}
          id="settings-save-button"
          marginTop={2}
        >
          Guardar preferencias
        </LoadingButton>
      </Stack>
      <FingoSnackBar
        open={openSnackbar}
        setOpen={toggleSnackbar}
        message="Sus cambios fueron guardados exitosamente"
        severity="success"
      />
    </Stack>
  );
};

export default MySettings;
