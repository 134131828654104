import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from '@apollo/client';
import dayjs from '@fingo/lib/config/dayjs';
import { numberToMoney, defaultCurrency } from '@fingo/lib/helpers/money';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { ReactComponent as OfferedInvoicesIcon } from '../assets/icons/offered-invoices.svg';
import { ReactComponent as RejectedInvoicesIcon } from '../assets/icons/rejected-invoices.svg';
import { ReactComponent as DebtInvoicesIcon } from '../assets/icons/debt-invoices.svg';
import HOME_SUMMARY from '../graphql/queries/home-summary';

const TODAY = dayjs();

const useHomeSummary = () => {
  const history = useHistory();
  const company = useSelectedCompany();
  const country = useGetCountryFromUrl();
  const ONE_MONTH_AGO = TODAY.subtract(1, 'month').format('YYYY-MM-DD');

  const { data, loading } = useQuery(HOME_SUMMARY, {
    variables: {
      companyId: company?.id,
      rejectedDateIssued_Gte: ONE_MONTH_AGO,
    },
    skip: !company?.id,
    fetchPolicy: 'network-only',
  });

  const homeSummary = useMemo(() => {
    if (!data) return [];

    const { offeredInvoices, rejectedInvoices, debtInvoices } = data;
    return [
      {
        id: 'home-offered-invoices',
        label: 'Facturas aprobadas',
        icon: <OfferedInvoicesIcon />,
        onClick: () => {
          history.push('/app/sales/factoring/offers');
        },
        count: offeredInvoices.totalCount,
        amount: offeredInvoices.totalAmount
          ? numberToMoney(
            offeredInvoices.totalAmount,
            defaultCurrency[country.name],
          )
          : null,
      },
      {
        id: 'home-rejected-invoices',
        label: 'Facturas rechazadas',
        icon: <RejectedInvoicesIcon />,
        onClick: () => {
          history.push('/app/sales/factoring/evaluation');
        },
        count: rejectedInvoices.totalCount,
        amount: rejectedInvoices.totalAmount
          ? numberToMoney(
            rejectedInvoices.totalAmount,
            defaultCurrency[country.name],
          )
          : null,
      },
      {
        id: 'home-expired-invoices',
        label: 'Facturas en mora',
        icon: <DebtInvoicesIcon />,
        onClick: () => {
          history.push('/app/sales/factoring/cessions');
        },
        count: debtInvoices.totalCount,
        amount: debtInvoices.totalAmount
          ? numberToMoney(
            debtInvoices.totalAmount,
            defaultCurrency[country.name],
          )
          : null,
      },
    ].filter(({ amount, count }) => count !== 0 || amount !== null);
  }, [data, country]);

  return { homeSummary, loading };
};

export default useHomeSummary;
