import { gql } from '@apollo/client';

const REQUEST_SURPLUS_WITHDRAWAL = gql`
  mutation RequestSurplusWithdrawal($companyId: Int!, $accountId: Int!) {
    requestSurplusWithdrawal(companyId: $companyId, accountId: $accountId) {
      success
      message
    }
  }
`;

export default REQUEST_SURPLUS_WITHDRAWAL;
