import { gql } from '@apollo/client';
import ConfirmingPayrollFields from '@fingo/lib/graphql/fragments/confirming-payroll-fields';

const CONFIRMING_PAYROLL = gql`
  query confirmingPayroll($payrollId: ID!) {
    confirmingPayroll(payrollId: $payrollId) {
      ...ConfirmingPayrollFields
    }
  }
  ${ConfirmingPayrollFields}
`;

export default CONFIRMING_PAYROLL;
