import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as ProfitsIcon } from '../../assets/icons/profits.svg';
import useHomeSummary from '../../hooks/useHomeSummary';
import HomeSummaryPaperTitle from '../titles/HomeSummaryPaperTitle';
import HomeSummaryList from '../lists/HomeSummaryList';

const HomeSummaryPaper = () => {
  const history = useHistory();
  const { homeSummary, loading } = useHomeSummary();

  const content = useMemo(() => {
    if (loading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      );
    }

    if (!homeSummary.length) {
      return (
        <>
          <Box mt={1.5} mb={5}>
            <ProfitsIcon />
          </Box>
          <Typography variant="small_2" color="gray.A400" fontWeight={500}>
            Solicita financiamiento
          </Typography>
          <Button
            variant="contained"
            onClick={() => history.push('/app/sales/factoring/available')}
            sx={{ mt: 2, height: 42 }}
          >
            Evaluar facturas
          </Button>
        </>
      );
    }

    return <HomeSummaryList homeSummaryItems={homeSummary} />;
  }, [loading, homeSummary]);

  return (
    <Paper
      component={Stack}
      alignItems="center"
      p={2}
      height={1}
      sx={{ borderRadius: 2 }}
    >
      <HomeSummaryPaperTitle />
      {content}
    </Paper>
  );
};

export default HomeSummaryPaper;
