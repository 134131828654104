import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackBars } from '@fingo/lib/hooks';
import dayjs from '@fingo/lib/config/dayjs';
import { FingoDatePicker } from '@fingo/lib/components/datePickers';
import COLLECT from '../graphql/mutations/collect';

const useCollectSteps = (documents) => {
  const [paymentDatetime, setPaymentDatetime] = useState();
  const { addAlert } = useSnackBars();

  const resetFields = useCallback(() => {
    setPaymentDatetime(null);
  }, []);

  const [collect, { loading }] = useMutation(COLLECT, {
    variables: {
      collectionManagerIds: documents.map(
        ({ collectionManager }) => collectionManager.id,
      ),
      paymentDatetime,
    },
    onCompleted: () => {
      addAlert({
        id: 0,
        color: 'primary',
        severity: 'success',
        message: 'Facturas marcadas correctamente',
      });
      resetFields();
    },
  });

  const steps = [
    {
      title: 'Ingresa fecha de pago',
      component: (
        <Stack spacing={2} width={300}>
          <Container component="div" sx={{ width: '100%', p: '0 !important' }}>
            <Typography variant="formLabel">Fecha de pago</Typography>
            <FingoDatePicker
              onChange={(value) => setPaymentDatetime(dayjs(value).toISOString())}
              value={paymentDatetime}
            />
          </Container>
        </Stack>
      ),
      backButton: {
        label: 'Atrás',
      },
      nextButton: {
        disabled: !paymentDatetime || loading,
        label: 'Guardar',
        loading,
        handler: collect,
      },
    },
  ];

  return [steps, resetFields];
};

export default useCollectSteps;
