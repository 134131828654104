import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import ORDERING_PURCHASE_ORDERS from '@fingo/lib/graphql/queries/ordering-purchase-orders';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import KeyCard from '../../../../components/ordering/SignDocumentCard';
import DocumentTable from '../../../../components/ordering/SignDocumentOCTable';
import SIGN_ORDER_ASSIGNMENT_DOCUMENT from '../../../../features/ordering/graphql/mutations/sign-order-assignment-document';

const SignDocument = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const purchaseOrderId = searchParams.get('id');
  const [errorMessage, setErrorMessage] = useState('');
  const { data, loading } = useQuery(
    ORDERING_PURCHASE_ORDERS,
    { variables: { purchaseOrderSecretKey: purchaseOrderId } },
  );
  const [
    signOrder,
    { loading: signOrderLoading },
  ] = useMutation(SIGN_ORDER_ASSIGNMENT_DOCUMENT, {
    variables: {
      id: purchaseOrderId,
    },
    onCompleted: () => {
      history.push('/app/sales/ordering/signdocument/success');
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });
  if (loading) return <LinearProgress />;
  return (
    <Grid container justifyContent="center">
      <Stack maxWidth={1000} width="100%" spacing={2} py={4}>
        <Typography variant="h1" color="primary">Firma de mandato</Typography>
        <Typography variant="h5">Órden de compra aprobada para financiar</Typography>
        <DocumentTable documents={data.purchaseOrders.edges.map((edge) => edge.node)} />
        <KeyCard
          loading={signOrderLoading}
          onConfirm={(secretKey) => signOrder({ variables: { secretKey } })}
          errorMessage={errorMessage}
        />
      </Stack>
    </Grid>
  );
};

export default SignDocument;
