import React, { useState } from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import Grid from '@mui/material/Grid';
import { SophiaHead, Graphs } from '../../components/sophia';
import { SophiaTourGuide } from '../../components/joyRides';
import useTutorial from '../../hooks/useTutorial';

const TUTORIAL_CODE = 'sophia_tutorial';

const Tracker = () => {
  const [isReady, setIsReady] = useState(false);
  const user = useGetUser();
  const [runGuide, toggleRunGuide] = useTutorial(TUTORIAL_CODE, isReady);

  return (
    <Grid container id="name-container" pb={3}>
      <PaperHeader
        type="sophia"
        viewTitle={
          user.selectedCompany ? user.selectedCompany.name : user.completeName
        }
        openJoyride={toggleRunGuide}
      />
      <SophiaHead setIsReady={setIsReady} />
      <Graphs />
      <SophiaTourGuide run={runGuide} setRun={toggleRunGuide} />
    </Grid>
  );
};

export default Tracker;
