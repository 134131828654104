import { gql } from '@apollo/client';

export const GET_COLLECTION_MAIL_ENUM = gql`
  query GetCollectionMailEnum {
    getCollectionMailEnum {
      value
      label
    }
  }
`;

export const GET_RATIFICATION_MAIL_ENUM = gql`
  query GetRatificationMailEnum {
    getRatificationMailEnum {
      value
      label
    }
  }
`;

export const GET_RATIFICATION_ACTIONS_ENUM = gql`
  query GetRatificationActionEnum {
    getRatificationActionEnum {
      value
      label
    }
  }
`;

export const GET_RATIFICATION_CHANNEL_ENUM = gql`
  query GetRatificationChannelEnum {
    getRatificationChannelEnum {
      value
      label
    }
  }
`;

export const GET_COLLECTION_MAIL_PREVIEW = gql`
  query GetCollectionMailPreview(
    $mailType: String!
    $collectionManagerIds: [Int]!
  ) {
    collectionMailPreview(
      mailType: $mailType
      collectionManagerIds: $collectionManagerIds
    )
  }
`;

export const GET_COLLECTIONS_BY_PRIORITY = gql`
  query GetCollectionsByPriority {
    getCollectionsByPriority {
      collectionPriority
      count
      amount
      debtors
    }
  }
`;

export const GET_COLLECTIONS_BY_EXPIRATION = gql`
  query GetCollectionsByExpiration {
    getCollectionsByExpiration {
      dateGroup
      values {
        priority
        count
        amount
      }
    }
  }
`;

export const GET_COLLECTIONS_BY_DEBT = gql`
  query GetCollectiosnByDebt {
    getCollectionsByDebt {
      expiredAmount
      unexpiredAmount
    }
  }
`;
