import { gql } from '@apollo/client';

const GET_COMPANY_PREFERENCES = gql`
  query {
    getCompanyPreferences {
      suppliers_Merit {
        id
      }
      suppliers_Acknowledgment {
        id
      }
    }
  }
`;

export default GET_COMPANY_PREFERENCES;
