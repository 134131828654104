import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import GET_USER from '@fingo/lib/graphql/queries/get-user';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { useAvailableBanks, useBankAccountTypes, useSelectedCompany } from '@fingo/lib/hooks';
import ValidationTextField from '@fingo/lib/components/inputs/ValidationTextFieldInput';
import EDIT_BANK_ACCOUNT from '@fingo/lib/graphql/mutations/edit-bank-account';
import CREATE_BANK_ACCOUNT from '@fingo/lib/graphql/mutations/create-bank-account';
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { getErrorMessage } from '../../../helpers/credentials';

const BankAccountForm = ({ bankAccounts, selectedBankAccount, onSubmit, onError, onClose }) => {
  const selectedCompany = useSelectedCompany();
  const country = useGetCountryFromUrl();
  const currencies = country?.currencies || [];
  const { accountTypes, loadingAccountTypes } = useBankAccountTypes(country.id);
  const { banks } = useAvailableBanks(country.id);
  const isNew = useMemo(() => selectedBankAccount === null, [selectedBankAccount]);
  const [formData, setFormData] = useState({
    number: { value: parseInt(selectedBankAccount?.accountNumber, 10) || '', error: '' },
    name: { value: selectedBankAccount?.accountName || '', error: '' },
    email: { value: selectedBankAccount?.accountEmail || '', error: '' },
    bank: { value: selectedBankAccount?.bankName || '', error: '' },
    accountType: { value: selectedBankAccount?.accountType || '', error: '' },
    currency: { value: selectedBankAccount?.currency || '', error: '' },
  });

  const [createBankAccount, { loading: createBankAccountLoading }] = useMutation(
    CREATE_BANK_ACCOUNT,
    {
      refetchQueries: [GET_USER],
      awaitRefetchQueries: true,
      onCompleted: ({ createBankAccount: { bankAccountCreated } }) => {
        onSubmit(bankAccountCreated);
      },
    },
  );

  const [editBankAccount, { loading: editBankAccountLoading }] = useMutation(EDIT_BANK_ACCOUNT, {
    refetchQueries: [GET_USER],
    awaitRefetchQueries: true,
    onCompleted: ({ editBankAccount: { bankAccount: editedBankAccount } }) => {
      onSubmit(editedBankAccount);
    },
  });

  const loading = useMemo(
    () => createBankAccountLoading || editBankAccountLoading,
    [createBankAccountLoading, editBankAccountLoading],
  );

  const onChangeHandler = useCallback(
    ({ target: { name, value } }) => {
      setFormData((prevState) => ({ ...prevState, [name]: { ...prevState[name], value } }));
    },
    [formData],
  );

  const onSubmitHandler = useCallback(() => {
    const { number, name, email, bank, accountType, currency } = formData;
    const errorMessage = getErrorMessage(formData, bankAccounts);
    if (errorMessage) {
      onError({ message: errorMessage });
      return;
    }
    if (isNew) {
      createBankAccount({
        variables: {
          bankName: bank.value,
          accountEmail: email.value,
          accountName: name.value,
          accountNumber: number.value,
          accountRut: selectedCompany.masterEntity.rut,
          accountType: accountType.value,
          currency: currency.value,
        },
      });
    } else {
      editBankAccount({
        variables: {
          id: selectedBankAccount.id,
          bankName: bank.value,
          accountEmail: email.value,
          accountName: name.value,
          accountNumber: number.value,
          accountType: accountType.value,
          currency: currency.value,
        },
      });
    }
  }, [formData]);

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <IconButton onClick={onClose} size="large">
          <ArrowBackIos />
        </IconButton>
        <Typography color="primary" variant="h6" alignSelf="center">
          {isNew ? 'Agrega una' : 'Edita tu'} cuenta bancaria
        </Typography>
      </Stack>
      <Typography fontWeight="bold">RUT</Typography>
      <TextField
        variant="outlined"
        name="rut"
        value={selectedCompany.masterEntity.displayNationalIdentifier}
        disabled
        fullWidth
      />
      <Typography fontWeight="bold">Titular de la cuenta</Typography>
      <TextField
        variant="outlined"
        name="name"
        value={formData.name.value}
        error={formData.name.error !== ''}
        helperText={formData.name.error}
        onChange={onChangeHandler}
        fullWidth
      />
      <Typography fontWeight="bold">Número de la cuenta</Typography>
      <TextField
        variant="outlined"
        name="number"
        type="number"
        value={formData.number.value}
        error={formData.number.error !== ''}
        helperText={formData.number.error}
        onChange={onChangeHandler}
        fullWidth
      />
      <Typography fontWeight="bold">Banco</Typography>
      <FormControl variant="outlined" fullWidth>
        <Select name="bank" value={formData.bank.value} onChange={onChangeHandler}>
          <MenuItem value="" disabled>
            Elige un banco
          </MenuItem>
          {banks?.getBankOptionsByCountry.map((bank) => (
            <MenuItem key={bank.name} value={bank.name}>
              {bank.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography fontWeight="bold">Tipo de cuenta</Typography>
      <FormControl variant="outlined" fullWidth>
        <Select name="accountType" value={formData.accountType.value} onChange={onChangeHandler}>
          <MenuItem value="" disabled>
            Elige un tipo de cuenta
          </MenuItem>
          {!loadingAccountTypes
            && accountTypes?.getBankAccountTypesByCountry.map(({ name }) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Typography fontWeight="bold">Email</Typography>
      <ValidationTextField
        variant="outlined"
        name="email"
        value={formData.email.value}
        onChange={onChangeHandler}
        validationtype="email"
        fullWidth
        fontWeight="bold"
      />
      <Typography fontWeight="bold">Divisa</Typography>
      <FormControl variant="outlined" fullWidth>
        <Select name="currency" value={formData.currency.value} onChange={onChangeHandler}>
          <MenuItem value="" disabled>
            Divisa
          </MenuItem>
          {currencies.map((currency) => (
            <MenuItem key={currency.isoCode} value={currency.isoCode}>
              {currency.isoCode}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        sx={{ alignSelf: 'center', justifySelf: 'center' }}
        size="small"
        onClick={onSubmitHandler}
        endIcon={loading && <CircularProgress size={16} />}
        disabled={loading}
      >
        {isNew ? 'Agregar' : 'Editar'} cuenta
      </Button>
    </Stack>
  );
};

BankAccountForm.propTypes = {
  bankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      bankName: PropTypes.string.isRequired,
      accountNumber: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedBankAccount: PropTypes.shape({
    id: PropTypes.string,
    bankName: PropTypes.string,
    accountNumber: PropTypes.string,
    accountType: PropTypes.string,
    accountEmail: PropTypes.string,
    accountName: PropTypes.string,
    currency: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

BankAccountForm.defaultProps = {
  selectedBankAccount: null,
};

export default BankAccountForm;
