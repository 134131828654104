import { gql } from '@apollo/client';

const EDIT_BANK_ACCOUNT = gql`
  mutation EditBankAccount(
    $id: ID!
    $accountEmail: String!
    $accountName: String!
    $accountNumber: String!
    $accountType: String!
    $bankName: String!
    $currency: String!
  ) {
    editBankAccount(
      id: $id
      accountEmail: $accountEmail
      accountName: $accountName
      accountNumber: $accountNumber
      accountType: $accountType
      bankName: $bankName
      currency: $currency
    ) {
      bankAccount {
        id
        accountEmail
        accountName
        accountNumber
        accountRut
        accountType
        bankName
        active
        currency {
          id
          isoCode
        }
      }
    }
  }
`;

export default EDIT_BANK_ACCOUNT;
