import { gql } from '@apollo/client';
import MoneyFields from './money/money-fields';
import { BankAccountFields, MasterEntityFields } from '../fragments';
import { RiskBlacklistFields } from '../customers/fragment';

const ConfirmingPayrollFields = gql`
  fragment ConfirmingPayrollFields on ConfirmingPayrollType {
    id
    createdAt
    status {
      id
      status
    }
    totalAmount @client
    totalCount @client
    supplierCount @client
    statusInfo @client
    companyName @client
    companyBlacklist @client
    companyRut @client
    areAllOperationsEqual @client
    operations {
      id
      contactEmail
      expirationDate
      monthlyRate
      supplierName @client
      supplierRut @client
      totalAmount @client
      totalCount @client
      statusInfo @client
      bankAccount {
        ...BankAccountFields
      }
      confirmingSet {
        id
        folio
        status
        dateIssued
        dateExpiration
        receiver {
          id
          masterEntity {
            ...MasterEntityFields
            riskBlacklist {
              ...RiskBlacklistFields
            }
          }
        }
        company {
          id
          masterEntity {
            ...MasterEntityFields
          }
          bankAccounts {
            ...BankAccountFields
          }
        }
        invoice {
          id
          mailInvoices {
            id
            createdAt
          }
        }
        amountWithIva {
          ...MoneyFields
        }
      }
    }
  }
  ${MoneyFields}
  ${BankAccountFields}
  ${MasterEntityFields}
  ${RiskBlacklistFields}
`;

export default ConfirmingPayrollFields;
