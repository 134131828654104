import { gql } from '@apollo/client';

const UPLOAD_SII_CREDENTIALS = gql`
  mutation UploadSiiCredentials($companyId: Int!, $siiPassword: String!) {
    uploadSiiCredentials(companyId: $companyId, siiPassword: $siiPassword) {
      success
      message
    }
  }
`;

export default UPLOAD_SII_CREDENTIALS;
