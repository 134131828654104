import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as WhatsAppIcon } from '../assets/icons/whatsapp.svg';
import { ReactComponent as EmailIcon } from '../assets/icons/mail.svg';

const ExecutiveContactInformation = ({ executive }) => {
  const whatsAppClickHandler = useCallback(
    () => window.open(executive?.whatsAppUrl, '_blank'),
    [executive],
  );

  const content = useMemo(() => {
    if (!executive) {
      return (
        <Typography
          variant="small_2"
          fontWeight={600}
          color="gray.A700"
          textAlign="center"
        >
          ¿Tienes dudas?
        </Typography>
      );
    }
    return (
      <>
        {executive.phoneNumber && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <WhatsAppIcon cursor="pointer" onClick={whatsAppClickHandler} />
            <Typography
              variant="small_2"
              fontWeight={600}
              color="gray.A700"
              onClick={whatsAppClickHandler}
              sx={{ cursor: 'pointer' }}
            >
              {executive.phoneNumber}
            </Typography>
          </Stack>
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          <EmailIcon />
          <Typography variant="small_2" fontWeight={600} color="gray.A700">
            {executive.email}
          </Typography>
        </Stack>
      </>
    );
  }, [executive]);

  return (
    <Stack direction={{ lg: 'row' }} alignItems="center" ml="auto" spacing={1}>
      {content}
    </Stack>
  );
};

ExecutiveContactInformation.propTypes = {
  executive: PropTypes.shape({
    whatsAppUrl: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
};

ExecutiveContactInformation.defaultProps = {
  executive: null,
};

export default ExecutiveContactInformation;
