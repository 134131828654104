import React from 'react';
import { useSelectedCompany } from '@fingo/lib/hooks';
import Contacts from '../../supplierAutomate/Contacts';
import GET_PROVIDERS from '../../../../graphql/queries/get-providers';

const MyProviders = () => {
  const selectedCompany = useSelectedCompany();
  return (
    <Contacts
      queryContacts={GET_PROVIDERS}
      queryVariables={{ companySuppliers: selectedCompany?.masterEntity.id }}
      title="Proveedores"
      relation="suppliers"
    />
  );
};

export default MyProviders;
