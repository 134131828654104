import { gql } from '@apollo/client';

const CONFIRMING_INVOICE_EXPIRATION_ANALYTICS = gql`
  query confirmingAnalytics(
    $expirationDateLte: DateTime!
    $expirationDateGte: DateTime!
    $groupBy: String!
  ) {
    confirmingInvoiceExpirationAnalytics(
      dateLte: $expirationDateLte
      dateGte: $expirationDateGte
      groupBy: $groupBy
    )
  }
`;

export default CONFIRMING_INVOICE_EXPIRATION_ANALYTICS;
