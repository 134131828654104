import { gql } from '@apollo/client';
import { MasterEntityBasicField } from '../customers/fragment';
import { BackOfficeUserFields } from '../fragments';

export const GET_BACK_OFFICE_USER = gql`
query GetBackOfficeUser {
  getUser {
    ...BackOfficeUserFields
  }
}
${BackOfficeUserFields}
`;

export const REGISTER_SENEGOCIA_PREFORM = gql`
  query RegisterSenegocia($token: String!) {
    senegociaRegisterInfo(token: $token) {
      user {
        firstName
        lastName
        email
        rut
        phoneNumber
        completeName @client
      }
      company {
        id
        rut
        name
      }
    }
  }
`;

export const GET_COMMERCIAL_EXECUTIVES_INFORMATION = gql`
  query getExecutiveAssignmentInformation($active: Boolean, $first: Int, $offset: Int, $orderBy: String) {
    getExecutiveAssignmentInformation(active: $active, first: $first, offset: $offset, orderBy: $orderBy) {
      user {
        id
        firstName
        lastName
        completeName @client
      }
      id
      active
      hubspotId
      tycActiveAssignation
      loginActiveAssignation
      clickActiveAssignation
      clickAssignationProportion
      loginAssignationProportion
      weeklyAssignations
      monthlyAssignations
      hubspotMonthlyAssignations
    }
}`;

export const GET_GENERIC_EXECUTIVES = gql`
  query getUsers(
    $executive_Active: Boolean, 
    $groups_Name: String,
    $groups_Name_Iexact: String,
    $first: Int, 
    $offset: Int, 
    $orderBy: String
    $globalFilter: String
    ) {
    getUsers(
      executive_Active: $executive_Active, 
      groups_Name: $groups_Name,
      groups_Name_Iexact: $groups_Name_Iexact,
      first: $first, 
      offset: $offset, 
      orderBy: $orderBy
      globalFilter: $globalFilter
      ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          graphqlId @client
          firstName
          lastName
          completeName @client
          executive {
            id
            active
            hubspotId
            tycActiveAssignation
            loginActiveAssignation
            clickActiveAssignation
            clickAssignationProportion
            loginAssignationProportion
            weeklyAssignations
            monthlyAssignations
            hubspotMonthlyAssignations
          }
        }
      }
    }
}`;

export const MY_ASSIGNED_COMPANIES = gql`
  query myAssignedCompanies (
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $orderBy: String
    $masterEntity_Name_Icontains: String
    $masterEntity_CountryId: Int
  ) {
    myCompanies (
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      orderBy: $orderBy
      masterEntity_Name_Icontains: $masterEntity_Name_Icontains
      masterEntity_CountryId: $masterEntity_CountryId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
          masterEntity {
            ... MasterEntityBasicField
            country {
              id
              name
            }
          }
        }
      }
    }
  }
  ${MasterEntityBasicField}
`;

export const OAUTH_PROVIDER = gql`
  query oauthProvider(
    $provider: String!
  ) {
    oauthProvider(
      provider: $provider
    ) {
      id
      clientId
      provider
    }
  }
`;

export const GET_USER_MAILS = gql`
  query getUsers($first: Int, $offset: Int, $orderBy: String, $isStaff: Boolean, $globalFilter: String) {
    getUsers(first: $first, offset: $offset, orderBy: $orderBy, isStaff: $isStaff, globalFilter: $globalFilter) {
      edges {
        node {
          id
          email
        }
      }
    }
  }
`;
