import { gql } from '@apollo/client';
import { UserFields } from '@fingo/lib/graphql/fragments';

const MARK_TUTORIAL_SEEN = gql`
  mutation MarkTutorialSeen($tutorialCode: String!) {
    markTutorialAsSeen(tutorialCode: $tutorialCode) {
      user {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

export default MARK_TUTORIAL_SEEN;
