import { useMutation } from '@apollo/client';
import { useInputRut, useSelectedCompany } from '@fingo/lib/hooks';
import UPDATE_INVOICE_PROVIDER_CREDENTIALS from '@fingo/lib/graphql/mutations/upload-invoice-provider-credentials';

const useUploadInvoiceProviderCredentials = () => {
  const company = useSelectedCompany();
  const { nationalIdentifier, updateNationalIdentifier, isValid } = useInputRut();
  const [uploadCredentials, { loading, error }] = useMutation(
    UPDATE_INVOICE_PROVIDER_CREDENTIALS,
    { variables: { companyId: company.id } },
  );

  return {
    rut: nationalIdentifier,
    updateRut: updateNationalIdentifier,
    isValid,
    uploadCredentials,
    loading,
    error,
  };
};

export default useUploadInvoiceProviderCredentials;
