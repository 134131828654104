import { gql } from '@apollo/client';

const ADD_COMPANY_PREFERENCES = gql`
  mutation addCompanyEmailPreference($companyId: Int!, $mail: String!) {
    addCompanyEmailPreference(companyId: $companyId, mail: $mail) {
      success
      message
    }
  }
`;

export default ADD_COMPANY_PREFERENCES;
