import { gql } from '@apollo/client';

const SET_PREFERENCES = gql`
  mutation setUserPreferences($input: UserPreferenceInputType!) {
    setUserPreferences(input: $input) {
      success
      message
    }
  }
`;

export default SET_PREFERENCES;
