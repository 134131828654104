import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useSelectedCompany } from '@fingo/lib/hooks';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import AttachMoneyOutlined from '@mui/icons-material/AttachMoneyOutlined';
import ReceiptOutlined from '@mui/icons-material/ReceiptOutlined';
import CreditCardOutlined from '@mui/icons-material/CreditCardOutlined';
import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import SummaryCard from '../cards/SummaryCard';
import SOPHIA_TRACKER from '../../features/analytics/graphql/queries/sophia-tracker';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '2rem',
  },
  card: {
    marginBottom: '1rem',
  },
}));

const SophiaHead = ({ setIsReady }) => {
  const classes = useStyles();
  const selectedCompany = useSelectedCompany();
  const { data: tracker, loading } = useQuery(
    SOPHIA_TRACKER,
    {
      variables: { companyId: selectedCompany?.id },
      onCompleted: () => setIsReady(),
      notifyOnNetworkStatusChange: true,
      skip: !selectedCompany,
    },
  );
  return (
    <Grid
      container
      justifyContent="center"
      classes={{ root: classes.container }}
    >
      <Grid item container spacing={2} justifyContent="center">
        <Grid id="sales-numbers" container item xs={12} lg={6} spacing={2}>
          <Grid item xs={12} md={6} classes={{ root: classes.card }}>
            <SummaryCard
              title="Ventas del mes"
              amountType="money"
              amount={tracker?.sophiaTracker.sales}
              tooltipTitle="Este es el monto de tu facturación emitida del mes, comparado con el mes anterior"
              previousAmount={tracker?.sophiaTracker.salesPast}
              Icon={AttachMoneyOutlined}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={6} classes={{ root: classes.card }}>
            <SummaryCard
              title="Compras del mes"
              amountType="money"
              amount={tracker?.sophiaTracker.shop}
              tooltipTitle="Este es el monto de tu facturación recibida del mes actual, comparado con el mes anterior"
              previousAmount={tracker?.sophiaTracker.shopPast}
              Icon={ReceiptOutlined}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid id="accounts-receivable" container item xs={12} lg={6} spacing={2}>
          <Grid item xs={12} md={6} classes={{ root: classes.card }}>
            <SummaryCard
              title="Cuentas por cobrar"
              amountType="money"
              amount={tracker?.sophiaTracker.salesExpires}
              tooltipTitle="Este es el monto de tu facturación emitida, próximas a vencer en los siguientes 30 días"
              Icon={AccountBalanceOutlined}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={6} classes={{ root: classes.card }}>
            <SummaryCard
              title="Cuentas por pagar"
              amountType="money"
              tooltipTitle="Este es el monto de tu facturación recibida próximos a vencer dentro de los siguientes 30 días"
              amount={tracker?.sophiaTracker.shopExpires}
              Icon={CreditCardOutlined}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SophiaHead.propTypes = {
  setIsReady: PropTypes.func.isRequired,
};

export default SophiaHead;
