import { useEffect } from 'react';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';

const GoogleTagManagerProvider = ({ children }) => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
    }
  }, []);

  return children;
};

GoogleTagManagerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GoogleTagManagerProvider;
