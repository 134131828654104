import { gql } from '@apollo/client';
import { UserIdentification } from '../user/fragments';

const ADD_COLLECTION_ACTION = gql`
  mutation AddCollectionAction(
    $collectionManagerIds: [Int]!
    $actionType: CollectionActionEnum!
    $comment: String
    $collectionContactIds: [Int]!
  ) {
    addCollectionAction(
      collectionAction: {
        collectionManagerIds: $collectionManagerIds
        actionType: $actionType
        comment: $comment
        collectionContactIds: $collectionContactIds
      }
    ) {
      collectionManagers {
        id
        collector {
          ...UserIdentification
        }
        collectionPriority {
          value
        }
        actions {
          id
          comment
          createdAt
          actionType
          contacts {
            id
            name
            position
            email
            phoneNumber
          }
          author {
            ...UserIdentification
          }
        }
      }
    }
  }
  ${UserIdentification}
`;

export default ADD_COLLECTION_ACTION;
