import { gql } from '@apollo/client';

const REGISTER_USER = gql`
  mutation RegisterUser(
    $email: String!
    $firstName: String
    $lastName: String
    $password: String!
    $phoneNumber: String
    $utmCampaign: String
    $origin: String
  ) {
    registerUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      phoneNumber: $phoneNumber
      utmCampaign: $utmCampaign
      origin: $origin
    ) {
      response
    }
  }
`;

export default REGISTER_USER;
