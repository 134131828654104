import useChileHomeFirstOperationTutorial from '../hooks/useChileHomeFirstOperationTutorial';
import useMexicoHomeFirstCreditLineTutorial from '../hooks/useMexicoHomeFirstCreditLineTutorial';
import useMexicoHomeFirstOperationTutorial from '../hooks/useMexicoHomeFirstOperationTutorial';

export const CHILE_HOME_FIRST_OPERATION = 'CHILE_HOME_FIRST_OPERATION';

export const MEXICO_HOME_FIRST_CREDIT_LINE = 'MEXICO_HOME_FIRST_CREDIT_LINE';

export const MEXICO_HOME_FIRST_OPERATION = 'MEXICO_HOME_FIRST_OPERATION';

export const COUNTRY_TUTORIALS = {
  Chile: [
    {
      code: CHILE_HOME_FIRST_OPERATION,
      title: 'Completa tu flujo de financiamiento',
      hook: useChileHomeFirstOperationTutorial,
      completedText:
        'Realizaste tu primera solicitud de financiamiento con éxito',
    },
  ],
  Mexico: [
    {
      code: MEXICO_HOME_FIRST_CREDIT_LINE,
      title: '¡Obtén tu línea! Te guiamos paso a paso',
      hook: useMexicoHomeFirstCreditLineTutorial,
      completedText:
        'Tu línea de crédito está aprobada. Ahora puedes solicitar financiamiento',
    },
    {
      code: MEXICO_HOME_FIRST_OPERATION,
      title: '¡Te acompañamos en tu primer financiamiento!',
      hook: useMexicoHomeFirstOperationTutorial,
      completedText:
        'Realizaste tu primera solicitud de financiamiento con éxito',
    },
  ],
};
