import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { logIn } from '@fingo/lib/apollo/helpers/auth';
import { useSnackBars } from '@fingo/lib/hooks';
import LOGIN from '@fingo/lib/graphql/mutations/login';
import Cookies from 'universal-cookie';
import { authEmailVar } from '../graphql/reactive-variables';

const useLogin = (redirectTo) => {
  const { addAlert } = useSnackBars();
  const history = useHistory();
  const cookies = new Cookies();
  const utmCampaign = cookies.get('utm_campaign') || null;

  const [login] = useMutation(LOGIN, {
    variables: {
      utmCampaign,
    },
    onCompleted: ({ tokenAuth }) => {
      logIn(tokenAuth);
      history.push(redirectTo);
      authEmailVar('');
    },
    onError: ({ message }) => {
      if (message === 'Please enter valid credentials') {
        addAlert({
          id: 'register failed',
          message: 'El correo o la contraseña no son correctos.',
          severity: 'error',
          color: 'error',
        });
      }
      if (message === 'failed login attempts limit exceded') {
        addAlert({
          id: 'register failed',
          message:
            'Se ha bloqueado su cuenta. Espere un tiempo para volver a intentar o reestablezca su contraseña.',
          severity: 'error',
          color: 'error',
        });
      }
    },
  });

  return { signIn: login };
};

export default useLogin;
