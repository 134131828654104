import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '@fingo/lib/components/router/PrivateRoute';
import FingoCircularProgressLoader from '@fingo/lib/components/loader/FingoCircularProgressLoader';
import appRoutes from './app-routes';

const AppRoutes = () => (
  <Suspense fallback={<FingoCircularProgressLoader />}>
    <Switch>
      {appRoutes.map(({ path, component, private: isPrivate }) => {
        if (isPrivate) {
          return <PrivateRoute key={path} path={path} component={component} />;
        }
        return <Route key={path} exact path={path} component={component} />;
      })}
    </Switch>
  </Suspense>
);

export default AppRoutes;
