import { useQuery } from '@apollo/client';
import { preofferSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { cleanPreOfferShoppingCart } from '@fingo/lib/apollo/helpers/shopping-cart';
import PREOFFER_SHOPPING_CART from '@fingo/lib/graphql/shopping/queries/preoffer-shopping-cart';
import useEvaluatePreoffers from '@fingo/lib/hooks/useEvaluatePreoffers';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import OrderingDebtWarning from '../../ordering/OrderingDebtWarning';
import ShoppingCartSummary from '../ShoppingCartSummary/ShoppingCartSummary';
import ShoppingCartSummaryActionButton from '../ShoppingCartSummary/ShoppingCartSummaryActionButton';

const ChilePreofferShoppingStepOne = ({
  nextStep,
  setEvaluatedInvoicesId,
  summaryCart,
}) => {
  const {
    data: { selectedPreofferIds },
  } = useQuery(preofferSelectedQuery);
  const { data: shoppingCartData, loading: loadingCart } = useQuery(
    PREOFFER_SHOPPING_CART,
    { variables: { invoicesId: selectedPreofferIds } },
  );

  const finishMutation = useCallback(() => {
    setEvaluatedInvoicesId(selectedPreofferIds);
    cleanPreOfferShoppingCart();
    nextStep();
  }, [nextStep]);

  const { requestPreoffer, loading } = useEvaluatePreoffers(finishMutation);

  return (
    <ShoppingCartSummary
      cartType="preoffer"
      summaryItems={summaryCart}
      cleanFunction={cleanPreOfferShoppingCart}
      warningAlert={(
        <OrderingDebtWarning
          orderingDebt={shoppingCartData?.preofferShoppingCart.orderingDebt}
          hasPurchaseOrdersNearToDebt={
            shoppingCartData?.preofferShoppingCart.hasPurchaseOrdersNearToDebt
          }
        />
      )}
      loading={loadingCart}
      actionButton={(
        <ShoppingCartSummaryActionButton
          id="cart-preoffer-evaluate"
          label="Evaluar"
          loading={loading}
          onClick={requestPreoffer}
        />
      )}
    />
  );
};

ChilePreofferShoppingStepOne.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setEvaluatedInvoicesId: PropTypes.func.isRequired,
  summaryCart: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      money: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default ChilePreofferShoppingStepOne;
