import { gql } from '@apollo/client';
import { UserFields } from '@fingo/lib/graphql/fragments';

const UPDATE_DUMMY_PASSWORD = gql`
  mutation UpdateDummyPassword(
    $newPassword: String!
    $mailProvided: String!
    $phoneNumber: String!
  ) {
    updateDummyPassword(
      newPassword: $newPassword
      mailProvided: $mailProvided
      phoneNumber: $phoneNumber
    ) {
      success
      message
      user {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

export default UPDATE_DUMMY_PASSWORD;
