import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { NavbarButton } from '@fingo/lib/components/buttons';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import Badge from '@mui/material/Badge';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import Bell from '@mui/icons-material/Notifications';
import DoubleMenu from '../../../components/menu/DoubleMenu';
import NotificationText from './NotificationText';
import READ_NOTIFICATIONS from '../../../graphql/mutations/read-notifications';

const useStyles = makeStyles((theme) => ({
  read: {
    backgroundColor: theme.palette.primary.A100,
    '&:hover': { backgroundColor: '#FFE5EE' },
  },
}));

const Notifications = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = useGetUser();
  const notificationData = user && user.all.edges;
  const mentionData = user && user.mentions.edges;
  const notificationCount = user?.notificationsUnreadCount?.totalCount;
  const [notificationsAnchorElement, setNotificationsAnchorElement] = useState(null);
  const openNotificationsMenu = (event) => {
    setNotificationsAnchorElement(event.currentTarget);
  };
  const [notifications, setNotifications] = useState(notificationData);
  const [mentions, setMentions] = useState(mentionData);
  const [notificationNumber, setNotificationNumber] = useState(notificationCount);
  const closeNotificationsMenu = () => {
    setNotificationsAnchorElement(null);
  };
  const [readNotification] = useMutation(READ_NOTIFICATIONS);

  const handleFrontRead = (ids, tabIndex) => {
    const newNotifications = [notifications, mentions][tabIndex];
    const newNotificationSetter = [setNotifications, setMentions][tabIndex];
    let newCount = notificationNumber;
    newNotificationSetter(newNotifications.map(
      (item) => {
        if (ids.includes(item.node.id)) {
          newCount -= 1;
          return { ...item, node: { ...item.node, unread: false } };
        }
        return item;
      },
    ));
    setNotificationNumber(newCount);
  };

  const notificationClicked = (notification, tabIndex) => {
    if (notification.unread) {
      readNotification({ variables: { notificationIds: [notification.id] } });
      handleFrontRead([notification.id], tabIndex);
    }
    history.push({
      pathname: notification.redirectionPath,
      search: notification.redirectionSearch });
    closeNotificationsMenu();
  };

  // eslint-disable-next-line no-unused-vars
  const markAllNotificationAsRead = (tabIndex) => {
    const newNotifications = [notifications, mentions][tabIndex];
    readNotification({
      variables: { notificationIds: [...newNotifications.map(({ node }) => node.id)] },
    });
    handleFrontRead([...newNotifications.map(({ node }) => node.id)], tabIndex);
  };
  const goToNotificationCenter = () => {
    history.push('/app/notifications');
    closeNotificationsMenu();
  };
  const mapperFunction = (list, tabIndex) => (
    list.map(({ node: item }) => (
      <ListItem
        button
        onClick={() => { notificationClicked(item, tabIndex); }}
        className={item.unread ? classes.read : null}
        key={item.id}
      >
        <Grid direction="row" style={{ flexWrap: 'nowrap' }} container>
          <Grid item style={{ alignSelf: 'center', marginRight: 8 }}>
            <FiberManualRecord
              fontSize="small"
              color={item.level === 'MENTION' ? 'primary' : 'inherit'}
            />
          </Grid>
          <NotificationText
            notificationObject={item}
          />
        </Grid>
      </ListItem>
    ))
  );
  return user
    && !user.requireChangePassword
    && user.selectedCompany
    && (user.selectedCompany.navBarTitles.has('my_purchases')) && (
    <>
      <NavbarButton onClick={openNotificationsMenu} id="nav-notifications">
        <Badge
          badgeContent={notificationNumber}
          color="primary"
        >
          <Bell />
        </Badge>
      </NavbarButton>
      <DoubleMenu
        link2Action={goToNotificationCenter}
        link2Text="Ver centro de notificaciones"
        leftId="notifications-tab-all"
        leftTitle="Todas"
        rightId="notifications-tab-mentions"
        rightTitle="Menciones"
        leftContent={mapperFunction(notifications, 0)}
        rightContent={mapperFunction(mentions, 1)}
        anchorEl={notificationsAnchorElement}
        keepMounted
        open={Boolean(notificationsAnchorElement)}
        onClose={closeNotificationsMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: {
            width: 600,
          },
        }}
      />
    </>
  );
};

export default Notifications;
