import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import REQUEST_SURPLUS_WITHDRAWAL from '@fingo/lib/graphql/mutations/request-surplus-withdrawal';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import FingoDialog from '../dialogs/FingoDialog';
import { AccountsListWithAdd } from '../bank';

const SurplusDialog = ({ open, toggleOpen }) => {
  const [selectedAccount, setSelectedAccount] = useState();
  const selectedCompany = useSelectedCompany();

  const [requestSurplus, { loading }] = useMutation(
    REQUEST_SURPLUS_WITHDRAWAL,
    {
      variables: {
        companyId: selectedCompany.id,
        accountId: selectedAccount?.id,
      },
      awaitRefetchQueries: true,
    },
  );

  const handleSubmit = () => {
    requestSurplus();
    toggleOpen();
  };

  return (
    <FingoDialog
      open={open}
      handleClose={toggleOpen}
      title="Seleccione la cuenta del banco"
      dialogActionButton={(
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          disabled={!selectedAccount}
          id="continue-surplus-withdrawal"
        >
          Continuar
        </LoadingButton>
      )}
    >
      <AccountsListWithAdd
        setSelectedAccountInParent={setSelectedAccount}
        xs={12}
        setCreatingNewAccount={() => {}}
        withSelect
      />
    </FingoDialog>
  );
};

SurplusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default SurplusDialog;
