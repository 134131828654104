import { gql } from '@apollo/client';

const MARK_COMPANY_TUTORIAL_SEEN = gql`
  mutation MarkCompanyTutorialSeen(
    $tutorialCode: String!
    $companyId: String!
  ) {
    markCompanyTutorialAsSeen(
      tutorialCode: $tutorialCode
      companyId: $companyId
    ) {
      users {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export default MARK_COMPANY_TUTORIAL_SEEN;
