import { gql } from '@apollo/client';

const SOPHIA_TRACKER = gql`
  query SophiaTracker($companyId: Int!) {
    sophiaTracker(companyId: $companyId) {
      companyRut
      sales
      salesPast
      salesCount
      salesCountPast
      shop
      shopPast
      shopCount
      shopCountPast
      cessions
      cessionsPast
      cessionsCount
      cessionsCountPast
      cessionsShop
      cessionsShopPast
      cessionsShopCount
      cessionsShopCountPast
      shopExpires
      salesExpires
    }
  }
`;

export default SOPHIA_TRACKER;
