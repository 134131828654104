import { gql } from '@apollo/client';

export const CHECK_PROCESSING_CONFIRMING = gql`
  mutation checkConfirmingCession {
    checkConfirmingCession{
      success
    }
  }
`;

export const UPDATE_CONFIRMING_OPERATION = gql`
  mutation updateConfirmingOperation($operations: [ConfirmingOperationInputType]!) {
    updateConfirmingOperation(operations: $operations) {
      operations {
        id
        contactEmail
        expirationDate
        bankAccount {
          id
        }
      }
    }
  }
`;

export const EXECUTE_CONFIRMING = gql`
  mutation executeConfirming($expirationDate: Date!, $payrollId: ID!) {
    executeConfirming(expirationDate: $expirationDate, payrollId: $payrollId) {
      success
    }
  }
`;

export const APPROVE_CONFIRMING = gql`
  mutation approveConfirming($payrollId: ID!) {
    approveConfirming(payrollId: $payrollId) {
      payroll {
        id
        operations {
          id
          confirmingSet {
            id
            status
          }
        }
      }
    }
  }
`;

export const SPLIT_CONFIRMING_OPERATION = gql`
  mutation splitConfirmingOperation($confirmingIds: [ID]!) {
    splitConfirmingOperation(confirmingIds: $confirmingIds) {
      success
    }
  }
`;
