import { gql } from '@apollo/client';

const GET_INVOICE_PROVIDER = gql`
  query geInvoiceProvider($credentialProviderType_Name: String) {
    getInvoiceProvider(
      credentialProviderType_Name: $credentialProviderType_Name
    ) {
      id
      name
      integrated
      iconUrl
      requiresEmail
      credentialProviderType {
        id
        name
        description
      }
    }
  }
`;

export default GET_INVOICE_PROVIDER;
