import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { getFormFieldError } from '@fingo/lib/helpers';
import { useSnackBars } from '@fingo/lib/hooks';
import {
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SEND_EMAIL_PASSWORD_RECOVERY from '../../graphql/mutations/send-email-password-recovery';

const PasswordRecoveryDialog = ({ open, onClose }) => {
  const { addAlert } = useSnackBars();
  const [disable, setDisable] = useState(false);
  const [operationMessage, setOperationMessage] = useState('');
  const [emailForm, setEmailForm] = useState({ value: '', error: '' });
  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const fieldError = getFormFieldError(value, type, name);
    setEmailForm({ error: fieldError.message, value });
  };
  const [sendMail, { loading }] = useMutation(SEND_EMAIL_PASSWORD_RECOVERY, {
    onCompleted: () => {
      addAlert({
        id: 'recovery-password-success',
        message: 'Mail enviado',
        severity: 'success',
        color: 'success',
      });
      setDisable(true);
    },
    onError: (error) => {
      addAlert({
        id: 'recovery-password-error',
        message: error.message,
        color: 'error',
        severity: 'error',
      });
    },
  });
  const isButtonDisabled = emailForm.value === '' || emailForm.error !== '' || loading || disable;
  const handleSendMail = () => {
    sendMail({ variables: { email: emailForm.value } });
  };
  const handleOnClose = () => {
    onClose();
    setEmailForm({ value: '', error: '' });
    setDisable(false);
    setOperationMessage(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      sx={{ textAlign: 'center' }}
      PaperProps={{
        style: { borderRadius: '18px' },
      }}
      maxWidth="xs"
    >
      <DialogTitle variant="h4" color="primary">
        Recuperar contraseña
      </DialogTitle>
      <DialogContent>
        <Typography>
          Ingresa tu correo y te enviaremos un link de recuperación para
          restablecer tu contraseña
        </Typography>
        <TextField
          value={emailForm.value}
          name="email"
          variant="outlined"
          type="email"
          error={Boolean(emailForm.error)}
          helperText={operationMessage || emailForm.error}
          onChange={handleChange}
          sx={{
            marginTop: '1.5rem',
            width: '80%',
          }}
        />
        <LoadingButton
          sx={{
            zIndex: 1,
            height: '2.5rem',
            width: '40%',
            borderRadius: '10px',
            margin: '2rem 0 1.5rem 0',
          }}
          variant="contained"
          color="primary"
          disabled={isButtonDisabled}
          onClick={handleSendMail}
          loading={loading}
          id="forgot-send-mail"
        >
          Enviar mail
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};

PasswordRecoveryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PasswordRecoveryDialog;
