import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import getImagePath from '../helpers/images';

const useMexicoHomeFirstOperationTutorial = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const company = useSelectedCompany();

  const handleEvaluationClick = useCallback(() => {
    history.push('/app/sales/factoring/available');
  }, [history]);

  const handleOperationClick = useCallback(() => {
    history.push('/app/sales/factoring/offers');
  }, [history]);

  const handleTransferClick = useCallback(() => {
    history.push('/app/sales/factoring/cessions');
  }, [history]);

  const steps = useMemo(
    () => [
      {
        id: 'evaluation-step',
        title: 'Evalúa tus facturas',
        description: 'para solicitar financiamiento',
        imagePath: getImagePath('mexico-evaluations-banner', isMobile),
        onClick: handleEvaluationClick,
      },
      {
        id: 'operation-step',
        title: 'Opera tus facturas aprobadas',
        description: 'indicando su fecha de pago',
        imagePath: getImagePath('mexico-operations-banner', isMobile),
        onClick: handleOperationClick,
      },
      {
        id: 'transfer-step',
        title: 'Firma Anexo',
        description: 'y recibe tu giro',
        imagePath: getImagePath('mexico-transfered-banner', isMobile),
        onClick: handleTransferClick,
      },
    ],
    [history, isMobile],
  );

  const {
    hasOfferedInvoices = false,
    hasOperations = false,
    hasTransferedInvoices = false,
  } = company || {};

  const activeStep = useMemo(() => {
    if (hasTransferedInvoices) return 3;
    if (hasOperations) return 2;
    if (hasOfferedInvoices) return 1;
    return 0;
  }, [hasOfferedInvoices, hasOperations, hasTransferedInvoices, company]);

  return { steps, activeStep };
};

export default useMexicoHomeFirstOperationTutorial;
