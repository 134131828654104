import { useMutation } from '@apollo/client';
import { logIn } from '@fingo/lib/apollo/helpers/auth';
import { useCallback, useMemo } from 'react';
import VERIFY_OTP from '../graphql/mutations/verify-otp';

const useVerifyOtp = (otp, email) => {
  const [otpVerify, { loading, error }] = useMutation(VERIFY_OTP, {
    variables: {
      email,
      otp,
    },
    onCompleted: ({ otpVerification }) => logIn(otpVerification),
  });
  const errorMessage = useMemo(() => {
    if (error) {
      return 'Este código ya fue usado o es incorrecto.';
    }
    return '';
  }, [error]);
  const sendCustomOtp = useCallback((customOtp) => {
    otpVerify({
      variables: {
        otp: customOtp,
      },
    });
  }, []);
  return [otpVerify, loading, errorMessage, sendCustomOtp];
};

export default useVerifyOtp;
