import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { formatMoney } from '@fingo/lib/helpers';
import { DownloadExcelIconButton } from '@fingo/lib/components/buttons';
import Info from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import CONFIRMING_CURRENT_AMOUNTS_ANALYTICS from '../../../../../features/confirming/graphql/queries/confirming-current-amounts-analytics';

const ConfirmigPayrollSummaryAnalytics = () => {
  const { data } = useQuery(CONFIRMING_CURRENT_AMOUNTS_ANALYTICS);
  const {
    confirmingCurrentAmountsAnalytics: result,
  } = useMemo(() => data || {}, [data]);
  return (
    <Stack
      height="100%"
      spacing={1}
    >
      <Typography>Monto vigente en cartera:</Typography>
      <BoxRow
        debtAmount={result?.walletDebt}
        amount={result?.walletAmount}
        interestAmount={result?.walletInterest}
        totalAmount={result?.walletTotalAmount}
      />
      <Typography>Monto vigente en mora:</Typography>
      <BoxRow
        debtAmount={result?.debtDebt}
        amount={result?.debtAmount}
        interestAmount={result?.debtInterest}
        totalAmount={result?.debtTotalAmount}
      />
      <Typography>Monto histórico:</Typography>
      <BoxRow
        debtAmount={result?.finishedDebt}
        amount={result?.finishedAmount}
        interestAmount={result?.finishedInterest}
        totalAmount={result?.finishedTotalAmount}
      />
    </Stack>
  );
};

const BoxRow = ({ totalAmount, amount, interestAmount, debtAmount }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    bgcolor="gray.A100"
    p={0.5}
  >
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography color="primary">
        ${formatMoney(totalAmount)}
      </Typography>
      <Tooltip title={(
        <Stack>
          <Typography>Capital: ${formatMoney(amount)}</Typography>
          <Typography>Monto interés al vencimiento: ${formatMoney(interestAmount)}</Typography>
          <Typography>Monto interés por mora: ${formatMoney(debtAmount)}</Typography>
        </Stack>
      )}
      >
        <Info fontSize="8px" sx={{ color: 'text.light' }} />
      </Tooltip>
    </Stack>
    <Tooltip title="Próximamente">
      <span>
        <DownloadExcelIconButton disabled />
      </span>
    </Tooltip>
  </Stack>
);

BoxRow.propTypes = {
  totalAmount: PropTypes.string,
  amount: PropTypes.string,
  interestAmount: PropTypes.string,
  debtAmount: PropTypes.string,
};

BoxRow.defaultProps = {
  totalAmount: 0,
  amount: 0,
  interestAmount: 0,
  debtAmount: 0,
};

export default ConfirmigPayrollSummaryAnalytics;
