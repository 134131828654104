import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

const UtmCampaignProvider = ({ children }) => {
  const cookies = new Cookies();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmCampaign = urlParams.get('utm_campaign') || '';
    cookies.set('utm_campaign', utmCampaign, { path: '/' });
  }, []);

  return children;
};

UtmCampaignProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UtmCampaignProvider;
