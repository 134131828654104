import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

const ExecutiveAvatar = ({ executive }) => (
  <Avatar
    alt={executive?.completeName}
    src={executive?.picture?.url}
    sx={{ width: 74, height: 74 }}
  />
);

ExecutiveAvatar.propTypes = {
  executive: PropTypes.shape({
    completeName: PropTypes.string,
    picture: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

ExecutiveAvatar.defaultProps = {
  executive: null,
};

export default ExecutiveAvatar;
