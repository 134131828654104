import { gql } from '@apollo/client';

const OTP_LOGIN = gql`
  mutation otpLogin($email: String!, $clientId: String!) {
    otpLogin(email: $email, clientId: $clientId) {
      success
    }
  }
`;

export default OTP_LOGIN;
