import Google from '@mui/icons-material/Google';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import useGoogleOauthLogin from '../../hooks/useGoogleOauthLogin';
import GoogleOAuthProvider from '../../providers/GoogleOAuthProvider';

const GoogleSignButton = () => {
  const { signIn, loading } = useGoogleOauthLogin();

  return (
    <GoogleOAuthProvider>
      <LoadingButton
        variant="outlined"
        onClick={signIn}
        loading={loading}
        disableRipple
        sx={{
          borderRadius: 3,
          borderColor: 'gray.A300',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        startIcon={<Google />}
      >
        Iniciar sesión con Google
      </LoadingButton>
    </GoogleOAuthProvider>
  );
};

export default React.memo(GoogleSignButton);
