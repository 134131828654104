import { gql } from '@apollo/client';

const SEND_INVOICE_STATUS_MAIL = gql`
  mutation sendInvoiceStatusMail(
    $emailType: String!
    $invoiceId: Int!
    $userId: Int!
    $contactsIds: [Int]!
  ) {
    sendInvoiceStatusMail(
      emailType: $emailType
      invoiceId: $invoiceId
      userId: $userId
      contactsIds: $contactsIds
    ) {
      success
      message
    }
  }
`;

export default SEND_INVOICE_STATUS_MAIL;
