import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { orderingOfferSelected } from '@fingo/lib/apollo/reactive-variables/purchase-orders';
import { orderingOfferSelection } from '@fingo/lib/apollo/cache/localQuery';
import ORDERING_PURCHASE_ORDERS from '@fingo/lib/graphql/queries/ordering-purchase-orders';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import { DocumentFilters } from '@fingo/lib/components/filters';
import { DocumentList } from '@fingo/lib/components/lists';
import { ORDERING_DOCUMENTLIST_TYPE } from '@fingo/lib/constants';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ConfirmOfferDialog from '../../../../components/ordering/ConfirmOfferDialog';
import useOrderingPreColumns from '../../../../components/invoice/useOrderingPreColumns';
import { NoDocumentOffersError } from '../factoringRoutes/noDataMessages';

const OfferOrdering = () => {
  const selectedCompany = useSelectedCompany();
  const { data: { selectedOfferOrderingIds } } = useQuery(orderingOfferSelection);
  const [dialogOpen, setDialogOpen] = useState(false);
  const missingCredentials = useCallback(() => !(
    selectedCompany?.hasSiiCredentials
    // && selectedCompany?.hasInvoiceProviderCredentials
    // && selectedCompany?.hasDigitalCertificate
    && selectedCompany?.hasFrameworkContract
  ), [selectedCompany]);
  const isActionButtonDisabled = useCallback(() => {
    if (selectedOfferOrderingIds.length === 0) return true;
    if (missingCredentials()) return true;
    return false;
  }, [selectedOfferOrderingIds, missingCredentials]);
  const tooltipTitle = useCallback(() => {
    if (selectedOfferOrderingIds.length === 0) {
      return 'Seleccione las órdenes de compra que desea solicitar giro.';
    }
    if (missingCredentials()) {
      return 'Recuerde de completar todos los documentos necesarios para operar. Estos son: contraseña del SII y del facturador, el certificado digital de la empresa y el contrato marco firmado.';
    }
    return '';
  }, [selectedOfferOrderingIds, missingCredentials]);

  const NoDataError = () => <NoDocumentOffersError document="órdenes de compra" />;

  return (
    <DocumentList
      trackerId="ORDERING_OFFER"
      type={ORDERING_DOCUMENTLIST_TYPE}
      checkboxSelection
      headerTitle="OC con oferta"
      queryDocument={ORDERING_PURCHASE_ORDERS}
      emitter
      customVariables={{
        companyId: selectedCompany ? selectedCompany.id : '',
        inOffer: true,
      }}
      includeHeaders={[
        'orderNumber',
        'purchaser_Name',
        'publicationDate',
        'totalAmount',
        'orderingoffer_OrderingPaymentAmount',
        'orderingoffer_OrderingMonthlyRate',
        'orderingoffer_OrderingRetentionRate',
        'orderingoffer_InvoiceIssuedDate',
        'orderingOperationActionOffer',
      ]}
      mobileHeaders={[
        'orderNumber',
        'purchaser_Name',
        'orderingoffer_OrderingMonthlyRate',
        'orderingoffer_InvoiceIssuedDate',
      ]}
      onCompletedSetLength={(data) => data.purchaseOrders.totalCount}
      onCompletedSetRows={(data) => data.purchaseOrders.edges.map((edge) => edge.node)}
      onSelectionModelChange={(ids) => orderingOfferSelected(ids)}
      selectionModel={selectedOfferOrderingIds}
      initialOrderBy="-publicationDate"
      isRowSelectable={({ row }) => row.status === 'OFFERED'}
      keepNonExistentRowsSelected
      preColumns={useOrderingPreColumns()}
      noRowsMessage={NoDataError}
      customSummaryElement={(
        <DocumentFilters
          showStatesFilter={false}
          showDatesFilter={false}
          flexEndButtons={() => (
            <>
              <Tooltip title={tooltipTitle()}>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={isActionButtonDisabled()}
                    onClick={() => setDialogOpen(true)}
                    id="ordering-confirm-offer"
                  >
                    Solicitar giro
                  </Button>
                </span>
              </Tooltip>
              <ConfirmOfferDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
            </>
          )}
        />
      )}
    />
  );
};

export default OfferOrdering;
