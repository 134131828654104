import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Header from './Header';

const Layout = ({ children }) => {
  const location = useLocation();
  const whiteBGC = [
    '/',
    '/register',
    '/login',
    '/provider-login',
    '/external-login',
    '/password-recovery',
    '/simulator',
    '/simulator-register',
  ];

  const backgroundColor = useMemo(() => {
    if (whiteBGC.includes(location.pathname)) {
      return 'common.white';
    }
    return 'gray.A200';
  }, [location.pathname.split('/')[1]]);

  if (location.pathname === '/debtor-payment-checker') return children;

  return (
    <Stack height={1}>
      <Header />
      <Box bgcolor={backgroundColor} flexGrow={1}>
        {children}
      </Box>
    </Stack>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
