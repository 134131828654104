import React, { useState, useEffect } from 'react';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import AppNavbar from './AppNavbar';

const useStyles = makeStyles((theme) => ({
  profileSnack: {
    marginTop: '-15px',
    marginRight: '80px',
    position: 'absolute',
  },
  profileSnackContent: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
    minWidth: '405px',
    paddingRight: '10px',
    textAlign: 'right',
  },
}));

const Header = () => {
  const user = useGetUser();
  const [profileInfoMissing, setProfileInfoMissing] = useState(0);
  const [openProfileSnack, setOpenProfileSnack] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const timerId = setTimeout(() => setOpenProfileSnack(true), 6000);
    return clearTimeout(timerId);
  }, []);

  useEffect(() => {
    if (user) {
      setProfileInfoMissing(
        (!user.email ? 1 : 0) + (!user.phoneNumber ? 1 : 0),
      );
    }
  }, [user]);

  return (
    <>
      {!!profileInfoMissing && (
        <Snackbar
          open={openProfileSnack}
          onClose={() => setOpenProfileSnack(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={15000}
          className={classes.profileSnack}
          TransitionComponent={(props) => (
            <Slide {...props} direction="right" timeout={1000} />
          )}
        >
          <SnackbarContent
            severity="warning"
            className={classes.profileSnackContent}
            message="Completa tu perfil aquí para ayudarte en el proceso! ➡️"
          />
        </Snackbar>
      )}
      <AppNavbar />
    </>
  );
};

export default Header;
