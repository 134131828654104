import { gql } from '@apollo/client';
import ShoppingFields from '../fragments/shopping-fields';

const ORDERING_SHOPPING_CART = gql`
  query orderingShoppingCart($purchaseOrders: [OrderingSelectionType]!) {
    orderingShoppingCart(purchaseOrders: $purchaseOrders) {
      ...ShoppingFields
    }
  }
  ${ShoppingFields}
`;

export default ORDERING_SHOPPING_CART;
