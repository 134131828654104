import { gql } from '@apollo/client';
import { InvoiceDocumentsFields } from '../invoices/fragments';
import { sharedInvoiceInput, sharedInvoiceInputFields } from '../fragments';

const INVOICE_WITH_DOCUMENT = gql`
  query InvoiceWithDocument(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      totalCount
      totalPages
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          folio
          documents {
            ... InvoiceDocumentsFields
          }
        }
      }
    }
  }
  ${InvoiceDocumentsFields}
`;

export default INVOICE_WITH_DOCUMENT;
