import { gql } from '@apollo/client';

const GET_PAYMENT_METHOD_ENUM = gql`
  query GetPaymentMethodEnum {
    getPaymentMethodEnum {
      value
      label
    }
  }
`;

export default GET_PAYMENT_METHOD_ENUM;
