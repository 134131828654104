import { gql } from '@apollo/client';

const UPDATE_NPS = gql`
  mutation UpdateNps(
    $id: ID!
    $grade: Int
    $reasons: [String]
    $otherReason: String
    $comment: String
    $lastShown: DateTime
    $location: String
  ) {
    updateNps(
      id: $id
      input: {
        grade: $grade
        reasons: $reasons
        otherReason: $otherReason
        comment: $comment
        lastShown: $lastShown
        location: $location
      }
    ) {
      updateNps {
        id
        grade
        reasons
        otherReason
        comment
      }
    }
  }
`;

export default UPDATE_NPS;
