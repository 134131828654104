import { gql } from '@apollo/client';

const CONFIRMING_PAYROLL_SUMMARY_ANALYTICS = gql`
  {
    confirmingPayrollSummaryAnalytics {
      pendingPayrolls
      pendingCessionOperations
      initiatedPayrolls
    }
  }
`;

export default CONFIRMING_PAYROLL_SUMMARY_ANALYTICS;
