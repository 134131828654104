import { gql } from '@apollo/client';

const COUNT_ORDERING = gql`
  query countOrdering($companyId: Int) {
    available: purchaseOrders(companyId: $companyId, inSimulation: true) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }
    offers: purchaseOrders(companyId: $companyId, inOffer: true) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }
    activeOperations: purchaseOrders(
      companyId: $companyId
      inOperationActive: true
    ) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }
    finishedOperations: purchaseOrders(
      companyId: $companyId
      finishedOperations: true
    ) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export default COUNT_ORDERING;
