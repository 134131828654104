import { gql } from '@apollo/client';
import { RatificationManagerFields } from '../fragments';
import { UserIdentification } from '../user/fragments';
import { CollectionManagerBasicFields } from '../collection/fragments';
import { BaseContactsField } from '../customers/fragment';

export const SEND_RATIFICATION_MAIL = gql`
  mutation SendRatificationMail(
    $contactsIds: [Int]!
    $mailType: String!
    $withCessionCertificate: Boolean!
    $managersIds: [Int]
    $invoiceIds: [Int]
  ) {
    sendRatificationMail(
      contactsIds: $contactsIds
      mailType: $mailType
      withCessionCertificate: $withCessionCertificate
      managersIds: $managersIds
      invoiceIds: $invoiceIds
    ) {
      managers {
        ...RatificationManagerFields
      }
    }
  }
  ${RatificationManagerFields}
`;

export const SEND_COLLECTION_MAIL = gql`
  mutation SendCollectionMail(
    $collectionContactIds: [Int]!
    $collectionManagerIds: [Int]!
    $mailType: String!
    $attachmentIds: [Int]
    $assignmentIds: [Int]
  ) {
    sendCollectionMail(
      collectionManagerIds: $collectionManagerIds
      collectionContactIds: $collectionContactIds
      attachmentIds: $attachmentIds
      assignmentIds: $assignmentIds
      mailType: $mailType
    ) {
      collectionManagers {
        id
        collector {
          ...UserIdentification
        }
        collectionPriority {
          value
        }
        actions {
          id
          comment
          createdAt
          actionType
          contacts {
            ...BaseContactsField
          }
          author {
            ...UserIdentification
          }
        }
      }
    }
  }
  ${UserIdentification}
  ${BaseContactsField}
`;

export const GET_RATIFICATION_MAIL_PREVIEW = gql`
  mutation GetRatificationMailPreview(
    $mailType: String!
    $ratificationManagerIds: [String]!
  ) {
    getRatificationMailPreview(
      mailType: $mailType
      ratificationManagerIds: $ratificationManagerIds
    ) {
      preview
    }
  }
`;

export const ADD_COLLECTION_PROROGATION = gql`
  mutation AddCollectionProrogation(
    $collectionManagerIds: [Int]!
    $prorogationDays: Int!
  ) {
    addCollectionProrogation(
      collectionManagerIds: $collectionManagerIds
      prorogationDays: $prorogationDays
    ) {
      collectionProrogations {
        id
        prorogationDate
      }
    }
  }
`;

export const REINTEGRATION_FLAG_ACTIVATION = gql`
  mutation ReintegrationFlagActivation($managerId: Int!, $newStatus: Boolean!) {
    reintegrationFlagActivation(managerId: $managerId, newStatus: $newStatus) {
      collectionManager {
        ...CollectionManagerBasicFields
      }
    }
  }
  ${CollectionManagerBasicFields}
`;

export const CREATE_RATIFICATION_ACTION = gql`
  mutation CreateRatificationAction(
    $ratificationManagerIds: [Int]!
    $ratificationContactsIds: [Int]
    $ratificationActionType: RatificationActionEnum!
    $ratificationComment: String
    $ratificationChannel: RatificationChannelEnum!
    $responded: Boolean
  ) {
    createRatificationAction(
      ratificationActionInput: {
        ratificationManagerIds: $ratificationManagerIds
        ratificationContactsIds: $ratificationContactsIds
        ratificationActionType: $ratificationActionType
        ratificationComment: $ratificationComment
        ratificationChannel: $ratificationChannel
        responded: $responded
      }
    ) {
      managers {
        ...RatificationManagerFields
      }
    }
  }
  ${RatificationManagerFields}
`;
