import { useQuery } from '@apollo/client';
import ORDERING_PURCHASE_ORDERS from '../graphql/queries/ordering-purchase-orders';

const usePurchaseOrders = (queryOptions) => {
  const { data, ...extraQuery } = useQuery(
    ORDERING_PURCHASE_ORDERS,
    queryOptions,
  );
  return {
    purchaseOrders: data?.purchaseOrders?.edges?.map((edge) => edge.node) || [],
    ...extraQuery,
    purchaseOrdersTotalCount: data?.purchaseOrders?.totalCount,
  };
};

export default usePurchaseOrders;
