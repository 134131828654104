/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { ControlledNumberInput } from '@fingo/lib/components/inputs';
import { ControlledSelect } from '@fingo/lib/components/selects';
import { useSelectedCompany } from '@fingo/lib/hooks';
import INVOICE_DEBTOR_SUMMARY from '@fingo/lib/graphql/queries/invoice-debtor-summary';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import KOBROS_COLLECTION_MASTER_ENTITIES from '../../features/collection/graphql/queries/kobros-collection-master-entities';
import SET_DEBTOR_PREFERENCES from '../../graphql/mutations/set-debtor-preferences';

const allDebtorsOption = { value: 'all_debtors', label: 'Todas las empresas' };
const buttonStyles = { borderRadius: 30, maxHeight: 40 };

const ClientConfigForm = ({
  debtorId,
  refetchQueries,
  handleClose,
  setAlertMessage,
}) => {
  const selectedCompany = useSelectedCompany();
  const [selectedDebtor, setSelectedDebtor] = useState(null);
  const [paymentTerm, setPaymentTerm] = useState('');

  const { data, loading: loadingDebtors } = useQuery(
    KOBROS_COLLECTION_MASTER_ENTITIES,
    {
      variables: { inCollection: selectedCompany.id },
    },
  );

  const [setDebtorPreferences, { loading: setDebtorPreferencesLoading }] = useMutation(SET_DEBTOR_PREFERENCES, {
    refetchQueries: [
      {
        query: KOBROS_COLLECTION_MASTER_ENTITIES,
        variables: { inCollection: selectedCompany.id },
      },
      debtorId
        ? {
          query: INVOICE_DEBTOR_SUMMARY,
          variables: { ownerId: selectedCompany.id, debtorId },
        }
        : null,
    ],
  });

  const debtors = data?.getMasterEntities.edges.map((edge) => edge.node) || [];
  const debtorOptions = [
    allDebtorsOption,
    ...debtors.map((debtor) => ({ value: debtor.id, label: debtor.name })),
  ];

  useEffect(() => {
    if (debtorId && debtors.length > 0) {
      setSelectedDebtor(debtors.find((d) => d.id === debtorId) || null);
    }
  }, [debtorId, debtors]);

  useEffect(() => {
    setPaymentTerm(
      selectedDebtor?.debtor?.debtorPreferences?.expirationDays || '',
    );
  }, [selectedDebtor]);

  const handleDebtorChange = useCallback(
    (event) => {
      const newDebtor = event.target.value === 'all_debtors'
        ? 'all_debtors'
        : debtors.find((d) => d.id === event.target.value);
      setSelectedDebtor(newDebtor);
    },
    [debtors],
  );

  const handlePaymentTermChange = useCallback((event) => {
    if (parseInt(event.target.value, 10) > 0) {
      setPaymentTerm(event.target.value);
    } else {
      setPaymentTerm(0);
    }
  }, []);

  const handleSaveChanges = useCallback(() => {
    if (selectedDebtor && paymentTerm) {
      setDebtorPreferences({
        variables: {
          companyId: selectedCompany?.id,
          masterEntityId:
            selectedDebtor !== 'all_debtors' ? selectedDebtor?.id : null,
          expirationDays: parseInt(paymentTerm, 10) || null,
          allDebtors: selectedDebtor === 'all_debtors',
        },
      })
        .then(() => {
          setAlertMessage({
            severity: 'success',
            message: 'Configuración guardada exitosamente',
            show: true,
          });
          refetchQueries();
        })
        .catch(() => {
          setAlertMessage({
            severity: 'error',
            message:
              'No se pudo guardar la configuración del cliente. Por favor, intenta nuevamente.',
            show: true,
          });
        });
    }
  }, [
    selectedDebtor,
    paymentTerm,
    selectedCompany?.id,
    setAlertMessage,
    refetchQueries,
    setDebtorPreferences,
  ]);

  return (
    <Box>
      <Stack direction="column" spacing={2} marginBottom={4}>
        <Typography variant="h1" color="primary.main">
          Configuraciones de cliente koBros
        </Typography>
        <Typography variant="subtitle1">
          Configura la plataforma de koBros by Fingo cómo más te acomode!
          Recuerda que puedes cambiar las configuraciones en cualquier momento.
        </Typography>
      </Stack>
      {loadingDebtors ? (
        <Stack direction="column" spacing={2}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Stack>
      ) : (
        <Stack
          maxWidth={300}
          direction="column"
          spacing={2}
          sx={{
            margin: 'auto',
          }}
        >
          <ControlledSelect
            value={
              selectedDebtor === 'all_debtors'
                ? 'all_debtors'
                : selectedDebtor?.id
            }
            handleChange={handleDebtorChange}
            label={(
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Selecciona el cliente para configurar
              </Typography>
            )}
            options={debtorOptions}
            disabled={!!debtorId}
          />
          <ControlledNumberInput
            value={paymentTerm}
            onChange={handlePaymentTermChange}
            label={(
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Plazo de pago cliente (días)
              </Typography>
            )}
          />
        </Stack>
      )}
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        justifyContent="center"
        marginTop={4}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          color="primary"
          sx={buttonStyles}
        >
          Cerrar
        </Button>
        <LoadingButton
          loading={setDebtorPreferencesLoading}
          onClick={handleSaveChanges}
          variant="contained"
          color="primary"
          disabled={!selectedDebtor || !paymentTerm}
          sx={buttonStyles}
        >
          Guardar cambios
        </LoadingButton>
      </Stack>
    </Box>
  );
};

ClientConfigForm.propTypes = {
  setAlertMessage: PropTypes.func.isRequired,
  debtorId: PropTypes.string,
  refetchQueries: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

ClientConfigForm.defaultProps = {
  debtorId: null,
};

export default ClientConfigForm;
