import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import useHomeButtons from '../hooks/useHomeButtons';
import HomeButton from './buttons/HomeButton';

const HomeButtons = () => {
  const homeButtons = useHomeButtons();

  return (
    <Grid
      id="home-buttons"
      container
      columnSpacing={{ mobile: 1, desktop: 4 }}
      rowSpacing={{ mobile: 1, desktop: 0 }}
    >
      {homeButtons.map(({ id, ...button }) => (
        <Grid
          key={id}
          item
          mobile={24 / homeButtons.length}
          desktop={12 / homeButtons.length}
        >
          <HomeButton id={id} key={id} {...button} />
        </Grid>
      ))}
    </Grid>
  );
};

export default HomeButtons;
