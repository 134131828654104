import { gql } from '@apollo/client';

const DELETE_COMPANY_PREFERENCES = gql`
  mutation eraseCompanyEmailPreference($companyId: Int!, $mail: String!) {
    eraseCompanyEmailPreference(companyId: $companyId, mail: $mail) {
      success
      message
    }
  }
`;

export default DELETE_COMPANY_PREFERENCES;
