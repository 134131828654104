import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import HomeSummaryListItem from '../list-items/HomeSummaryListItem';

const HomeSummaryList = ({ homeSummaryItems }) => (
  <List sx={{ width: 1, px: { desktop: 2 } }}>
    {homeSummaryItems.map(({ id, ...props }) => (
      <React.Fragment key={id}>
        <HomeSummaryListItem id={id} {...props} />
        <Divider
          sx={{
            width: { desktop: 340 },
            borderColor: 'gray.A300',
            mx: 'auto',
          }}
        />
      </React.Fragment>
    ))}
  </List>
);

HomeSummaryList.propTypes = {
  homeSummaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.element,
      count: PropTypes.number,
      amount: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
};

export default HomeSummaryList;
