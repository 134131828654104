import { gql } from '@apollo/client';
import {
  sharedInvoiceInput,
  sharedInvoiceInputFields,
  TypeConnectionFields,
} from '@fingo/lib/graphql/fragments';
import MoneyFields from '@fingo/lib/graphql/fragments/money/money-fields';
import { PreofferFields } from '@fingo/lib/graphql/invoices/fragments';

const GET_INVOICES_FOR_AUTOMATIC_OPERATION = gql`
  query GetInvoicesForAutomaticOperation(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
        ... TypeConnectionFields
        edges {
          cursor
          node {
            id
            folio
            receiver {
              id
              name
            }
            dateIssued
            dateExpiration
            dateToPay
            preoffer {
              ...PreofferFields
            }
            amountWithIva {
              ...MoneyFields
            }
          }
        }
      }
    }
  ${TypeConnectionFields}
  ${MoneyFields}
  ${PreofferFields}
`;

export default GET_INVOICES_FOR_AUTOMATIC_OPERATION;
