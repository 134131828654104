import { gql } from '@apollo/client';

const UPLOAD_DOCUMENT_BATCH = gql`
  mutation UploadDocumentBatch(
    $invoiceIds: [Int]!
    $inputUploadFiles: [UploadFilesInvoiceDocumentManagerInputType]!
  ) {
    uploadDocumentBatch(
      invoiceIds: $invoiceIds
      inputUploadFiles: $inputUploadFiles
    ) {
      documentBatch {
        id
      }
    }
  }
`;

export default UPLOAD_DOCUMENT_BATCH;
