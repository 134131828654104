import { gql } from '@apollo/client';
import NPSFields from '@fingo/lib/graphql/fragments/nps-fields';

const GET_NPS = gql`
  query getNPS($npsId: ID!) {
    getNpsById(npsId: $npsId) {
      ... NPSFields
    }
  }
  ${NPSFields}
`;

export default GET_NPS;
