import React from 'react';
import StepConnector from '@mui/material/StepConnector';

const HorizontalStepConnector = () => (
  <StepConnector
    sx={{
      ml: 0,
      width: 60,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiStepConnector-line': {
        height: 2,
        width: 'inherit',
        border: 2,
        borderColor: 'gray.A400',
      },
      '&.Mui-active .MuiStepConnector-line': {
        borderColor: 'primary.main',
      },
      '&.Mui-completed .MuiStepConnector-line': {
        borderColor: 'primary.main',
      },
    }}
  />
);

export default HorizontalStepConnector;
