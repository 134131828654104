import { gql } from '@apollo/client';

const ERASE_CONTACT = gql`
  mutation eraseContact($id: Int!, $company: Int!, $contactType: String) {
    eraseContact(id: $id, company: $company, contactType: $contactType) {
      messageType
      message
    }
  }
`;

export default ERASE_CONTACT;
