import { gql } from '@apollo/client';

const ACTIVATE_COLLECTION_PLAN = gql`
  mutation ActivateCollectionPlan($planId: ID!) {
    activateCollectionPlan(planId: $planId) {
      user {
        id
        permissions
        userPlanSubscriptions(isActive: true) {
          edges {
            node {
              id
              isActive
              isAdmin
            }
          }
        }
        selectedCompany {
          id
          hasCollectionAcceptedTerms @client
          currentUserAcceptedTerms {
            id
            product
          }
          masterEntity {
            id
            subscriptions(isActive: true) {
              edges {
                node {
                  id
                  isActive
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ACTIVATE_COLLECTION_PLAN;
