import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from '@fingo/lib/config/dayjs';
import { useMutation } from '@apollo/client';
import StepperDialog from '@fingo/lib/components/dialogs/StepperDialog';
import { formatMoney } from '@fingo/lib/helpers';
import {
  calculateCommission,
  calculateInvoiceAmounts,
} from '@fingo/lib/helpers/invoice-amounts-calculation';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import ASSIGN_INVOICES_TO_PURCHASE_ORDERS from '@fingo/lib/graphql/mutations/assign-invoices-to-purchase-orders';
import useBankAccounts from '@fingo/lib/hooks/useBankAccounts';
import useOffers from '@fingo/lib/hooks/useOffers';
import AddInvoicesToPurchaseOrdersDialogStepOne from './AddInvoicesToPurchaseOrdersDialogStepOne';
import AddInvoicesToPurchaseOrdersDialogStepTwo from './AddInvoicesToPurchaseOrdersDialogStepTwo';
import AddInvoicesToPurchaseOrdersDialogStepThree from './AddInvoicesToPurchaseOrdersDialogStepThree';

const AddInvoicesToPurchaseOrdersDialog = ({
  open,
  setOpen,
  purchaseOrders,
  setSelectedPurchaseOrderIds,
  company,
  refetch,
}) => {
  const [account, setAccount] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [isExternal, toggleIsExternal] = useBooleanState(true);
  const [selectedOfferIds, setSelectedOfferIds] = useState([]);
  const [orderingAssignmentRequest, setOrderingAssignmentRequest] = useState(
    [],
  );
  const { addAlert } = useSnackBars();
  const {
    accounts,
    loading: accountsLoading,
    refetch: accountsRefetch,
  } = useBankAccounts([company?.id]);
  const { offers, loading, totalCount } = useOffers({
    skip: !company?.rut,
    variables: {
      companyId: company?.id,
      availableForOrdering: true,
      first: pageSize,
      offset: page * pageSize,
      orderBy: '-pk',
    },
  });

  const selectedOffers = useMemo(
    () => offers
      .filter((offer) => selectedOfferIds.includes(offer.id))
      .map((offer) => {
        const selectedAssignment = orderingAssignmentRequest.find(
          (assignment) => assignment.invoiceId === offer.id,
        );
        const dateToPay = selectedAssignment
          ? dayjs(selectedAssignment.dateToPay)
          : null;
        return { ...offer, dateToPay };
      }),
    [selectedOfferIds, orderingAssignmentRequest],
  );

  const totalAmountToPay = useMemo(
    () => purchaseOrders?.reduce(
      (acc, cur) => acc + (cur.orderingoffer?.orderingFinancedAmount.amount || 0),
      0,
    ),
    [purchaseOrders],
  );

  const currentAmountToPay = useMemo(
    () => selectedOffers
      .map((offer) => ({
        days: offer.dateToPay,
        retentionRate: offer.offer
          ? offer.offer.monthlyRate
          : offer.preoffer.monthlyRate,
        monthlyRate: offer.offer
          ? offer.offer.monthlyRate
          : offer.preoffer.monthlyRate,
        amountWithIva: offer.amountWithIva,
      }))
      .reduce(
        (acc, cur) => acc + (calculateInvoiceAmounts(cur)?.advanceAmount || 0),
        0,
      ),
    [selectedOffers],
  );

  // eslint-disable-next-line max-len
  const [assignInvoicesToPurchaseOrders, { loading: assignationLoading }] = useMutation(ASSIGN_INVOICES_TO_PURCHASE_ORDERS, {
    variables: {
      orderingAssignmentRequest: selectedOffers.map(
        ({ id: invoiceId, dateToPay }) => ({
          invoiceId,
          dateToPay: dateToPay?.format('YYYY-MM-DD'),
        }),
      ),
      purchaseOrderIds: purchaseOrders?.map((oc) => oc.id),
      isExternal,
      bankAccountId: account?.id,
      commission: calculateCommission(
        company?.currentCommissionRules,
        currentAmountToPay,
      ),
    },
  });

  const statistics = [
    {
      label: 'Cantidad de OCs',
      value: purchaseOrders?.length,
    },
    {
      label: 'Monto financiado OCs',
      value: `$${formatMoney(totalAmountToPay)}`,
    },
    {
      label: 'Monto acumulado factoring',
      value: `$${formatMoney(currentAmountToPay)}`,
    },
    {
      label: 'Diferencia por pagar',
      value: `$${
        currentAmountToPay < totalAmountToPay
          ? formatMoney(totalAmountToPay - currentAmountToPay)
          : 0
      }`,
      color: 'primary.main',
    },
  ];

  const steps = [
    {
      title:
        'Selecciona tus facturas disponibles que quieres usar para pagar tus OCs',
      component: (
        <AddInvoicesToPurchaseOrdersDialogStepOne
          statistics={statistics}
          offers={offers}
          loading={loading}
          selectedOfferIds={selectedOfferIds}
          setSelectedOfferIds={setSelectedOfferIds}
          setOrderingAssignmentRequest={setOrderingAssignmentRequest}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalCount={totalCount}
        />
      ),
      backButton: {
        label: 'Cancelar',
        handler: () => {
          setOrderingAssignmentRequest([]);
          setSelectedPurchaseOrderIds([]);
        },
      },
      nextButton: {
        label: 'Siguiente',
        disabled:
          currentAmountToPay < totalAmountToPay
          || !selectedOffers.length
          || selectedOffers.some((offer) => !offer.dateToPay?.isValid()),
      },
    },
    {
      title: 'Detalle simulación pago OCs con facturas',
      component: (
        <AddInvoicesToPurchaseOrdersDialogStepTwo
          statistics={[
            {
              label: 'OCs',
              value: purchaseOrders?.map((oc) => oc.orderNumber).join(', '),
            },
            {
              label: 'Facturas',
              value: selectedOffers?.map((offer) => offer.folio).join(', '),
            },
            ...statistics.slice(1, 3),
          ]}
          accounts={accounts}
          loading={accountsLoading}
          account={account}
          setAccount={setAccount}
          company={company}
          refetch={accountsRefetch}
          toggleIsExternal={toggleIsExternal}
          isExternal={isExternal}
        />
      ),
      backButton: { label: 'Atrás' },
      nextButton: {
        label: 'Aceptar operación',
        loading: assignationLoading,
        disabled: !account,
        handler: assignInvoicesToPurchaseOrders,
      },
    },
    {
      title: '¡Estamos listos!',
      component: <AddInvoicesToPurchaseOrdersDialogStepThree />,
      backButton: { label: 'Atrás', disabled: true },
      nextButton: {
        label: 'OK',
        handler: () => {
          setSelectedPurchaseOrderIds([]);
          setOrderingAssignmentRequest([]);
          addAlert({
            id: 'assign-invoices-to-purchase-orders',
            message: 'Se realizó la asignación correctamente!',
          });
          refetch();
          setOpen(false);
        },
      },
    },
  ];

  return (
    <StepperDialog
      open={open}
      setOpen={setOpen}
      handleClose={() => {
        setOrderingAssignmentRequest([]);
        setSelectedPurchaseOrderIds([]);
        setOpen(false);
      }}
      steps={steps}
    />
  );
};

AddInvoicesToPurchaseOrdersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  purchaseOrders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      orderingoffer: PropTypes.shape({
        orderingFinancedAmount: PropTypes.number,
      }),
    }),
  ).isRequired,
  company: PropTypes.shape({
    id: PropTypes.string,
    rut: PropTypes.string,
    currentCommissionRules: PropTypes.arrayOf(
      PropTypes.shape({
        variant: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }).isRequired,
  setSelectedPurchaseOrderIds: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AddInvoicesToPurchaseOrdersDialog;
