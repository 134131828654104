import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import dayjs from '../../config/dayjs';

const LastActionDate = ({ actions, lastDate }) => {
  const lastAction = actions.length > 0
    ? actions.reduce(
      (prevAct, currAct) => (prevAct.createdAt > currAct.createdAt ? prevAct : currAct),
    )
    : null;
  const dateToShow = lastAction ? lastAction.createdAt : lastDate;
  if (!dateToShow) return null;
  return (
    <Stack alignItems="center">
      <Typography variant="body1" fontSize={9} noWrap>
        {dayjs(dateToShow).format('DD-MM-YYYY')}
      </Typography>
      <Typography variant="body1" fontSize={9} noWrap>
        {dayjs(dateToShow).format('HH:mm[hrs]')}
      </Typography>
    </Stack>
  );
};

LastActionDate.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  })).isRequired,
  lastDate: PropTypes.string,
};

LastActionDate.defaultProps = {
  lastDate: null,
};

export default LastActionDate;
