import { gql } from '@apollo/client';
import { InvoiceDocumentsFields } from '../invoices/fragments';

const UPLOAD_INVOICE_DOCUMENTS = gql`
  mutation uploadInvoiceDocuments(
    $invoiceId: Int!
    $inputUploadFiles: [UploadFilesInvoiceDocumentManagerInputType]!
  ) {
    uploadInvoiceDocuments(
      invoiceId: $invoiceId
      inputUploadFiles: $inputUploadFiles
    ) {
      invoices {
        totalCount
        totalPages
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            documents {
              ...InvoiceDocumentsFields
            }
          }
        }
      }
    }
  }
  ${InvoiceDocumentsFields}
`;

export default UPLOAD_INVOICE_DOCUMENTS;
