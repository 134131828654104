import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import HomeJoyrideTooltip from '../tooltips/HomeJoyrideTooltip';

const steps = [
  {
    target: '#home-buttons',
    title: 'Nuestros servicios',
    content:
      'Al igual que antes, aquí puedes seleccionar  el tipo de financiamiento o gestión que deseas realizar',
    disableBeacon: true,
  },
  {
    target: '#home-carousel',
    title: 'Banner publicitario',
    content:
      'En esta sección encontrarás promociones vigentes, nuevas actualizaciones de la plataforma,entre otros.',
    placement: 'right',
  },
  {
    target: '#home-summary-paper',
    title: 'Resumen de tu financiamiento',
    content:
      'Aquí te mantendremos informado/a del estado de tu financiamiento de factoring ',
    placement: 'left',
  },
  {
    target: '#home-executive-paper',
    title: 'Información de tu ejecutivo',
    content:
      'Ahora podrás tener siempre cercano el contacto de tu ejecutivo y podrás hablarle directamente.',
    placement: 'right',
  },
];

const HomeJoyride = ({ run, onClose }) => {
  const handleJoyrideCallback = useCallback(
    (data) => {
      const { status } = data;
      const finishedStatuses = ['finished', 'skipped'];
      if (finishedStatuses.includes(status)) {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <Joyride
      steps={steps}
      run={run}
      continuous
      scrollToFirstStep
      disableOverlayClose
      disableCloseOnEsc
      callback={handleJoyrideCallback}
      tooltipComponent={HomeJoyrideTooltip}
    />
  );
};

HomeJoyride.propTypes = {
  run: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HomeJoyride;
