import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import Cookies from 'universal-cookie';
import { useGoogleLogin } from '@react-oauth/google';
import { useMutation } from '@apollo/client';
import { logIn } from '@fingo/lib/apollo/helpers/auth';
import { useSnackBars } from '@fingo/lib/hooks';
import OAUTH_LOGIN from '../graphql/mutations/oauth-login';

const useGoogleOauthLogin = () => {
  const { trackEvent } = useTracking();
  const cookies = new Cookies();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utmCampaign = cookies.get('utm_campaign') || searchParams.get('utm_campaign') || null;
  const { addAlert } = useSnackBars();

  const [oauthLogin, { loading }] = useMutation(OAUTH_LOGIN, {
    variables: {
      provider: 'google',
      utmCampaign,
    },
    onCompleted: ({ oauth2Auth }) => logIn(oauth2Auth),
    onError: ({ message }) => {
      addAlert({
        id: 'oauth-error',
        message,
      });
    },
  });

  const onGoogleClick = useGoogleLogin({
    onSuccess: (codeResponse) => oauthLogin({
      variables: {
        idToken: codeResponse.code,
      },
    }),
    scope:
      'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.phonenumbers.read',
    flow: 'auth-code',
  });

  const signIn = useCallback(() => {
    trackEvent({
      action: 'CLICK',
      button: 'google-oauth',
    });
    onGoogleClick();
  }, [onGoogleClick]);

  return { signIn, loading };
};

export default useGoogleOauthLogin;
