import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { ELECTRONIC_INVOICES_TYPE } from '@fingo/lib/constants';
import { useSelectedCompany } from '@fingo/lib/hooks';
import GET_INVOICE_PROVIDER from '@fingo/lib/graphql/queries/get-invoice-provider';
import { getCurrentCredentialProvider } from '../features/profile/helpers/credentials';

const useGetInvoiceProviders = () => {
  const selectedCompany = useSelectedCompany();
  const { data, loading } = useQuery(GET_INVOICE_PROVIDER, {
    variables: { credentialProviderType_Name: ELECTRONIC_INVOICES_TYPE },
  });

  const providers = useMemo(() => {
    if (loading || !data) return [];
    return data.getInvoiceProvider || [];
  }, [data, loading]);

  const integratedProviders = useMemo(
    () => providers.filter(({ integrated }) => integrated),
    [providers],
  );

  const notIntegratedProviders = useMemo(
    () => providers.filter(({ integrated }) => !integrated),
    [providers],
  );

  const currentProvider = useMemo(() => {
    if (loading || !selectedCompany) return null;
    return getCurrentCredentialProvider(selectedCompany) || null;
  }, [selectedCompany, loading]);

  return {
    providers,
    integratedProviders,
    notIntegratedProviders,
    currentProvider,
    loading,
  };
};

export default useGetInvoiceProviders;
