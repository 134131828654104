import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { ReactComponent as FactoringIcon } from '../assets/icons/factoring.svg';
import { ReactComponent as OrderingIcon } from '../assets/icons/ordering.svg';
import { ReactComponent as SalesIcon } from '../assets/icons/sales.svg';
import { ReactComponent as KobrosIcon } from '../assets/icons/kobros.svg';
import BoldTypography from '../components/typographies/BoldTypography';

const COUNTRY_BUTTONS = {
  Chile: [
    'factoring-button',
    'ordering-button',
    'sales-button',
    'kobros-button',
  ],
  Mexico: ['factoring-button', 'sales-button'],
};

const useHomeButtons = () => {
  const country = useGetCountryFromUrl();
  const history = useHistory();
  const company = useSelectedCompany();

  const homeButtons = useMemo(() => {
    const buttons = [
      {
        id: 'factoring-button',
        mostUsed: country.name === 'Chile',
        startIcon: <FactoringIcon />,
        label: <BoldTypography label="Anticipar " boldLabel="facturas" />,
        onClick: () => {
          history.push('/app/sales/factoring');
        },
      },
      {
        id: 'ordering-button',
        startIcon: <OrderingIcon />,
        label: (
          <BoldTypography label="Anticipar " boldLabel="órdenes de compra" />
        ),
        onClick: () => {
          history.push('/app/sales/ordering');
        },
      },
      {
        id: 'sales-button',
        startIcon: <SalesIcon />,
        label: <BoldTypography label="Gestionar " boldLabel="compras" />,
        onClick: () => {
          history.push('/app/purchases');
        },
      },
      {
        id: 'kobros-button',
        startIcon: <KobrosIcon />,
        label: (
          <BoldTypography label="Gestionar cobranzas con " boldLabel="koBros" />
        ),
        onClick: () => {
          if (!company) {
            history.push('/kobros-register');
          } else {
            history.push('/app/sales/collection/home');
          }
        },
      },
    ];

    return buttons.filter(({ id }) => {
      const countryButtons = COUNTRY_BUTTONS[country.name];
      return countryButtons.includes(id);
    });
  }, [company, history, country]);

  return homeButtons;
};

export default useHomeButtons;
