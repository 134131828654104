import React from 'react';
import PropTypes from 'prop-types';
import { GoogleOAuthProvider as GoogleOAuth } from '@react-oauth/google';

const GoogleOAuthProvider = ({ children }) => (
  <GoogleOAuth clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    {children}
  </GoogleOAuth>
);

GoogleOAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GoogleOAuthProvider;
