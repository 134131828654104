import { gql } from '@apollo/client';

const UPLOAD_CONFIRMING = gql`
  mutation uploadConfirming($confirmingFile: Upload!) {
    uploadConfirming(confirmingFile: $confirmingFile) {
      success
    }
  }
`;

export default UPLOAD_CONFIRMING;
