import { gql } from '@apollo/client';
import MoneyFields from '@fingo/lib/graphql/fragments/money/money-fields';
import {
  InvoiceIdFields,
  InvoiceStatusFields,
  TraceFields,
} from '@fingo/lib/graphql/invoices/fragments';

const FETCH_TRACE = gql`
  mutation fetchTrace($invoiceId: Int!) {
    fetchTrace(invoiceId: $invoiceId) {
      invoice {
        ...InvoiceIdFields
        ...InvoiceStatusFields
        traces {
          ...TraceFields
        }
        intermediateReferencesFrom {
          toInvoice {
            ...InvoiceIdFields
          }
          amount {
            ...MoneyFields
          }
        }
        intermediateReferencesTo {
          fromInvoice {
            ...InvoiceIdFields
          }
          amount {
            ...MoneyFields
          }
        }
      }
    }
  }
  ${MoneyFields}
  ${TraceFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
`;

export default FETCH_TRACE;
