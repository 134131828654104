/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import ShoppingFields from './fragments/shopping-fields';

export const OFFER_SHOPPING_CART = gql`
  query offerShoppingCart($offerSelected: [OfferSelectionType]!) {
    offerShoppingCart(offerSelected: $offerSelected) {
      ...ShoppingFields
    }
  }
  ${ShoppingFields}
`;
