import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { useSelectedCompany } from '@fingo/lib/hooks';
import ORDERING_PURCHASE_ORDERS from '@fingo/lib/graphql/queries/ordering-purchase-orders';
import {
  OrderingOperationActions,
  OrderingOperationPaymentSummary,
} from '../../../../components/ordering/ordering-operation';
import { orderStatus } from '../../../../helpers/orderingStatus';
import { NoOrderingOperationsError } from './noDataMessages';
import useOrderingPreColumns from '../../../../components/invoice/useOrderingPreColumns';

const CollapseComponent = ({ row }) => (
  <OrderingOperationPaymentSummary purchaseOrder={row} />
);

CollapseComponent.propTypes = {
  row: PropTypes.shape().isRequired,
};

const ActiveOperations = () => {
  const [selectedPurchaseOrderIds, setSelectedPurchaseOrderIds] = useState([]);
  const selectedCompany = useSelectedCompany();
  const NoDataError = () => <NoOrderingOperationsError />;

  return (
    <FingoMainView
      id="ordering-operations"
      query={ORDERING_PURCHASE_ORDERS}
      queryCustomVariables={{
        companyId: selectedCompany?.id || '',
        inOperationActive: true,
      }}
      slots={{
        header: PaperHeader,
        actions: OrderingOperationActions,
        table: FingoTable,
      }}
      slotProps={{
        header: { viewTitle: 'Operaciones activas e históricas' },
        actions: {
          selectedPurchaseOrderIds,
          setSelectedPurchaseOrderIds,
          company: selectedCompany,
        },
        table: {
          columns: useOrderingPreColumns(),
          includeHeaders: [
            'orderNumber',
            'purchaser_Name',
            'publicationDate',
            'totalAmount',
            'orderingoffer_OrderingPaymentAmount',
            'debtDays',
            'orderingoffer_OrderingMonthlyRate',
            'orderingOperationStatus',
            'orderingOperationActionOffer',
          ],
          checkboxSelection: true,
          isRowSelectable: ({ row }) => row.status === orderStatus.PendingInvoices,
          collapsible: true,
          isRowCollapsible: ({ row }) => !!row.invoiceAssignationRecords.length
            || !!row.orderingsenttocollectionregisterSet.length,
          collapseComponent: CollapseComponent,
          disableSelectionOnClick: true,
          onSelectionModelChange: (ids) => { setSelectedPurchaseOrderIds(ids); },
          selectionModel: selectedPurchaseOrderIds,
          density: 'compact',
          noRowsMessage: NoDataError,
          initialOrderBy: '-publicationDate',
          rowsPerPageOptions: [5, 10, 25],
        },
      }}
    />
  );
};

export default ActiveOperations;
