import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import makeStyles from '@mui/styles/makeStyles';
import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import currencyVar from '../../apollo/reactive-variables/money';
import { GET_BANK_ACCOUNT_TYPES, GET_BANKS } from '../../graphql';
import { validEmail } from '../../helpers';
import { useSelectedCompany } from '../../hooks';
import useGetCountryFromUrl from '../../hooks/useGetCountryFromUrl';
import CREATE_BANK_ACCOUNT from '../../graphql/mutations/create-bank-account';

const useStyles = makeStyles({
  header: {
    marginBottom: '2rem',
  },
  label: {
    marginBottom: '0.5rem',
  },
  input: {
    width: '100%',
    marginBottom: '1rem',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '1rem',
    marginBottom: '1rem',
    height: 40,
  },
  buttonsContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  button: {
    width: '50%',
    maxWidth: '250px',
    height: '100%',
  },
  error: {
    marginBottom: '0.5rem',
    marginLeft: '0.5rem',
  },
});

const NewBankAccount = ({
  cancel,
  onAccountCreate,
  accounts,
  customCompany,
  refetchQueriesOnCreation,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
  const selectedCompany = useSelectedCompany();
  const country = useGetCountryFromUrl();
  const company = customCompany || selectedCompany;
  const { data: accountTypes, loading: loadingAccountTypes } = useQuery(GET_BANK_ACCOUNT_TYPES, {
    variables: {
      countryId: country?.id,
    } });
  const { data: banks } = useQuery(GET_BANKS, {
    variables: {
      countryId: country?.id,
    } });
  const { t } = useTranslation();
  const [createNewAccount, { loading }] = useMutation(
    CREATE_BANK_ACCOUNT,
    {
      refetchQueries: refetchQueriesOnCreation,
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        onAccountCreate(data.createBankAccount.bankAccountCreated);
      },
      onError: () => {},
    },
  );
  const [data, setData] = useState({
    number: {
      value: '',
      error: '',
    },
    name: {
      value: company.masterEntity.name,
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
    bank: {
      value: 'choose',
      error: '',
    },
    accountType: {
      value: 'choose',
      error: '',
    },
  });
  const [formError, setFormError] = useState('');
  const [accountTypeError, setAccountTypeError] = useState('');
  const currency = useReactiveVar(currencyVar);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((oldData) => ({ ...oldData, [name]: { ...oldData[name], value } }));
    if (name === 'bank') {
      setFormError('');
    } else if (name === 'accountType') {
      setAccountTypeError('');
    }
  };

  const cleanError = (field) => {
    setData((oldData) => ({ ...oldData, [field]: { ...oldData[field], error: '' } }));
  };

  const handleCreate = async () => {
    const { number, name, email, bank, accountType } = data;
    if (name.value === '') {
      setData((oldData) => ({ ...oldData, name: { ...oldData.name, error: 'El nombre de la cuenta no puede estar vacío' } }));
      return;
    }
    cleanError('name');
    if (number.value === '') {
      setData((oldData) => ({ ...oldData, number: { ...oldData.number, error: 'El número de la cuenta no puede estar vacío' } }));
      return;
    }
    cleanError('number');
    if (bank.value === 'choose') {
      setFormError('Debes elegir algún banco');
      return;
    }
    setFormError('');
    if (accountType.value === 'choose') {
      setAccountTypeError('Debes elegir algún tipo de cuenta');
      return;
    }
    setAccountTypeError('');
    if (!validEmail(email.value)) {
      setData((oldData) => ({ ...oldData, email: { ...oldData.email, error: 'Ingresa un email válido' } }));
      return;
    }
    cleanError('email');
    for (let i = 0; i < accounts.length; i += 1) {
      const account = accounts[i];
      if (account.bankName === bank.value && account.accountNumber === number.value) {
        setData((oldData) => ({ ...oldData, number: { ...oldData.number, error: 'Ya registraste esta cuenta' } }));
        return;
      }
    }
    cleanError('number');
    setFormError('');
    createNewAccount({
      variables: {
        bankName: bank.value,
        accountEmail: email.value,
        accountName: name.value,
        accountNumber: number.value,
        accountRut: `${company.masterEntity.displayNationalIdentifier.replace(/\./g, '')}`,
        accountType: accountType.value,
        currency,
      },
    });
  };

  return (
    <Stack {...props}>
      <Grid container alignItems="center" className={classes.header}>
        <Grid item xs={2}>
          <IconButton onClick={cancel} size="large">
            <ArrowBackIos />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography color="primary" variant="h6">
            Agrega tu cuenta del banco
          </Typography>
        </Grid>
      </Grid>
      <Typography className={classes.label}><b>{t('National identifier')} </b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="rut"
        value={company.masterEntity.displayNationalIdentifier}
        disabled
      />
      <Typography className={classes.label}><b>Titular de la cuenta</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="name"
        value={data.name.value}
        error={data.name.error !== ''}
        helperText={data.name.error}
        onChange={handleChange}
      />
      <Typography className={classes.label}><b>{t('account number')}</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="number"
        type="number"
        value={data.number.value}
        error={data.number.error !== ''}
        helperText={data.number.error}
        onChange={handleChange}
      />
      <Typography className={classes.label}><b>Banco</b></Typography>
      <FormControl variant="outlined" fullWidth>
        <Select
          className={classes.input}
          name="bank"
          value={data.bank.value}
          error={formError !== ''}
          onChange={handleChange}
          MenuProps={{ sx: { zIndex: 1355 } }}
        >
          <MenuItem value="choose" disabled>Elige un banco</MenuItem>
          {banks?.getBankOptionsByCountry.map((bank) => (
            <MenuItem key={bank.name} value={bank.name}>{bank.name}</MenuItem>
          ))}
        </Select>
        {formError && <Typography color="error" variant="caption" className={classes.error}>{formError}</Typography>}
      </FormControl>
      <Typography className={classes.label}><b>Tipo de cuenta</b></Typography>
      <FormControl variant="outlined" fullWidth>
        <Select
          className={classes.input}
          name="accountType"
          value={data.accountType.value}
          error={accountTypeError !== ''}
          onChange={handleChange}
          MenuProps={{ sx: { zIndex: 1355 } }}

        >
          <MenuItem value="choose" disabled>Elige un tipo de cuenta</MenuItem>
          {!loadingAccountTypes && accountTypes?.getBankAccountTypesByCountry.map((type) => (
            <MenuItem key={type.name} value={type.name}>{type.name}</MenuItem>
          ))}
        </Select>
        {accountTypeError && <Typography color="error" variant="caption" className={classes.error}>{accountTypeError}</Typography>}
      </FormControl>
      <Typography className={classes.label}><b>Email</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="email"
        value={data.email.value}
        error={data.email.error !== ''}
        helperText={data.email.error}
        onChange={handleChange}
      />
      { currency === 'USD' && (
        <Typography className={classes.label}>*Recordar que esta cuenta es para USD</Typography>
      )}
      <div className={isMobile ? classes.buttonsContainerMobile : classes.buttonsContainer}>
        <LoadingButton
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleCreate}
          disabled={loading}
          loading={loading}
          sx={{ borderRadius: 3 }}
        >
          Agregar cuenta
        </LoadingButton>
      </div>
    </Stack>
  );
};

NewBankAccount.propTypes = {
  customCompany: PropTypes.shape(
    {
      id: PropTypes.string,
      rut: PropTypes.string,
      name: PropTypes.string,
    },
  ),
  cancel: PropTypes.func.isRequired,
  onAccountCreate: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    bankName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  refetchQueriesOnCreation: PropTypes.array.isRequired,
};

NewBankAccount.defaultProps = {
  customCompany: null,
};

export default NewBankAccount;
