import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useIsMobile } from '@fingo/lib/hooks';
import BANK_ACCOUNT_ACTIVE_CHANGE from '@fingo/lib/graphql/mutations/bank-account-active-change';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Adjust from '@mui/icons-material/Adjust';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { encryptedBankAccountNumber } from '../../../helpers/credentials';

const BankAccountCard = ({
  bankAccount,
  selectedBankAccount,
  onSelect,
  selectable,
  onEditHandler,
}) => {
  const isMobile = useIsMobile();
  const [bankAccountActiveChange] = useMutation(BANK_ACCOUNT_ACTIVE_CHANGE);
  const isSelected = useMemo(
    () => bankAccount.id === selectedBankAccount?.id,
    [selectedBankAccount],
  );

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      sx={{
        borderRadius: 6,
        padding: '0.3rem 1rem',
        boxShadow: '0px 4px 18px rgba(0, 0, 0, 0, 0.05)',
        border: isSelected && '1px solid',
        borderColor: isSelected && 'primary.main',
      }}
      onClick={isMobile ? () => onSelect(bankAccount) : null}
    >
      {selectable && !isMobile && (
        <IconButton
          variant="contained"
          size="small"
          color="primary"
          onClick={() => onSelect(bankAccount)}
        >
          {isSelected ? <Adjust /> : <RadioButtonUnchecked />}
        </IconButton>
      )}
      <Stack direction="column" spacing={0.2}>
        <Typography variant="body2">{bankAccount.bankName}</Typography>
        <Typography variant="body2">
          {`${bankAccount.accountType}  ${encryptedBankAccountNumber(bankAccount.accountNumber)}`}
        </Typography>
        <Typography variant="body2">{bankAccount.currency.isoCode}</Typography>
      </Stack>
      <Stack direction="column">
        <IconButton onClick={onEditHandler} disabled={!isSelected}>
          <Edit />
        </IconButton>
        <IconButton
          onClick={() => bankAccountActiveChange(
            { variables: { bankId: selectedBankAccount?.id } },
          )}
          disabled={!isSelected}
        >
          <Delete />
        </IconButton>
      </Stack>
    </Stack>
  );
};

BankAccountCard.propTypes = {
  bankAccount: PropTypes.shape({
    id: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    currency: PropTypes.shape({
      isoCode: PropTypes.string,
    }),
  }).isRequired,
  selectedBankAccount: PropTypes.shape({
    id: PropTypes.string,
    bankName: PropTypes.string,
    accountNumber: PropTypes.string,
    accountType: PropTypes.string,
    active: PropTypes.bool,
    currency: PropTypes.shape({
      isoCode: PropTypes.string,
    }),
  }),
  onSelect: PropTypes.func.isRequired,
  selectable: PropTypes.bool,
  onEditHandler: PropTypes.func,
};

BankAccountCard.defaultProps = {
  selectedBankAccount: null,
  selectable: false,
  onEditHandler: () => {},
};

export default BankAccountCard;
