import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from '@fingo/lib/config/dayjs';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import { FormControlLabel, Checkbox, TextField, Grid } from '@mui/material';
import GET_NPS from '../../graphql/queries/get-nps';
import FirstStepDialog from './NPSFirstStep';
import SecondStepDialog from './NPSSecondStep';
import UPDATE_NPS from '../../graphql/mutations/update-nps';
import SKIP_NPS from '../../graphql/mutations/skip-nps';

const NPS = ({ npsId, presetGrade, timeout }) => {
  const [NPSId, setNPSId] = useState(npsId);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [grade, setGrade] = useState(presetGrade);
  const [npsInfo, setNPSInfo] = useState({});
  const [shown, setShown] = useState(null);
  const [checkedReasons, setCheckedReasons] = useState();
  const [selectedReasonsAmount, setSelectedReasonsAmount] = useState(0);
  const [otherText, setOtherText] = useState('');
  const [comment, setComment] = useState('');
  const location = useLocation();

  const npsLocations = {
    '/app/home': 'NPS',
    '/app/sales/factoring/offers': 'NPS',
    '/evaluate': 'NPS',
    '/app/analytics': 'sophia',
  };

  const user = useGetUser();
  const {
    data: getNps,
    loading: loadingNps,
    error: errorNps,
  } = useQuery(GET_NPS, {
    variables: { npsId },
    skip: !npsId,
  });

  const fetchNps = (locationNps) => {
    // If the user is signed in, get the NPS from the user data
    if (user) {
      const currentNpsList = user.activeNpsResponse;
      const currentNpsTypeData = currentNpsList.find(
        (npsData) => npsData.npsType.name === locationNps,
      );
      return currentNpsTypeData;
    }
    return getNps?.getNpsById;
  };

  useEffect(() => {
    if (npsLocations[location.pathname] && !shown && !loadingNps) {
      const locationNps = npsLocations[location.pathname];
      const nps = fetchNps(locationNps);
      if (!nps) {
        return;
      }
      setNPSInfo(nps.npsType);
      setNPSId(nps.id);
      const reasonsObject = nps.npsType.reasons.reduce(
        (p, r) => ({ ...p, [r.category]: false }),
        {},
      );
      setCheckedReasons(reasonsObject);
      setTimeout(() => {
        setOpen(true);
      }, timeout);
    }
  }, [location?.pathname, loadingNps, user]);

  const [updateNPS] = useMutation(UPDATE_NPS);
  const [skipNPS] = useMutation(SKIP_NPS);

  const reasonsToString = () => Object.entries(checkedReasons)
    .filter(([, value]) => value)
    .map(([key]) => key.toString());

  const handleShown = (date) => {
    updateNPS({
      variables: {
        id: NPSId,
        lastShown: date,
      },
    });
  };

  const handleNext = () => {
    updateNPS({
      variables: {
        id: NPSId,
        grade,
        location: location.pathname,
      },
    });
    setStep(1);
  };

  const handleFinish = () => {
    updateNPS({
      variables: {
        id: NPSId,
        comment,
        reasons: reasonsToString(),
        otherReason: otherText,
      },
    });
    setOpen(false);
  };

  const handleClose = () => {
    skipNPS({
      variables: {
        id: NPSId,
      },
    });
    setOpen(false);
  };

  const secondStepTitle = () => {
    if (npsInfo.reasons.length === 0) {
      return '¿Tienes algún Comentario adicional?';
    }
    if (grade <= 6) return npsInfo.questions[0];
    if (grade <= 8) return npsInfo.questions[1];
    return npsInfo.questions[2];
  };

  const handleReasonChange = (event) => {
    const newCheckedReasons = {
      ...checkedReasons,
      [event.target.name]: event.target.checked,
    };
    setCheckedReasons(newCheckedReasons);
    setSelectedReasonsAmount(
      Object.values(newCheckedReasons).reduce((acc, checkedReason) => {
        if (checkedReason) {
          return acc + 1;
        }
        return acc;
      }, 0),
    );
  };

  const getFormItem = (reason, i) => {
    if (reason.category === 'OTHER' && checkedReasons.OTHER) {
      return (
        <Grid key={i}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={checkedReasons[reason.category]}
                onChange={handleReasonChange}
                name={reason.category}
                disabled={
                  selectedReasonsAmount === 3
                  && !checkedReasons[reason.category]
                }
                indeterminate={
                  selectedReasonsAmount === 3
                  && !checkedReasons[reason.category]
                }
              />
            )}
            label={reason.categoryToEs}
          />
          <TextField
            value={otherText}
            onChange={(e) => setOtherText(e.target.value)}
            variant="outlined"
          />
        </Grid>
      );
    }
    return (
      <FormControlLabel
        key={i}
        control={(
          <Checkbox
            checked={checkedReasons[reason.category]}
            onChange={handleReasonChange}
            name={reason.category}
            disabled={
              selectedReasonsAmount === 3 && !checkedReasons[reason.category]
            }
            indeterminate={
              selectedReasonsAmount === 3 && !checkedReasons[reason.category]
            }
          />
        )}
        label={reason.categoryToEs}
      />
    );
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const buttonDisabled = () => {
    const someChecked = getKeyByValue(checkedReasons, true);
    if (someChecked) {
      if (checkedReasons.Other && otherText === '') {
        return true;
      }
      return false;
    }
    return true;
  };

  if (!open) return null;
  if (!shown) {
    handleShown(dayjs());
    setShown(true);
  }

  if (loadingNps) return <div>Loading...</div>;
  if (errorNps) return <div>Error loading data</div>;

  return (
    <>
      {step === 0 && (
        <FirstStepDialog
          title={npsInfo.title}
          grade={grade}
          handleClose={handleClose}
          handleNext={handleNext}
          setGrade={setGrade}
        />
      )}
      {step === 1 && (
        <SecondStepDialog
          comment={comment}
          reasons={npsInfo.reasons}
          setComment={setComment}
          getFormItem={getFormItem}
          buttonDisabled={buttonDisabled}
          secondStepTitle={secondStepTitle}
          handleClose={handleClose}
          handleFinish={handleFinish}
        />
      )}
    </>
  );
};

NPS.propTypes = {
  npsId: PropTypes.string,
  presetGrade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timeout: PropTypes.number,
};

NPS.defaultProps = {
  npsId: '',
  presetGrade: 0,
  timeout: 5000,
};

export default NPS;
