import { gql } from '@apollo/client';

const SKIP_NPS = gql`
  mutation SkipNps($id: ID!) {
    skipNps(id: $id) {
      skipNps {
        id
        grade
        reasons
        otherReason
        comment
      }
    }
  }
`;

export default SKIP_NPS;
