import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { DocumentList } from '@fingo/lib/components/lists';
import { isInvoicesFirstLoadVar } from '@fingo/lib/apollo/reactive-variables/invoices';
import { useBooleanState, useNewInvoiceCharged } from '@fingo/lib/hooks';
import MY_SALES_PURCHASES from '@fingo/lib/graphql/queries/my-sales-purchases';
import EXPORT_INVOICES from '@fingo/lib/graphql/queries/export-invoices';
import {
  HEADERS,
  MOBILE_HEADERS,
  useInvoicePreColumns,
} from '@fingo/lib/constants';
import MySalesTourGuide from '../../../../components/joyRides/MySalesTourGuide';
import { NoDocumentError } from '../factoringRoutes/noDataMessages';

const MySales = () => {
  const [openJoyride, toggleJoyride] = useBooleanState(false);
  const [newInvoices, readNewInvoices] = useNewInvoiceCharged();
  const isInvoicesFirstLoad = useReactiveVar(isInvoicesFirstLoadVar);

  const NoDataError = () => <NoDocumentError document="document" />;

  return (
    <>
      <DocumentList
        trackerId="MY_SALES"
        type="my-sales"
        headerTitle="Cuentas por cobrar"
        queryDocument={MY_SALES_PURCHASES}
        showFilters
        defaultFilterProps={{
          showQuickFilters: true,
          buttonsToShow: ['payed', 'rejected'],
        }}
        emitter
        newData={newInvoices}
        readData={readNewInvoices}
        includeHeaders={HEADERS}
        mobileHeaders={MOBILE_HEADERS}
        allCompaniesOptions
        showExportInvoice
        queryExport={EXPORT_INVOICES}
        preColumns={useInvoicePreColumns()}
        openJoyride={toggleJoyride}
        firstLoad={isInvoicesFirstLoad}
        noRowsMessage={NoDataError}
      />
      <MySalesTourGuide run={openJoyride} setRun={toggleJoyride} />
    </>
  );
};

export default MySales;
