import React, { memo } from 'react';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const HomeTitle = () => {
  const user = useGetUser();
  return (
    <Box component={Stack}>
      <Typography variant="large_2">Hola {user.firstName}</Typography>
      <Typography variant="medium_2" color="#878787" fontWeight={600}>
        ¿Qué quieres hacer hoy?
      </Typography>
    </Box>
  );
};

export default memo(HomeTitle);
