import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Adjust from '@mui/icons-material/Adjust';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';

import makeStyles from '@mui/styles/makeStyles';
import { useIsMobile } from '../../hooks';
import BANK_ACCOUNT_ACTIVE_CHANGE from '../../graphql/mutations/bank-account-active-change';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 6,
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.05)',
    width: '100%',
    height: 75,
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflowWrap: 'break-word',
  },
  mobileFont: {
    fontSize: '3.5vw',
  },
}));

const BankAccountCard = (props) => {
  const {
    account,
    withSelect,
    selected,
    onSelect,
    editable,
    editHandler,
    deletable,
  } = props;
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [bankAccountActiveChange] = useMutation(
    BANK_ACCOUNT_ACTIVE_CHANGE,
    { variables: { bankId: account.id } },
  );
  const bankNumberString = account.accountNumber.toString();
  const isSelected = account?.active && selected;

  return (
    <Card className={isSelected ? clsx(classes.card, classes.selected) : classes.card}>
      <Stack direction="row" spacing={4} alignItems="center" justifyContent="flex-start" width="100%">
        {withSelect && (
          <IconButton
            variant="contained"
            size="small"
            color="primary"
            onClick={() => onSelect(account.id)}
            disabled={!account.active}
          >
            {isSelected ? <Adjust /> : <RadioButtonUnchecked />}
          </IconButton>
        )}
        <Grid item>
          <Typography variant="body2" className={isMobile ? classes.mobileFont : null}>
            {account.bankName}
          </Typography>
          <Typography variant="body2" className={isMobile ? classes.mobileFont : null}>
            {`${account.accountType}  **** ${bankNumberString.substr(bankNumberString.length - 4, 4)}`}
          </Typography>
        </Grid>
        <Grid item>
          {editable && (
            <IconButton sx={{ ml: 'auto !important' }} onClick={editHandler}>
              <Edit />
            </IconButton>
          )}
          {deletable && (
            <IconButton sx={{ ml: 'auto !important' }} onClick={bankAccountActiveChange}>
              <Delete />
            </IconButton>
          )}
        </Grid>
      </Stack>
    </Card>
  );
};

BankAccountCard.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
  withSelect: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  editable: PropTypes.bool,
  editHandler: PropTypes.func,
  deletable: PropTypes.bool,
};

BankAccountCard.defaultProps = {
  withSelect: true,
  selected: false,
  onSelect: () => {},
  editable: true,
  editHandler: () => {},
  deletable: true,
};

export default BankAccountCard;
