import { gql } from '@apollo/client';
import { FileFields } from '@fingo/lib/graphql/fileField';
import { TypeConnectionFields } from '@fingo/lib/graphql';

const COLLECTION_INVOICES = gql`
  query AppCollectionInvoices($id_In: [String], $first: Int) {
    invoices(id_In: $id_In, first: $first) {
    ...TypeConnectionFields
      edges {
        cursor
        node {
          id
          folio
          collectionManager {
            id
          }
          documents {
            id
            type {
              id
              name
            }
          }
          assignmentSet {
            id
            certificate {
              ...FileFields
            }
            toMasterEntity {
              id
              rut
            }
            createdAt
          }
        }
      }
    }
  }
  ${FileFields}
  ${TypeConnectionFields}
`;

export default COLLECTION_INVOICES;
