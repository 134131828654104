import { gql } from '@apollo/client';
import {
  InvoicePaymentDiscountFields,
  sharedInvoiceInput,
  sharedInvoiceInputFields,
  TypeConnectionFields,
} from '../fragments';
import {
  CreditNoteFields,
  DocumentFinanceStateFields,
  InvoiceDteTypeFields,
  InvoiceIdFields,
  InvoiceRequestingPlatformFields,
  InvoiceStatusFields,
  PreofferFields,
  TraceFields,
} from '../invoices/fragments';
import {
  CollectionActionsFields,
  CollectionManagerBasicFields,
  CollectionPriorityFields,
  DataForCollectionFields,
} from '../collection/fragments';
import {
  MasterEntityBasicField,
  RiskBlacklistFields,
} from '../customers/fragment';
import {
  FundBasicField,
  InvoiceLoanManagerBasicField,
  InvoiceLoanRosterBasic,
} from '../invoice-loan/fragments';
import { UserIdentification } from '../user/fragments';

const INVOICES_IN_COLLECTION = gql`
  query invoicesInCollection(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... CreditNoteFields
          ... InvoiceRequestingPlatformFields
          isFunding @client
          lightningPaymentAuthorized @client
          isRatificated
          hasPaymentsEmitted
          dteType {
            ... InvoiceDteTypeFields
          }
          traces {
            ... TraceFields
          }
          uniqueDocumentFinanceState {
            ... DocumentFinanceStateFields
          }
          company {
            id
            masterEntity {
              ... MasterEntityBasicField
              riskBlacklist {
                ...RiskBlacklistFields
              }
            }
            executiveAssigned {
              ... UserIdentification
            }
          }
          receiver {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
          }
          preoffer {
            ... PreofferFields
          }
          invoiceloanmanager {
            ... InvoiceLoanManagerBasicField
            invoiceLoanRoster {
              ... InvoiceLoanRosterBasic
              fund {
                ... FundBasicField
                masterEntity {
                  ... MasterEntityBasicField
                }
              }
            }
          }
          collectionManager {
            ... CollectionManagerBasicFields
            owner {
              id
            }
            collectionPriority {
              ... CollectionPriorityFields
            }
            actions {
              ... CollectionActionsFields 
              author {
                ... UserIdentification
              }
            }
            collector {
              ... UserIdentification
            }
            currentDataForCollection {
              ... DataForCollectionFields
            }
          }
          ratificationmanager {
            id
            actions {
              id
              actionType
              comment
              createdAt
              channel
              responded
              author {
                ... UserIdentification
              }
            }
          }
          invoiceDebt {
            id
            days
             debt {
              ...MoneyFields
             }
          }
          offer {
            id
            monthlyRate
            defaultRate
            retentionRate
            transferCondition
            fundingDaysAfterExpiration
          }
          ... InvoicePaymentDiscountFields
        }
      }
    }
  }
  ${TypeConnectionFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${CollectionManagerBasicFields}
  ${DataForCollectionFields}
  ${CollectionPriorityFields}
  ${CreditNoteFields}
  ${InvoiceDteTypeFields}
  ${PreofferFields}
  ${TraceFields}
  ${DocumentFinanceStateFields}
  ${MasterEntityBasicField}
  ${InvoiceLoanManagerBasicField}
  ${InvoiceLoanRosterBasic}
  ${FundBasicField}
  ${UserIdentification}
  ${CollectionActionsFields}
  ${InvoicePaymentDiscountFields}
  ${RiskBlacklistFields}
  ${InvoiceRequestingPlatformFields}
`;

export default INVOICES_IN_COLLECTION;
