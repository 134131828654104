import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { cleanOfferShoppingCart } from '../apollo/helpers/shopping-cart';
import { offerDateToPaySelectedQuery } from '../apollo/cache/localQuery';
import {
  CREATE_OPERATION,
  INVOICE_CREATE_OPERATION,
} from '../graphql';
import { formatYearMonthDay } from '../helpers';
import { calculateInvoiceAmounts } from '../helpers/invoice-amounts-calculation';
import useSelectedOfferInvoices from './useSelectedOffers';
import { shoppingCartOffersVar } from '../apollo/reactive-variables/shopping-cart';
import useSelectedCompany from './useSelectedCompany';
import dayjs from '../config/dayjs';
import { getLocalStorageVariable } from '../apollo/helpers/local-storage';
import GET_USER from '../graphql/queries/get-user';

const useCreateOperation = (
  isExternal = false,
  bankId = null,
  certificatePassword = '',
  onCompleted = null,
  setError = null,
  skipCleaning = false,
) => {
  const requestingPlatformId = getLocalStorageVariable('requestingPlatformIdVar');
  const company = useSelectedCompany();
  const companyId = useMemo(() => company.id, [company]);

  const [invoices, , deleteQueryFromCache] = useSelectedOfferInvoices(
    INVOICE_CREATE_OPERATION,
  );

  const {
    data: { selectedOfferDateToPay },
  } = useQuery(offerDateToPaySelectedQuery);

  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);

  const amountCalculator = useCallback(
    (invoice) => {
      const { dateToPay } = shoppingCartOffers.find(
        (offer) => offer.invoiceId === invoice.id,
      );
      if (!dateToPay) return null;
      const days = dateToPay.diff(dayjs().startOf('day'), 'days');
      return calculateInvoiceAmounts({
        days,
        retentionRate: invoice.offer.retentionRate,
        monthlyRate: invoice.offer.monthlyRate,
        amountWithIva: invoice.amountWithIva,
      }).amountMinusInterests;
    },
    [selectedOfferDateToPay],
  );

  const assignmentRequests = useMemo(
    () => invoices.map((node) => {
      const matchOffer = shoppingCartOffers.find(
        (o) => o.invoiceId === node.id,
      );
      const { dateToPay } = matchOffer;
      return {
        id: node.id,
        dateToPay: dateToPay ? formatYearMonthDay(dateToPay) : null,
        amountMinusInterests: amountCalculator(node),
      };
    }),
    [invoices, selectedOfferDateToPay],
  );

  const result = useMutation(CREATE_OPERATION, {
    variables: {
      assignmentRequests,
      digitalCertificatePassword: certificatePassword,
      bankAccountId: bankId,
      isExternal,
      companyId,
      requestingPlatformId,
    },
    refetchQueries: [GET_USER],
    awaitRefetchQueries: true,
    onCompleted: (_data) => {
      deleteQueryFromCache();
      if (!skipCleaning) {
        cleanOfferShoppingCart();
        shoppingCartOffersVar([]);
      }
      if (onCompleted) {
        onCompleted(_data);
      }
    },
    onError: (error) => {
      if (setError) {
        switch (error.message) {
          case 'digital certificate not found on fingo':
            setError('No se encontró el certificado digital en Fingo');
            break;
          case 'Uploaded Digital Certificate is not valid':
            setError('Credenciales inválidas');
            break;
          default:
            setError(
              'Hubo un error con el certificado digital, contactate con nosotros',
            );
            break;
        }
      }
    },
  });
  return result;
};

export default useCreateOperation;
