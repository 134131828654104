import { gql } from '@apollo/client';

const CHECK_USERNAME_EMAIL = gql`
  query checkUsernameEmail(
    $email: String!
    $landingTag: String
    $utmCampaign: String
  ) {
    checkUsernameEmail(
      email: $email
      landingTag: $landingTag
      utmCampaign: $utmCampaign
    )
  }
`;

export default CHECK_USERNAME_EMAIL;
