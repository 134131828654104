import React from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const ContractTypeInput = ({ input, obj, handleInfoFunc }) => (
  <FormControl sx={input.sx}>
    <InputLabel id="contract-type-input-label">{input.label}</InputLabel>
    <Select
      id={input.id}
      label={input.label}
      disabled={input.disabled}
      onChange={(e) => {
        handleInfoFunc(input.id, e.target.value, obj.id);
      }}
      value={obj[input.id] || ''}
    >
      <MenuItem value="DIGITAL">Digital</MenuItem>
      <MenuItem value="NOTARY">Notarial</MenuItem>
    </Select>
  </FormControl>
);

ContractTypeInput.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    sx: PropTypes.shape({
      width: PropTypes.string,
    }),
    inputProps: PropTypes.shape({
      style: PropTypes.shape({
        textTransform: PropTypes.string,
      }),
    }),
  }).isRequired,
  obj: PropTypes.shape({
    id: PropTypes.number,
    rut: PropTypes.string,
    mainDisabled: PropTypes.bool,
  }).isRequired,
  handleInfoFunc: PropTypes.func.isRequired,
};

export default ContractTypeInput;
