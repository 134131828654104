import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { orderingSimulationSelection } from '@fingo/lib/apollo/cache/localQuery';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import ORDERING_SHOPPING_CART from '@fingo/lib/graphql/queries/ordering-shopping-cart';

const useOrderingShoppingCartSummary = () => {
  const {
    data: { selectedOrderingIds, orderingInvoiceIssuedSelected },
  } = useQuery(orderingSimulationSelection);

  const { data, loading } = useQuery(ORDERING_SHOPPING_CART, {
    variables: {
      purchaseOrders: selectedOrderingIds.map((purchaseOrderId) => ({
        purchaseOrderId,
        invoiceIssuedDate: formatYearMonthDay(
          orderingInvoiceIssuedSelected[purchaseOrderId],
        ),
      })),
    },
  });

  const summaryItems = useMemo(
    () => [
      {
        title: 'Cantidad de órdenes',
        value: data?.orderingShoppingCart.invoiceCount,
        color: 'text.primary',
        money: false,
      },
      {
        title: 'Monto total',
        value: data?.orderingShoppingCart.totalAmount,
        color: 'text.primary',
        money: true,
      },
      {
        title: 'Diferencia de precio',
        value: data?.orderingShoppingCart.priceDifference,
        color: 'text.primary',
        money: true,
      },
      {
        title: 'Comisión',
        value: data?.orderingShoppingCart.commission,
        color: 'text.primary',
        money: true,
      },
      {
        title: 'Costos totales',
        value: data?.orderingShoppingCart.totalCosts,
        color: 'text.primary',
        money: true,
      },
      {
        title: 'Monto retenidos',
        value: data?.orderingShoppingCart.retainedAmount,
        color: 'text.primary',
        money: true,
      },
      {
        title: 'Monto a depositar',
        value: data?.orderingShoppingCart.depositAmount,
        color: 'primary.main',
        money: true,
      },
    ],
    [data],
  );

  const noPurchaseOrders = useMemo(
    () => !selectedOrderingIds.length,
    [selectedOrderingIds],
  );

  const somePurchaseOrderWithoutDate = useMemo(
    () => selectedOrderingIds.some((id) => !orderingInvoiceIssuedSelected[id]),
    [selectedOrderingIds, orderingInvoiceIssuedSelected],
  );

  const tooltipTitle = useMemo(() => {
    if (noPurchaseOrders) {
      return 'Debes seleccionar órdenes de compra para continuar';
    }
    if (somePurchaseOrderWithoutDate) {
      return 'Ingrese por favor todas las fechas solicitadas';
    }
    return null;
  }, [noPurchaseOrders, somePurchaseOrderWithoutDate]);

  const disabled = useMemo(() => tooltipTitle !== null, [tooltipTitle]);

  return {
    summaryItems,
    loading,
    disabled,
    tooltipTitle,
  };
};

export default useOrderingShoppingCartSummary;
