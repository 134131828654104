import { gql } from '@apollo/client';
import {
  CessionsFields,
  DocumentFinanceStateFields,
  DownloadInvoiceDocumentFields,
  InvoiceAttachableFields,
  InvoiceDetailFields,
  InvoiceDteTypeFields,
  InvoiceIdFields,
  InvoiceIntermediateReferenceFields,
  InvoiceLoanedFields,
  InvoiceStatusFields,
  TraceFields,
} from '@fingo/lib/graphql/invoices/fragments';
import { MasterEntityBasicField } from '@fingo/lib/graphql/customers/fragment';
import { CountryFields } from '@fingo/lib/graphql/fragments';

const USER_INVOICE_PROFILE = gql`
  query userInvoiceProfile($invoiceId: ID!) {
    invoice(invoiceId: $invoiceId) {
      ...InvoiceIdFields
      ...InvoiceStatusFields
      ...InvoiceIntermediateReferenceFields
      ...InvoiceAttachableFields
      ...InvoiceLoanedFields
      ...DownloadInvoiceDocumentFields
      providerTrace @client {
        id
        estimatedPaymentDate
        paymentDate
        comments
        isAutomatic
        eventTime: date
        status {
          id
          code
          status
        }
        bank {
          id
          name
        }
      }
      providerRejectionReasons @client {
        id
        estimatedPaymentDate
        paymentDate
        comments
        isAutomatic
        eventTime: date
        status {
          id
          code
          status
        }
        bank {
          id
          name
        }
      }
      paymentMethod @client
      dteType {
        ...InvoiceDteTypeFields
      }
      invoicedetail {
        ...InvoiceDetailFields
      }
      company {
        id
        masterEntity {
          ...MasterEntityBasicField
          country {
            ...CountryFields
          }
        }
      }
      receiver {
        ...MasterEntityBasicField
        country {
          ...CountryFields
        }
        company {
          id
          hasInvoiceProviderCredentials
        }
      }
      traces {
        ...TraceFields
      }
      cessions {
        ...CessionsFields
      }
      uniqueDocumentFinanceState {
        ...DocumentFinanceStateFields
      }
      binnacleSet {
        id
        message
        username
        mail
        username
      }
      mailInvoices {
        id
        mailParameter {
          mail {
            id
            emailName
          }
        }
        updatedAt
        sendGridData {
          id
          toEmail
          events {
            id
            createdAt
            status
          }
        }
      }
    }
  }
  ${InvoiceIdFields}
  ${InvoiceDteTypeFields}
  ${InvoiceStatusFields}
  ${InvoiceIntermediateReferenceFields}
  ${MasterEntityBasicField}
  ${TraceFields}
  ${CessionsFields}
  ${DocumentFinanceStateFields}
  ${InvoiceAttachableFields}
  ${InvoiceLoanedFields}
  ${InvoiceDetailFields}
  ${CountryFields}
  ${DownloadInvoiceDocumentFields}
`;

export default USER_INVOICE_PROFILE;
