import { useMutation } from '@apollo/client';
import Cookies from 'universal-cookie';
import REGISTER_USER from '../graphql/mutations/register-user';

const useRegister = () => {
  const cookies = new Cookies();
  const utmCampaign = cookies.get('utm_campaign') || null;

  const [registerUser] = useMutation(REGISTER_USER, {
    variables: {
      utmCampaign,
    },
  });

  return { signUp: registerUser };
};

export default useRegister;
