import { gql } from '@apollo/client';

const SET_DEBTOR_PREFERENCES = gql`
  mutation setDebtorPreferences(
    $companyId: ID!
    $masterEntityId: ID
    $expirationDays: Int!
    $allDebtors: Boolean!
  ) {
    setDebtorPreferences(
      companyId: $companyId
      masterEntityId: $masterEntityId
      expirationDays: $expirationDays
      allDebtors: $allDebtors
    ) {
      preferences {
        id
        expirationDays
      }
    }
  }
`;

export default SET_DEBTOR_PREFERENCES;
