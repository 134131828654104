import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useGetUser, useSnackBars } from '@fingo/lib/hooks';
import { formatRut } from '@fingo/lib/helpers';
import { useMutation, useReactiveVar } from '@apollo/client';
import popUpRecoveryPasswordDemoUser from '@fingo/lib/apollo/reactive-variables/user';
import { EmailSent } from '@fingo/lib/assets';
import { Box } from '@mui/system';
import SEND_EMAIL_PASSWORD_RECOVERY from '../../graphql/mutations/send-email-password-recovery';

export default function FinishRegisterAutomaticDialog() {
  const [open, setOpen] = useState(false);
  const currentUser = useGetUser();
  const { addAlert } = useSnackBars();
  const popUpRecoveryPassword = useReactiveVar(popUpRecoveryPasswordDemoUser);
  const data = useMemo(() => ({ email: currentUser?.mailProvided, rut: formatRut(currentUser?.selectedCompany?.rut), password: '' }), [currentUser]);
  const [sendMail] = useMutation(SEND_EMAIL_PASSWORD_RECOVERY, {
    onCompleted: () => {
      addAlert({
        id: 'new-leads',
        message: 'Se te ha envíado un correo para que crees tu contraseña!',
      });
    },
  });

  const handleClose = useCallback(() => {
    setOpen(false);
    if (!popUpRecoveryPassword) {
      sendMail({ variables: { email: data.email } });
      popUpRecoveryPasswordDemoUser(true);
    }
  }, [popUpRecoveryPassword]);
  useEffect(() => {
    if (currentUser.requireChangePassword) {
      const timeId = setTimeout(() => {
      // After 10 seconds set the show value to false
        setOpen(true);
      }, 10000);
      return () => {
        clearTimeout(timeId);
      };
    }
    return () => {};
  }, []);
  if (popUpRecoveryPassword) {
    return null;
  }
  return (
    <FingoDialog
      open={open}
      title="Completa tu información de contacto"
      handleClose={handleClose}
    >
      <Stack
        width={{ xs: '100%', md: 320, lg: 320 }}
        justifySelf="center"
        justifyContent="space-between"
      >
        <Box
          component="img"
          src={EmailSent}
          alt="alt"
          sx={{
            width: { xs: '50px', md: '100px' },
            alignSelf: 'center',
            mr: '20px',
          }}
        />
        <Typography sx={{ fontSize: 14, textAlign: 'center', mb: '20px' }}>
          Te enviaremos un correo para que puedas
          <Typography sx={{ fontSize: 14, textAlign: 'center', fontWeight: 'bold' }}>
            cambiar/crear tu contraseña
          </Typography>
        </Typography>

        <Button
          size="small"
          variant="contained"
          onClick={() => handleClose()}
        >
          ¡De acuerdo!
        </Button>
      </Stack>
    </FingoDialog>
  );
}
