import { gql } from '@apollo/client';
import CollectionMasterEntityFragment from '@fingo/lib/graphql/fragments/collection-master-entity';
import { PendingCollectionManagerFragment } from '@fingo/lib/graphql/fragments/pending-collection-manager';

const KOBROS_COLLECTION_MASTER_ENTITIES = gql`
  query kobrosCollectionMasterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $orderBy: String
    $inCollection: Int
    $debtor_CollectionPriorities_Priority_Value: Int
    $countryId: Int
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inCollection: $inCollection
      orderBy: $orderBy
      debtor_CollectionPriorities_Priority_Value: $debtor_CollectionPriorities_Priority_Value
      countryId: $countryId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ...CollectionMasterEntityFragment
          pendingCollectionManagers: pendingInCollectionInvoice {
            ...PendingCollectionManagerFragment
          }
          debtor {
            debtorPreferences(ownerId: $inCollection) {
              id
              expirationDays
            }
          }
        }
      }
    }
  }
  ${CollectionMasterEntityFragment}
  ${PendingCollectionManagerFragment}
`;

export default KOBROS_COLLECTION_MASTER_ENTITIES;
