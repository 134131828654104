import React from 'react';
import PropTypes from 'prop-types';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ReactComponent as BackgroundLogo } from '../../assets/icons/background-logo.svg';

const HomeContainer = ({ children }) => {
  const isMobile = useIsMobile('desktop');
  return (
    <Box height={1} sx={{ overflow: 'hidden', position: 'relative' }}>
      <BackgroundLogo
        style={{
          position: 'absolute',
          top: 0,
          right: isMobile ? -10 : -30,
          width: isMobile ? 208 : 323,
          height: isMobile ? 156 : 242,
        }}
      />
      <Stack
        spacing={{ mobile: 3, desktop: 5 }}
        height={1}
        width={1}
        py={{ mobile: 3, desktop: 3.75 }}
        px={{ mobile: 2, lg: 0 }}
        maxWidth="lg"
        mx="auto"
      >
        {children}
      </Stack>
    </Box>
  );
};

HomeContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HomeContainer;
