import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { factoringTabs } from '../../../routes/sales';
import useCountFactoring from '../../../features/factoring/hooks/useCountFactoring';

const Factoring = () => {
  const country = useGetCountryFromUrl();
  const { preoffers, evaluations, offers, cessions } = useCountFactoring();
  const tabs = factoringTabs(country?.name).map((tab, idx) => ({
    ...tab,
    quantity: [preoffers, evaluations, offers, cessions][idx],
  }));
  return <FingoPaper tabs={tabs} />;
};

export default Factoring;
