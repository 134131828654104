import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider as Apollo } from '@apollo/client';
import client from '@fingo/lib/apollo';

const ApolloProvider = ({ children }) => (
  <Apollo client={client}>{children}</Apollo>
);

ApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApolloProvider;
