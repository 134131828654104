import { gql } from '@apollo/client';

const READ_NOTIFICATIONS = gql`
  mutation markNotificationAsRead($notificationIds: [ID]) {
    markNotificationAsRead(notificationIds: $notificationIds) {
      success
    }
  }
`;

export default READ_NOTIFICATIONS;
