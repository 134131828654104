import { gql } from '@apollo/client';

const HOME_SUMMARY = gql`
  query HomeSummary($companyId: Int, $rejectedDateIssued_Gte: Date) {
    offeredInvoices: invoices(companyId: $companyId, hasValidOffer: true) {
      totalCount
      totalAmount: aggregate(field: "amount_with_iva")
      edges {
        node {
          id
        }
      }
    }
    rejectedInvoices: invoices(
      companyId: $companyId
      preoffer_Preofferevaluationrequest_Status_In: ["Rejected"]
      dateIssued_Gte: $rejectedDateIssued_Gte
    ) {
      totalCount
      totalAmount: aggregate(field: "amount_with_iva")
      edges {
        node {
          id
        }
      }
    }
    debtInvoices: invoices(companyId: $companyId, status: ["Debt"]) {
      totalCount
      totalAmount: aggregate(field: "amount_with_iva")
      edges {
        node {
          id
        }
      }
    }
  }
`;

export default HOME_SUMMARY;
