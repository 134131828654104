import { gql } from '@apollo/client';

const NOTIFICATION_LIST = gql`
  query getNotifications(
    $first: Int
    $offset: Int
    $unread: Boolean
    $globalFilter: String
    $timestamp_Lte: DateTime
    $timestamp_Gte: DateTime
  ) {
    getNotifications(
      first: $first
      offset: $offset
      unread: $unread
      globalFilter: $globalFilter
      timestamp_Lte: $timestamp_Lte
      timestamp_Gte: $timestamp_Gte
    ) {
      totalPages
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          timestamp
          level
          unread
          actorStr
          verbStr
          targetStr
          redirectionPath
        }
      }
    }
  }
`;

export default NOTIFICATION_LIST;
