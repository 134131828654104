import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useBooleanState, useSelectedCompany } from '@fingo/lib/hooks';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CESSIONS_SUMMARY from '@fingo/lib/graphql/queries/cessions-summary';
import CantWithdrawSurplusExplanation from '../dialogs/CantWithdrawSurplusExplanation';
import SurplusDialog from './SurplusDialog';

const Surplus = () => {
  const [open, toggleOpen] = useBooleanState(false);
  const [openExplanation, toggleOpenExplanation] = useBooleanState(false);
  const selectedCompany = useSelectedCompany();

  const { data: cessionsSummary } = useQuery(CESSIONS_SUMMARY, {
    variables: { companyId: selectedCompany.id },
  });

  const amortizationDebt = selectedCompany?.masterEntity.debtAmortizationSchedule?.count > 0;

  const cantWithdrawSurplus = useMemo(
    () => cessionsSummary?.cessionsSummary.debtAmount.amount > 0
      || cessionsSummary?.cessionsSummary.availableSurplus.amount <= 0
      || selectedCompany.pendingReintegrations?.count > 0
      || amortizationDebt,
    [cessionsSummary, amortizationDebt],
  );

  const WithdrawTooltip = useMemo(() => {
    if (cessionsSummary?.cessionsSummary.availableSurplus.amount <= 0) {
      return 'No tienes excedentes disponible para retirar';
    }
    return '';
  }, [cessionsSummary]);

  const handleButton = useCallback(() => {
    if (!cantWithdrawSurplus) {
      toggleOpen();
    } else {
      toggleOpenExplanation();
    }
  }, [cantWithdrawSurplus, toggleOpen, toggleOpenExplanation]);

  return (
    <>
      <Tooltip title={WithdrawTooltip}>
        <span>
          <Button
            color="primary"
            variant="containedReverse"
            fullWidth
            disableElevation
            onClick={handleButton}
            disabled={
              cessionsSummary?.cessionsSummary.availableSurplus.amount <= 0
            }
            sx={{ lineHeight: '1.2', fontSize: '14px' }}
            id="withdraw-surplus"
          >
            {cantWithdrawSurplus
              ? '¿Por qué no puedo retirar excedentes?'
              : 'Retirar excedentes'}
          </Button>
        </span>
      </Tooltip>
      {open && <SurplusDialog open={open} toggleOpen={toggleOpen} />}
      {openExplanation && (
        <CantWithdrawSurplusExplanation
          open={openExplanation}
          onClose={toggleOpenExplanation}
        />
      )}
    </>
  );
};

export default Surplus;
