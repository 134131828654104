import React, { memo } from 'react';
import Typography from '@mui/material/Typography';

const HomeSummaryPaperTitle = () => (
  <>
    <Typography variant="medium_1" fontWeight={700} color="primary">
      Estado actual de tu financiamiento
    </Typography>
    <Typography variant="small_2" color="gray.A400" fontWeight={600}>
      Información clave de tus solicitudes
    </Typography>
  </>
);

export default memo(HomeSummaryPaperTitle);
