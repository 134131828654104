import { useQuery } from '@apollo/client';
import { useSelectedCompany } from '@fingo/lib/hooks';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import dayjs from '@fingo/lib/config/dayjs';
import { useMemo } from 'react';
import HOME_RECEIVED_DATA from '../features/home/graphql/queries/home-received-data';

const useHomeReceivedData = () => {
  const startOfWeek = useMemo(
    () => dayjs().startOf('week').format('YYYY-MM-DD'),
    [],
  );
  const country = useGetCountryFromUrl();
  const selectedCompany = useSelectedCompany();
  const dateIssuedGte = startOfWeek;
  const companyId = selectedCompany?.id;
  const dteTypeCountryId = country?.id;
  const { data, loading } = useQuery(HOME_RECEIVED_DATA, {
    variables: {
      dateIssued_Gte: dateIssuedGte,
      companyId,
      dteType_CountryId: dteTypeCountryId,
    },
    skip: !companyId || !dteTypeCountryId || !dateIssuedGte,
  });
  const { rejectedInvoices, receivedInvoices, creditNotesInvoices } = data || {};
  return {
    rejectedInvoicesCount: rejectedInvoices?.totalCount || 0,
    rejectedInvoicesAmount: rejectedInvoices?.aggregate || 0,
    receivedInvoicesCount: receivedInvoices?.totalCount || 0,
    receivedInvoicesAmount: receivedInvoices?.aggregate || 0,
    creditNotesInvoicesCount: creditNotesInvoices?.totalCount || 0,
    creditNotesInvoicesAmount: creditNotesInvoices?.aggregate || 0,
    loading,
  };
};

export default useHomeReceivedData;
