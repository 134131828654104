import { gql } from '@apollo/client';
import { PurchaseOrderFragment } from '@fingo/lib/graphql/fragments';

const PURCHASE_ORDERS_CONFIRM_OFFER = gql`
  mutation purchaseOrderConfirmOffer(
    $purchaseOrders: [Int]!
    $bankAccountId: Int!
    $inCollection: Boolean = false
    $getRates: Boolean = true
  ) {
    purchaseOrderConfirmOffer(
      purchaseOrders: $purchaseOrders
      bankAccountId: $bankAccountId
    ) {
      purchaseOrders {
        ...PurchaseOrderFragment
      }
    }
  }
  ${PurchaseOrderFragment}
`;

export default PURCHASE_ORDERS_CONFIRM_OFFER;
