import { gql } from '@apollo/client';
import { PurchaseOrderFragment } from '@fingo/lib/graphql/fragments';

const SIGN_ORDER_ASSIGNMENT_DOCUMENT = gql`
  mutation SignOrderAssignmentDocument(
    $id: String!
    $secretKey: String!
    $inCollection: Boolean = false
    $getRates: Boolean = true
  ) {
    signOrderAssignmentDocument(id: $id, secretKey: $secretKey) {
      purchaseOrders {
        ...PurchaseOrderFragment
      }
    }
  }
  ${PurchaseOrderFragment}
`;

export default SIGN_ORDER_ASSIGNMENT_DOCUMENT;
