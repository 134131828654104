import { gql } from '@apollo/client';
import { UserFields } from '../fragments';

const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo(
    $phoneNumber: String
    $firstName: String
    $lastName: String
    $rut: String
    $completeRut: String
    $mailProvided: String
  ) {
    updateUserInfo(
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      rut: $rut
      completeRut: $completeRut
      mailProvided: $mailProvided
    ) {
      user {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

export default UPDATE_USER_INFO;
