import React from 'react';
import { t } from 'i18next';
import UploadDocumentButton from '@fingo/lib/components/buttons/UploadDocumentButton';
import AmountWithIvaCell from '@fingo/lib/components/cells/AmountWithIvaCell';
import CompanyCell from '@fingo/lib/components/cells/CompanyCell';
import DateCell from '@fingo/lib/components/cells/DateCell';
import FactoringStatusCell from '@fingo/lib/components/cells/FactoringStatusCell';
import FolioCell from '@fingo/lib/components/cells/FolioCell';
import formatDecimal from '@fingo/lib/helpers/formatters';

const COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
    disableColumnMenu: true,
    width: 105,
    renderCell: ({ row }) => <FolioCell invoice={row} />,
  },
  {
    field: 'dteType_Code',
    headerName: t('dte'),
    sortable: false,
    disableColumnMenu: true,
    width: 70,
    valueGetter: ({ row }) => row.dteType.name,
  },
  {
    field: 'receiver_sortable',
    headerName: 'Receptor',
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => <CompanyCell company={row.receiver} />,
  },
  {
    field: 'dateIssued',
    headerName: 'Emisión',
    disableColumnMenu: true,
    width: 105,
    renderCell: ({ row }) => <DateCell date={row.dateIssued} />,
  },
  {
    field: 'amountWithIva',
    headerName: 'Monto',
    disableColumnMenu: true,
    width: 110,
    renderCell: ({ row }) => <AmountWithIvaCell row={row} />,
  },
  {
    field: 'preoffer_MonthlyRate',
    headerName: 'Tasa',
    disableColumnMenu: true,
    width: 75,
    valueGetter: ({ row }) => {
      const { preoffer, preofferSelectable } = row;
      const preofferEvaluationRequest = preoffer?.preofferevaluationrequest;
      const inEvaluation = ['EVALUATING', 'PENDINGDOCUMENTS'].includes(
        preofferEvaluationRequest?.status,
      );
      if (!preofferSelectable?.selectable && !inEvaluation) {
        return 'N/D';
      }
      return formatDecimal(preoffer.monthlyRate);
    },
  },
  {
    field: 'defaultRate',
    headerName: 'Mora',
    sortable: false,
    disableColumnMenu: true,
    width: 60,
    valueGetter: ({ row }) => {
      const { preoffer, preofferSelectable } = row;
      const preofferEvaluationRequest = preoffer?.preofferevaluationrequest;
      const inEvaluation = ['EVALUATING', 'PENDINGDOCUMENTS'].includes(
        preofferEvaluationRequest?.status,
      );
      if (!preofferSelectable?.selectable && !inEvaluation) {
        return 'N/D';
      }
      return formatDecimal(preoffer.defaultRate);
    },
  },
  {
    field: 'factoringStatus',
    headerName: 'Estado',
    sortable: false,
    disableColumnMenu: true,
    width: 130,
    renderCell: ({ row }) => <FactoringStatusCell invoice={row} />,
  },
  {
    field: 'uploadDocuments',
    type: 'actions',
    width: 65,
    renderCell: ({ row }) => <UploadDocumentButton invoiceId={row.id} />,
  },
];

export default COLUMNS;
