import { gql } from '@apollo/client';
import MoneyFields from '../fragments/money/money-fields';

const INVOICE_DEBTOR_SUMMARY = gql`
  query invoiceDebtorSummary(
    $ownerId: ID!
    $debtorId: String!
    $currency: String
  ) {
    debtorSummary(ownerId: $ownerId, debtorId: $debtorId, currency: $currency) {
      currentDebtAmount {
        ...MoneyFields
      }
      badDebtAmount {
        ...MoneyFields
      }
      averagePaymentTerm
      collectedInvoices
      lateCollectedInvoices
    }
  }
  ${MoneyFields}
`;

export default INVOICE_DEBTOR_SUMMARY;
