import { gql } from '@apollo/client';
import { CompanyFields } from '../fragments';

const CHANGE_COMPANY = gql`
  mutation changeCompany($companyId: Int!) {
    changeCompany(companyId: $companyId) {
      getUser {
        id
        selectedCompany {
          ...CompanyFields
        }
        permissions
      }
    }
  }
  ${CompanyFields}
`;

export default CHANGE_COMPANY;
