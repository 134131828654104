import { useQuery } from '@apollo/client';
import { preofferSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { cleanPreOfferShoppingCart } from '@fingo/lib/apollo/helpers/shopping-cart';
import FingoAlert from '@fingo/lib/components/dialogs/alerts/FingoAlert';
import PREOFFER_SHOPPING_CART from '@fingo/lib/graphql/shopping/queries/preoffer-shopping-cart';
import { hasAllRequiredDocuments } from '@fingo/lib/helpers/preoffer-conditions';
import {
  useAlertMessage,
  useCreditLine,
  useSelectedCompany,
} from '@fingo/lib/hooks';
import useEvaluatePreoffers from '@fingo/lib/hooks/useEvaluatePreoffers';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { openUploadCreditLineDocumentsDialogVar } from '../../../features/home/graphql/reactive-variables';
import ShoppingCartSummary from '../ShoppingCartSummary/ShoppingCartSummary';
import ShoppingCartSummaryActionButton from '../ShoppingCartSummary/ShoppingCartSummaryActionButton';

const MexicoPreofferShoppingStepOne = ({
  nextStep,
  setEvaluatedInvoicesId,
  summaryCart,
}) => {
  const [alertMessage, setAlertMessage] = useAlertMessage();
  const selectedCompany = useSelectedCompany();
  const creditLine = useCreditLine();
  const {
    data: { selectedPreofferIds },
  } = useQuery(preofferSelectedQuery);
  const { loading: loadingCart } = useQuery(PREOFFER_SHOPPING_CART, {
    variables: { invoicesId: selectedPreofferIds },
  });

  const hasApprovedAmount = useMemo(
    () => creditLine && creditLine.limitAmount,
    [creditLine],
  );

  const validateMexicoOperation = useMemo(() => {
    if (!selectedCompany) return false;
    return hasAllRequiredDocuments(selectedCompany);
  }, [selectedCompany, openUploadCreditLineDocumentsDialogVar]);

  if (!hasApprovedAmount && !validateMexicoOperation && !alertMessage.show) {
    setAlertMessage({
      message: 'Debes subir todos los documentos requeridos para poder evaluar',
      severity: 'error',
      show: true,
    });
  }

  if (!hasApprovedAmount && validateMexicoOperation && !alertMessage.show) {
    setAlertMessage({
      message:
        'Tus documentos están en evaluación, por favor espera a que sean aprobados para poder evaluar',
      severity: 'error',
      show: true,
    });
  }

  if (hasApprovedAmount && validateMexicoOperation && alertMessage.show) {
    setAlertMessage({
      message: '',
      severity: 'error',
      show: false,
    });
  }

  const finishMutation = useCallback(() => {
    setEvaluatedInvoicesId(selectedPreofferIds);
    cleanPreOfferShoppingCart();
    nextStep();
  }, [selectedPreofferIds, nextStep]);

  const { requestPreoffer: evaluatePreoffers, loading } = useEvaluatePreoffers(finishMutation);

  const canEvaluate = hasApprovedAmount && (validateMexicoOperation || hasApprovedAmount);

  return (
    <Box sx={{ pt: 1 }}>
      <FingoAlert alert={alertMessage} setAlert={setAlertMessage} />
      <ShoppingCartSummary
        cartType="preoffer"
        summaryItems={summaryCart}
        cleanFunction={cleanPreOfferShoppingCart}
        loading={loadingCart}
        actionButton={(
          <ShoppingCartSummaryActionButton
            id="cart-preoffer-evaluate"
            label={canEvaluate ? 'Evaluar' : 'Subir documentos'}
            loading={loading}
            disabled={validateMexicoOperation && !hasApprovedAmount}
            onClick={
              canEvaluate
                ? evaluatePreoffers
                : () => openUploadCreditLineDocumentsDialogVar(true)
            }
          />
        )}
      />
    </Box>
  );
};

MexicoPreofferShoppingStepOne.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setEvaluatedInvoicesId: PropTypes.func.isRequired,
  summaryCart: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      money: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default MexicoPreofferShoppingStepOne;
