const MuiButton = {
  variants: [
    {
      props: { variant: 'containedReverse', color: 'primary' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.gray.A100,
        },
      }),
    },
    {
      props: { variant: 'home' },
      style: ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '8px',
        padding: '16px 8px',
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
        boxShadow: '2px 2px 12px 0px rgba(0, 0, 0, 0.15)',
        '& .MuiButton-startIcon': {
          marginLeft: '0px',
          marginRight: '0px',
        },
        [theme.breakpoints.up('desktop')]: {
          flexDirection: 'row',
          gap: '16px',
          padding: '24px 16px 24px 32px',
          '&:hover': {
            backgroundColor: theme.palette.common.white,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)',
            transform: 'scale(1.05)',
          },
        },
      }),
    },
    {
      props: { variant: 'form' },
      style: ({ theme }) => ({
        color: theme.palette.common.white,
        borderRadius: 30,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.A800,
        },
        '&:disabled': {
          backgroundColor: theme.palette.gray.A300,
        },
      }),
    },
    {
      props: { variant: 'white' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        '&:hover': {
          backgroundColor: theme.palette.gray.A100,
        },
      }),
    },
    {
      props: { variant: 'link' },
      style: ({ theme }) => ({
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: 'transparent',
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      }),
    },
    {
      props: { variant: 'underlineLink' },
      style: ({ theme }) => ({
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      }),
    },
    {
      props: { variant: 'hoverHighlightButton' },
      style: ({ theme }) => ({
        padding: 2,
        width: '50%',
        height: 80,
        backgroundColor: '#F2F2F2',
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
        fontSize: { xs: 12, md: 15 },
      }),
    },
    {
      props: { variant: 'paper' },
      style: {
        borderRadius: '4px',
        boxShadow:
          'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
      },
    },
    {
      props: { variant: 'dropDown' },
      style: ({ theme }) => ({
        justifyContent: 'flex-start',
        color: theme.palette.text.primary,
        fontSize: 12,
        padding: 0,
        '& .MuiButton-startIcon': {
          color: theme.palette.primary.main,
          fontWeigth: 'bold',
          fontSize: 13,
        },
        '& .MuiButton-endIcon': {
          color: theme.palette.primary.main,
        },
      }),
    },
  ],
  styleOverrides: {
    root: {
      py: 2,
      px: 4,
      borderRadius: '4px',
    },
    label: {
      height: '100%',
    },
    sizeSmall: {
      padding: '8px 16px',
      height: 'min-content',
    },
  },
};

export default MuiButton;
