import { gql } from '@apollo/client';

const CREATE_EXTERNAL_PURCHASE_ORDER = gql`
  mutation CreateExternalPurchaseOrder(
    $externalPurchaseOrders: [ExternalPurchaseOrderType]!
  ) {
    createExternalPurchaseOrder(
      externalPurchaseOrders: $externalPurchaseOrders
    ) {
      success
      message
    }
  }
`;

export default CREATE_EXTERNAL_PURCHASE_ORDER;
