import React, { useEffect } from 'react';
import { Intercom, LiveChatLoaderProvider } from 'react-live-chat-loader';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';

const IntercomProvider = ({ children }) => {
  const theme = useTheme();
  const user = useGetUser();
  const company = useSelectedCompany();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (user) {
        window.intercomSettings = {
          name: user.completeName,
          userId: user.id,
          email: user.mailProvided || user.email,
          phone: user.phoneNumber,
          company: {
            companyId: company?.id,
            name: company?.name,
          },
        };
      }
    }
  }, [user, company]);

  return (
    <LiveChatLoaderProvider provider="intercom" providerKey="b72hnksj">
      <Intercom color={theme.palette.primary.main} />
      {children}
    </LiveChatLoaderProvider>
  );
};

IntercomProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IntercomProvider;
