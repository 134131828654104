import { useQuery } from '@apollo/client';
import { useSelectedCompany } from '@fingo/lib/hooks';
import COUNT_ORDERING from '../graphql/queries/count-ordering';

const useCountOrdering = () => {
  const selectedCompany = useSelectedCompany();
  const { data } = useQuery(COUNT_ORDERING, {
    variables: {
      companyId: selectedCompany?.id,
    },
    skip: !selectedCompany,
  });
  return {
    available: data?.available.totalCount,
    offers: data?.offers.totalCount,
    activeOperations: data?.activeOperations.totalCount,
    finishedOperations: data?.finishedOperations.totalCount,
  };
};

export default useCountOrdering;
