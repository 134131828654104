import { gql } from '@apollo/client';

const ADD_OR_EDIT_CONTACT = gql`
  mutation createOrEditContact(
    $isNew: Boolean!
    $id: Int
    $name: String
    $phoneNumber: String
    $position: String
    $email: String!
    $company: Int!
    $contactType: String!
    $source: String
  ) {
    createOrEditContact(
      isNew: $isNew
      name: $name
      phoneNumber: $phoneNumber
      position: $position
      email: $email
      id: $id
      company: $company
      contactType: $contactType
      source: $source
    ) {
      messageType
      message
      contact {
        id
        name
        email
        position
        phoneNumber
        userInstance {
          id
          activeInvitations {
            companyFrom {
              id
              rut
            }
          }
        }
      }
    }
  }
`;

export default ADD_OR_EDIT_CONTACT;
