import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const LogoNavItem = ({ image, isLogged }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const redirectUrl = isLogged ? '/app/home' : t('landing-path');

  const handleClick = () => {
    if (isLogged) {
      history.push(redirectUrl);
    } else {
      window.location.replace(redirectUrl);
    }
  };

  return (
    <Box onClick={handleClick} style={{ cursor: 'pointer' }}>
      {image}
    </Box>
  );
};

LogoNavItem.propTypes = {
  image: PropTypes.node.isRequired,
  isLogged: PropTypes.bool.isRequired,
};

export default LogoNavItem;
