import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { whatsappLink } from '@fingo/lib/constants/links';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const ExecutiveContactButton = ({ executive }) => {
  const isMobile = useIsMobile('desktop');
  const whatsAppClickHandler = useCallback(
    () => window.open(executive?.whatsAppUrl || whatsappLink, '_blank'),
    [],
  );

  const buttonText = useMemo(() => {
    if (!executive) {
      return 'Contactarme con fingo';
    }
    if (isMobile) {
      return '¡Quiero que me contacten!';
    }
    return 'Contactar a mi ejecutivo';
  }, [executive, isMobile]);

  return (
    <Button
      variant="contained"
      startIcon={<WhatsAppIcon />}
      onClick={whatsAppClickHandler}
      sx={{
        width: { mobile: 240, desktop: 228 },
        height: { mobile: 40, desktop: 36 },
        ml: { mobile: 0, desktop: 'auto' },
      }}
    >
      <Typography variant="small_1">{buttonText}</Typography>
    </Button>
  );
};

ExecutiveContactButton.propTypes = {
  executive: PropTypes.shape({
    whatsAppUrl: PropTypes.string,
  }),
};

ExecutiveContactButton.defaultProps = {
  executive: null,
};

export default ExecutiveContactButton;
