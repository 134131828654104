import { gql } from '@apollo/client';

const VERIFY_OTP = gql`
  mutation otpVerification($email: String!, $otp: String!) {
    otpVerification(email: $email, otp: $otp) {
      payload
      refreshExpiresIn
      token
      refreshToken
    }
  }
`;

export default VERIFY_OTP;
