import {
  ContractTextInput,
  ContractRutInput,
  CivilStatusInput,
  CompanyTypeInput,
  PersonLabelInput,
  ContractTypeInput,
  FileFormatInput,
} from '../inputs';

export const labelInputs = [
  [
    {
      id: 'name',
      index: 0,
      label: 'Nombre',
      sx: { width: '67%' },
      disabled: true,
      component: ContractTextInput,
      inputProps: {},
    },
    {
      id: 'rut',
      index: 1,
      label: 'Rut',
      sx: { width: '33%' },
      disabled: true,
      component: ContractTextInput,
      inputProps: {},
    },
  ],
  [
    {
      id: 'mainActivity',
      index: 2,
      label: 'Giro',
      sx: { width: '100%' },
      disabled: false,
      component: ContractTextInput,
    },
  ],
  [
    {
      id: 'address',
      index: 3,
      label: 'Dirección y comuna',
      sx: { width: '67%' },
      disabled: false,
      component: ContractTextInput,
      inputProps: {},
    },
    {
      id: 'societyType',
      index: 4,
      label: 'Tipo Sociedad',
      sx: { width: '33%' },
      disabled: false,
      component: CompanyTypeInput,
    },
  ],
  [{
    id: 'contractType',
    index: 5,
    label: 'Tipo de Contrato',
    sx: { width: '50%' },
    disabled: false,
    component: ContractTypeInput,
  },
  {
    id: 'fileFormat',
    index: 6,
    label: 'Formato del archivo',
    sx: { width: '50%' },
    disabled: false,
    component: FileFormatInput,
  },
  ],
];

export const legalRepresentativesInput = [
  [
    {
      id: 'label',
      index: 0,
      label: 'Título',
      sx: { width: '20%' },
      disabled: false,
      component: PersonLabelInput,
      inputProps: {},
    },
    {
      id: 'name',
      index: 0,
      label: 'Nombre',
      sx: { width: '80%' },
      disabled: true,
      component: ContractTextInput,
      inputProps: {},
    },
  ],
  [
    {
      id: 'rut',
      index: 1,
      label: 'Rut',
      sx: { width: '50%' },
      disabled: true,
      component: ContractRutInput,
    },
    {
      id: 'nationality',
      index: 2,
      label: 'Nacionalidad',
      sx: { width: '50%' },
      disabled: false,
      component: ContractTextInput,
      inputProps: { style: { textTransform: 'lowercase' } },
    },
  ],
  [
    {
      id: 'email',
      index: 3,
      label: 'Email',
      sx: { width: '50%' },
      disabled: false,
      component: ContractTextInput,
      inputProps: { style: { textTransform: 'lowercase' } },
    },
    {
      id: 'profession',
      index: 4,
      label: 'Profesión',
      sx: { width: '50%' },
      disabled: false,
      component: ContractTextInput,
      inputProps: { style: { textTransform: 'lowercase' } },
    },
  ],
  [
    {
      id: 'civilStatus',
      index: 5,
      label: 'Estado Civil',
      sx: { width: '100%' },
      disabled: false,
      component: CivilStatusInput,
    },
  ],
];

export const endorsementsInputs = [
  [
    {
      id: 'label',
      index: 0,
      label: 'Título',
      sx: { width: '20%' },
      disabled: false,
      component: PersonLabelInput,
      inputProps: {},
    },
    {
      id: 'name',
      index: 0,
      label: 'Nombre',
      sx: { width: '80%' },
      disabled: true,
      component: ContractTextInput,
      inputProps: {},
    },
  ],
  [
    {
      id: 'rut',
      index: 1,
      label: 'Rut',
      sx: { width: '50%' },
      disabled: true,
      component: ContractRutInput,
    },
    {
      id: 'nationality',
      index: 2,
      label: 'Nacionalidad',
      sx: { width: '50%' },
      disabled: false,
      component: ContractTextInput,
      inputProps: { style: { textTransform: 'lowercase' } },
    },
  ],
  [
    {
      id: 'civilStatus',
      index: 3,
      label: 'Estado Civil',
      sx: { width: '100%' },
      disabled: false,
      component: CivilStatusInput,
    },
    {
      id: 'profession',
      index: 4,
      label: 'Profesión',
      sx: { width: '50%' },
      disabled: false,
      component: ContractTextInput,
      inputProps: { style: { textTransform: 'lowercase' } },
    },
  ],
  [
    {
      id: 'address',
      index: 5,
      label: 'Dirección Particular Aval',
      sx: { width: '100%' },
      disabled: false,
      component: ContractTextInput,
    },
  ],
  [
    {
      id: 'email',
      index: 6,
      label: 'Email',
      sx: { width: '50%' },
      disabled: false,
      component: ContractTextInput,
      inputProps: { style: { textTransform: 'lowercase' } },
    },
  ],
  [
    {
      id: 'personeriaComments',
      index: 7,
      label: 'Comentarios personería Aval',
      sx: { width: '100%' },
      minRows: 3,
      multiline: true,
      disabled: false,
      component: ContractTextInput,
    },
  ],
];

export const endorsementsSpouseInputs = [
  [
    {
      id: 'spouceLabel',
      index: 0,
      label: 'Título',
      sx: { width: '20%' },
      disabled: false,
      component: PersonLabelInput,
      inputProps: {},
    },
    {
      id: 'spouceName',
      index: 7,
      label: 'Nombre Conyuge',
      sx: { width: '80%' },
      disabled: false,
      component: ContractTextInput,
      inputProps: {},
    },
  ],
  [
    {
      id: 'spouceRut',
      index: 8,
      label: 'Rut Conyuge',
      sx: { width: '50%' },
      disabled: false,
      component: ContractRutInput,
    },
    {
      id: 'spouceNationality',
      index: 9,
      label: 'Nacionalidad Conyuge',
      sx: { width: '50%' },
      disabled: false,
      component: ContractTextInput,
      inputProps: { style: { textTransform: 'lowercase' } },
    },
  ],
  [
    {
      id: 'spouceAddress',
      index: 10,
      label: 'Dirección Particular Conyuge',
      sx: { width: '50%' },
      disabled: false,
      component: ContractTextInput,
    },
    {
      id: 'spouceEmail',
      index: 11,
      label: 'Correo Cónyuge',
      sx: { width: '50%' },
      disabled: false,
      component: ContractTextInput,
      inputProps: { style: { textTransform: 'lowercase' } },
    },
  ],
];

export const personeriaCommentsInput = [
  [
    {
      id: 'personeriaComments',
      index: 0,
      label: 'Comentarios Personería',
      sx: { width: '100%' },
      disabled: false,
      component: ContractTextInput,
      minRows: 3,
      multiline: true },
  ],
];
