import { gql } from '@apollo/client';

const NPSFields = gql`
  fragment NPSFields on NPSType {
    id
    npsType {
      id
      name
      questions
      title
      reasons {
        category
        categoryToEs
      }
    }
  }
`;

export default NPSFields;
