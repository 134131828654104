import { gql } from '@apollo/client';
import { sharedInvoiceInput, sharedInvoiceInputFields } from '../fragments';

const EXPORT_INVOICES = gql`
  query exportInvoices(
    $rowCount: Int
    $extraFields: [String]
    $exportType: String
    ${sharedInvoiceInputFields}
  ) {
    exportInvoices(
      rowCount: $rowCount
      extraFields: $extraFields
      exportType: $exportType
      ${sharedInvoiceInput}
    )
  }
`;

export default EXPORT_INVOICES;
