import { gql } from '@apollo/client';

export const CONFIRM_ORDERING_SIMULATION = gql`
  mutation ConfirmOrderingSimulation(
    $simulationId: Int!
    $orderingInterest: Int!
    $factoringInterest: Int!
    $orderingPaymentAmount: Int!
    $factoringPaymentAmount: Int!
    $invoiceIssuedDate: Date!
    $invoiceDateToPay: Date!
  ) {
    confirmOrderingSimulation(
      simulationId: $simulationId
      orderingInterest: $orderingInterest
      factoringInterest: $factoringInterest
      orderingPaymentAmount: $orderingPaymentAmount
      factoringPaymentAmount: $factoringPaymentAmount
      invoiceIssuedDate: $invoiceIssuedDate
      invoiceDateToPay: $invoiceDateToPay
    ) {
      success
      message
    }
  }
`;

export const UPDATE_SIMULATION_SENT_REGISTER = gql`
  mutation UpdateSimulationSentUrlRegister(
    $url: String!
    $urlOpened: Boolean!
    $sentToSimulation: Boolean!
    $sentToAutoregister: Boolean!
  ) {
    updateSimulationSentUrlRegister(
      url: $url
      urlOpened: $urlOpened
      sentToSimulation: $sentToSimulation
      sentToAutoregister: $sentToAutoregister
    ) {
      success
      message
    }
  }
`;
