import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useSelectedCompany } from '@fingo/lib/hooks';
import COLLECTION_SUMMARY from '../graphql/queries/collection-summary';

const useCollectionSummary = () => {
  const selectedCompany = useSelectedCompany();
  const { data, loading } = useQuery(COLLECTION_SUMMARY, {
    variables: { ownerId: selectedCompany.id },
    skip: !selectedCompany.id,
  });
  const collectionManagersByDateRange = useMemo(
    () => data?.factoringCollectionManagersByDateRange || [],
    [data],
  );
  const collectionManagersByPriority = useMemo(
    () => data?.factoringCollectionManagersByPriority || [],
    [data],
  );
  const collectionManagersByExpiration = useMemo(
    () => data?.factoringCollectionManagersByExpiration || [],
    [data],
  );

  return {
    collectionManagersByPriority,
    collectionManagersByDateRange,
    collectionManagersByExpiration,
    loading,
  };
};

export default useCollectionSummary;
