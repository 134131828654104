import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const BoldTypography = ({ label, boldLabel }) => (
  <Typography
    variant="small_2"
    textAlign={{ mobile: 'center', desktop: 'left' }}
  >
    {label}
    <Typography component="span" variant="small_2" fontWeight={700}>
      {boldLabel}
    </Typography>
  </Typography>
);

BoldTypography.propTypes = {
  label: PropTypes.string.isRequired,
  boldLabel: PropTypes.string.isRequired,
};

export default BoldTypography;
