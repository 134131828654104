import React, { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import LazyImage from '@fingo/lib/components/images/LazyImage';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import HomeJoyride from '../joyrides/HomeJoyride';
import MARK_TUTORIAL_SEEN from '../../../../graphql/mutations/mark-tutorial-as-seen';

const TUTORIAL_CODE = 'CHILE_HOME_JOYRIDE';

const HomeJoyrideDialog = () => {
  const isMobile = useIsMobile();
  const country = useGetCountryFromUrl();
  const user = useGetUser();
  const [run, setRun] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);

  const [markTutorialSeen] = useMutation(MARK_TUTORIAL_SEEN);

  const handleStartTour = () => {
    setRun(true);
    setDialogOpen(false);
    markTutorialSeen({ variables: { tutorialCode: TUTORIAL_CODE } });
  };

  const onClose = () => {
    setRun(false);
  };

  const handleClose = () => {
    markTutorialSeen({ variables: { tutorialCode: TUTORIAL_CODE } });
    setDialogOpen(false);
  };

  const hasHomeJoyrideTutorial = useMemo(() => {
    if (run) {
      return false;
    }
    const tutorials = user?.userseentutorialSet || [];
    return tutorials.some(({ tutorialCode }) => tutorialCode === TUTORIAL_CODE);
  }, [user, run]);

  if (country.name !== 'Chile' || hasHomeJoyrideTutorial) {
    return null;
  }

  return (
    <>
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth={false}>
        <DialogContent
          sx={(theme) => ({
            py: 4,
            px: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            borderRadius: '16px 0px 0px 16px',
            background: `linear-gradient(0deg, #FFF 47.5%, ${theme.palette.primary.A200} 140.75%)`,
          })}
        >
          {!isMobile && (
            <LazyImage
              imagePath="images/joyrides/home-joyride.png"
              alt="Home Joyride"
              width={384}
              height={295}
              format="avif"
            />
          )}
          <Typography
            variant={isMobile ? 'medium_2' : 'large_2'}
            fontWeight={700}
            color="primary.main"
            textAlign="center"
          >
            ¡Tenemos nueva página de inicio!
          </Typography>
          <Typography
            variant={isMobile ? 'small_2' : 'medium_2'}
            fontWeight={500}
            color="gray.A700"
            textAlign="center"
          >
            Queremos ofrecerte una experiencia más personalizada
          </Typography>
          <Typography
            variant={isMobile ? 'small_2' : 'medium_2'}
            fontWeight={500}
            color="gray.A700"
            textAlign="center"
          >
            🚀 ¡Descúbrela ahora!
          </Typography>
          <Button variant="contained" onClick={handleStartTour}>
            Comenzar recorrido
          </Button>
          <Button variant="underlineLink" onClick={() => { onClose(); handleClose(); }}>
            Omitir
          </Button>
        </DialogContent>
      </Dialog>
      {run && <HomeJoyride run={run} onClose={onClose} />}
    </>
  );
};

export default HomeJoyrideDialog;
