import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  offerSelectedQuery,
  orderingSimulationSelection,
  preofferSelectedQuery,
  shoppingCartShowQuery,
} from '@fingo/lib/apollo/cache/localQuery';
import { NavbarButton } from '@fingo/lib/components/buttons';
import { useAnchorEl, useDebounce } from '@fingo/lib/hooks';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  cleanOfferShoppingCart,
  cleanPreOfferShoppingCart,
  cleanOrderingShoppingCart,
} from '@fingo/lib/apollo/helpers/shopping-cart';
import { shoppingCartShow } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import ShoppingCartStack from './ShoppingCartStack';

const ShoppingCart = () => {
  const iconRef = useRef(undefined);
  const [animating, setAnimating] = useState(false);
  const [cartType, setCartType] = useState('available-factoring');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
  const { anchorEl, toggleAnchorEl, open } = useAnchorEl();
  const cartTypeManager = {
    selectedPreofferIds: {
      cartType: 'available-factoring',
      cleanOtherCarts: () => {
        cleanOfferShoppingCart();
        cleanOrderingShoppingCart();
      },
    },
    selectedOfferIds: {
      cartType: 'available-offers',
      cleanOtherCarts: () => {
        cleanPreOfferShoppingCart();
        cleanOrderingShoppingCart();
      },
    },
    selectedOrderingIds: {
      cartType: 'ordering',
      cleanOtherCarts: () => {
        cleanPreOfferShoppingCart();
        cleanOfferShoppingCart();
      },
    },
  };
  const debouncer = useDebounce(() => setAnimating(false), 1000);
  const makeRipple = useCallback(
    (data) => {
      if (data.length === 0) return;
      setAnimating(true);
      debouncer();
    },
    [debouncer],
  );

  const handleSelectedChange = useCallback(
    (previous, property, data) => {
      makeRipple(data[property]);
      if (
        previous
        && data
        && previous[property].length < data[property].length
      ) {
        setCartType(cartTypeManager[property].cartType);
        cartTypeManager[property].cleanOtherCarts();
      }
    },
    [makeRipple],
  );

  const {
    data: { selectedOfferIds },
    previousData: previousOfferIds,
  } = useQuery(offerSelectedQuery, {
    onCompleted: (data) => {
      handleSelectedChange(previousOfferIds, 'selectedOfferIds', data);
    },
  });

  const {
    data: { selectedOrderingIds },
    previousData: previousOrderingIds,
  } = useQuery(orderingSimulationSelection, {
    onCompleted: (data) => {
      handleSelectedChange(previousOrderingIds, 'selectedOrderingIds', data);
    },
  });

  const {
    data: { selectedPreofferIds },
    previousData: previousPreofferIds,
  } = useQuery(preofferSelectedQuery, {
    onCompleted: (data) => {
      handleSelectedChange(previousPreofferIds, 'selectedPreofferIds', data);
    },
  });

  const {
    data: { showShoppingCart },
  } = useQuery(shoppingCartShowQuery, {
    onCompleted: (data) => {
      if (data.showShoppingCart) {
        iconRef.current.click();
        shoppingCartShow(!showShoppingCart);
      }
    },
  });

  const badgeContent = useMemo(
    () => selectedPreofferIds.length
      + selectedOfferIds.length
      + selectedOrderingIds.length,
    [selectedOfferIds, selectedPreofferIds, selectedOrderingIds],
  );

  return (
    <>
      <NavbarButton
        ref={iconRef}
        onClick={toggleAnchorEl}
        animation={animating}
        id="nav-cart"
      >
        <>
          <Badge badgeContent={badgeContent} color="primary">
            <ShoppingCartIcon />
          </Badge>
        </>
      </NavbarButton>
      {!isMobile && iconRef.current && (
        <Menu
          id="shopping-cart"
          anchorEl={anchorEl}
          open={open}
          onClose={toggleAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <ShoppingCartStack toggleMenu={toggleAnchorEl} cartType={cartType} />
        </Menu>
      )}
      {isMobile && (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={toggleAnchorEl}
          onOpen={toggleAnchorEl}
          swipeAreaWidth={30}
          disableSwipeToOpen
        >
          <ShoppingCartStack toggleMenu={toggleAnchorEl} cartType={cartType} />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default ShoppingCart;
