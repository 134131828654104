import Stack from '@mui/material/Stack';
import React, { useMemo, useRef, useState } from 'react';
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
} from 'recharts';
import { useQuery } from '@apollo/client';
import PayrollQuickProfile from './PayrollQuickProfile';
import CONFIRMING_CURRENT_SUPPLIER_ANALYTICS from '../../../../../features/confirming/graphql/queries/confirming-current-supplier-analytics';

const COLORS = [
  '#3FCDCD',
  '#54B052',
  '#EACD59',
  '#FF9B21',
  '#812581',
  '#E62E66',
];

const ConfirmingSupplierChart = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const anchorRef = useRef(null);
  const { data } = useQuery(CONFIRMING_CURRENT_SUPPLIER_ANALYTICS);
  const result = useMemo(() => data?.confirmingCurrentSuppliersAnalytics || [], [data]);
  return (
    result.length ? (
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <PieChart width={240} height={180} ref={anchorRef}>
          <Pie
            dataKey="value"
            data={result}
            innerRadius={48}
            outerRadius={72}
            paddingAngle={2}
          >
            {result.map(({ name, companyId }, index) => (
              <Cell
                key={name}
                fill={COLORS[index % COLORS.length]}
                onClick={() => { setAnchorEl(anchorRef.current); setSelectedCompany(companyId); }}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
        <PayrollQuickProfile
          companyId={selectedCompany}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </Stack>
    )
      : (
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          No hay documentos
        </Stack>
      )
  );
};

export default ConfirmingSupplierChart;
