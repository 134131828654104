import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ExecutiveAvatar from '../avatars/ExecutiveAvatar';
import ExecutiveContactInformation from '../ExecutiveContactInformation';
import ExecutiveContactButton from '../buttons/ExecutiveContactButton';

const ExecutivePaper = () => {
  const company = useSelectedCompany();
  const { t } = useTranslation();
  const executive = company?.executiveAssigned;

  const content = useMemo(() => {
    if (!executive) {
      return (
        <>
          <Typography variant="medium_2" fontWeight={600} color="gray.A700">
            Aún no tienes ejecutivo/a
          </Typography>
          <Typography variant="small_2">
            Debes ingresar tus credenciales del{' '}
            {t('National Tax Service acronym')} para que podamos asignarte un
            ejecutivo
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography variant="medium_2" fontWeight={600} color="gray.A700">
          {executive.completeName}
        </Typography>
        <Typography variant="small_2">
          Hola, soy tu ejecutivo y estoy para ayudarte
          <Typography
            variant="small_2"
            fontWeight={600}
            component="span"
            ml={0.5}
          >
            ¡contáctame!
          </Typography>
        </Typography>
      </>
    );
  }, [executive]);

  return (
    <Paper
      component={Stack}
      px={3}
      py={2.5}
      alignItems="center"
      spacing={2}
      sx={{ borderRadius: 2 }}
    >
      <Stack direction="row" spacing={2}>
        <ExecutiveAvatar executive={executive} />
        <Box component={Stack}>{content}</Box>
      </Stack>
      <ExecutiveContactInformation executive={executive} />
      <ExecutiveContactButton executive={executive} />
    </Paper>
  );
};

export default ExecutivePaper;
