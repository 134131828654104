import { useTheme, useMediaQuery } from '@mui/material';

const useResponsiveImage = (width, height) => {
  const theme = useTheme();

  // Detectar los breakpoints actuales
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  // Resolver valores según breakpoints
  const resolveValue = (value) => {
    if (typeof value === 'number') return value;
    if (typeof value === 'object') {
      if (isMobile && value.mobile) return value.mobile;
      if (isTablet && value.tablet) return value.tablet;
      if (isDesktop && value.desktop) return value.desktop;
      if (isLarge && value.lg) return value.lg;
    }
    return undefined;
  };

  return {
    width: resolveValue(width),
    height: resolveValue(height),
  };
};

export default useResponsiveImage;
