import { gql } from '@apollo/client';
import { CompanyFields } from '../fragments';

const UPDATE_INVOICE_PROVIDER_CREDENTIALS = gql`
  mutation upload(
    $companyId: Int!
    $password: String!
    $naturalPersonRut: String!
    $credentialTypeId: Int!
    $secondUserName: String
  ) {
    uploadInvoiceProviderCredentials(
      companyId: $companyId
      password: $password
      naturalPersonRut: $naturalPersonRut
      credentialTypeId: $credentialTypeId
      secondUsername: $secondUserName
    ) {
      company {
        ...CompanyFields
      }
    }
  }
  ${CompanyFields}
`;

export default UPDATE_INVOICE_PROVIDER_CREDENTIALS;
