import { gql } from '@apollo/client';
import MoneyFields from '../../fragments/money/money-fields';

const ShoppingFields = gql`
  fragment ShoppingFields on ShoppingCartSummaryType {
    invoiceCount
    totalAmount {
      ...MoneyFields
    }
    priceDifference {
      ...MoneyFields
    }
    commission {
      ...MoneyFields
    }
    totalCosts {
      ...MoneyFields
    }
    retainedAmount {
      ...MoneyFields
    }
    orderingDebt {
      ...MoneyFields
    }
    negativeSurplus {
      ...MoneyFields
    }
    rate
    hasPurchaseOrdersNearToDebt
    depositAmount {
      ...MoneyFields
    }
  }
  ${MoneyFields}
`;

export default ShoppingFields;
