import { gql } from '@apollo/client';

const ADD_DATA_FOR_COLLECTION = gql`
  mutation AddDataForCollection(
    $collectionManagerIds: [Int]!
    $collectionContactIds: [Int]!
    $dateToPay: String!
    $paymentMethod: PaymentMethodEnum!
  ) {
    addDataForCollection(
      dataForCollection: {
        collectionManagerIds: $collectionManagerIds
        collectionContactIds: $collectionContactIds
        dateToPay: $dateToPay
        paymentMethod: $paymentMethod
      }
    ) {
      collectionManagers {
        id
        dataforcollectionSet {
          id
          paymentMethod
          dateToPay
        }
      }
    }
  }
`;

export default ADD_DATA_FOR_COLLECTION;
