import { gql } from '@apollo/client';
import { MasterEntityBasicField } from '../customers/fragment';

const GET_SIMPLE_MASTER_ENTITIES = gql`
  query getMasterEntities(
    $first: Int
    $offset: Int
    $orderBy: String
    $countryId: Int
    $globalFilter: String
    $companyClients: Int
    $financinginstitution_Isnull: Boolean
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      orderBy: $orderBy
      countryId: $countryId
      globalFilter: $globalFilter
      companyClients: $companyClients
      financinginstitution_Isnull: $financinginstitution_Isnull
    ) {
      totalPages
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...MasterEntityBasicField
        }
      }
    }
  }
  ${MasterEntityBasicField}
`;

export default GET_SIMPLE_MASTER_ENTITIES;
