import { gql } from '@apollo/client';

const PORTFOLIO_OPTIONS = gql`
  query portfolioOptions {
    getPortfolioTypeEnum {
      value
      label
    }
    getPortfolioFormatEnum {
      value
      label
    }
  }
`;

export default PORTFOLIO_OPTIONS;
