import React from 'react';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CreditLineSlider from '../sliders/CreditLineSlider';

const CreditLinePaper = () => (
  <Paper
    component={Stack}
    p={2}
    pb={3}
    alignItems="center"
    sx={{ borderRadius: 2 }}
  >
    <Stack
      direction="row"
      mb={3}
      justifyContent="space-between"
      width={1}
      alignItems="flex-end"
    >
      <Typography color="gray.A700" variant="medium_1" fontWeight={700}>
        Línea de crédito
      </Typography>
      <Stack direction="row" spacing={2}>
        <Chip
          icon={<FiberManualRecordIcon fontSize="10px" />}
          label="Utilizado"
          variant="contained"
          sx={{
            height: 17,
            fontSize: 12,
            borderRadius: 2,
            bgcolor: 'primary.A100',
            color: 'primary.A800',
            '& .MuiChip-icon': {
              color: 'primary.A800',
            },
          }}
        />
        <Chip
          icon={<FiberManualRecordIcon fontSize="10px" />}
          label="Disponible"
          variant="contained"
          sx={{
            height: 17,
            fontSize: 12,
            borderRadius: 2,
          }}
        />
      </Stack>
    </Stack>
    <CreditLineSlider />
  </Paper>
);

export default CreditLinePaper;
