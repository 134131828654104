import { gql } from '@apollo/client';

const SEND_EMAIL_PASSWORD_RECOVERY = gql`
  mutation sendMail($email: String!) {
    sendEmailRecoveryPassword(email: $email) {
      success
    }
  }
`;

export default SEND_EMAIL_PASSWORD_RECOVERY;
