import { gql } from '@apollo/client';
import { PurchaseOrderFragment } from '@fingo/lib/graphql/fragments';

const PURCHASE_ORDERS_SEND_EVALUATE = gql`
  mutation purchaseOrderSendEvaluation(
    $purchaseOrders: [PurchaseOrderSimulationInput]!
    $inCollection: Boolean = false
    $getRates: Boolean = true
  ) {
    purchaseOrderSendEvaluation(purchaseOrders: $purchaseOrders) {
      purchaseOrders {
        ...PurchaseOrderFragment
      }
    }
  }
  ${PurchaseOrderFragment}
`;

export default PURCHASE_ORDERS_SEND_EVALUATE;
