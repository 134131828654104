import { useMutation, useQuery } from '@apollo/client';
import { orderingOfferSelection } from '@fingo/lib/apollo/cache/localQuery';
import { cleanOrderingOfferShoppingCart } from '@fingo/lib/apollo/helpers/shopping-cart';
import { useSnackBars } from '@fingo/lib/hooks';
import ORDERING_PURCHASE_ORDERS from '@fingo/lib/graphql/queries/ordering-purchase-orders';
import PURCHASE_ORDERS_CONFIRM_OFFER from '../features/ordering/graphql/mutations/purchase-orders-confirm-offer';

const useConfirmOrdering = (selectedAccountId, onCompleted) => {
  const {
    data: { selectedOfferOrderingIds },
  } = useQuery(orderingOfferSelection);
  const { addAlert } = useSnackBars();
  const result = useMutation(PURCHASE_ORDERS_CONFIRM_OFFER, {
    variables: {
      bankAccountId: selectedAccountId,
      purchaseOrders: selectedOfferOrderingIds,
    },
    refetchQueries: [ORDERING_PURCHASE_ORDERS],
    awaitRefetchQueries: true,
    onCompleted: () => {
      onCompleted();
      cleanOrderingOfferShoppingCart();
    },
    onError: (error) => {
      addAlert({
        id: 'confirm-ordering-offer-error',
        message: `Error: ${error.message}`,
      });
    },
  });
  return result;
};

export default useConfirmOrdering;
