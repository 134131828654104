import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LazyImage from '@fingo/lib/components/images/LazyImage';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import Paper from '@mui/material/Paper';
import useHomeTutorial from '../../hooks/useHomeTutorial';
import getImagePath from '../../helpers/images';

const HomeCarousel = () => {
  const isMobile = useIsMobile('desktop');
  const history = useHistory();
  const company = useSelectedCompany();
  const country = useGetCountryFromUrl();
  const { currentTutorial } = useHomeTutorial();

  const handleKobrosClick = useCallback(() => {
    if (!company) {
      history.push('/kobros-register');
    } else {
      history.push('/app/sales/collection/home');
    }
  }, [history, company]);

  const handleTransferClick = useCallback(() => {
    history.push('/app/sales/factoring/cessions');
  }, [history]);

  const { steps, activeStep } = currentTutorial?.hook
    ? currentTutorial.hook()
    : { steps: [], activeStep: 0 };

  const carouselStep = useMemo(() => {
    if (!steps?.[activeStep] && country.name === 'Chile') {
      return {
        id: 'kobros-step',
        imagePath: getImagePath('kobros-banner', isMobile),
        onClick: handleKobrosClick,
      };
    }

    if (!steps?.[activeStep] && country.name === 'Mexico') {
      return {
        id: 'transfered-step',
        imagePath: getImagePath('transfered-banner', isMobile),
        onClick: handleTransferClick,
      };
    }

    return steps[activeStep];
  }, [
    country,
    isMobile,
    handleKobrosClick,
    handleTransferClick,
    steps,
    activeStep,
  ]);

  return (
    <Paper
      onClick={carouselStep.onClick}
      sx={{
        height: 1,
        cursor: 'pointer',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 2,
      }}
    >
      <LazyImage
        imagePath={carouselStep.imagePath}
        alt={`${carouselStep.id} banner`}
        width={isMobile ? 370 : 722}
        height={isMobile ? 305 : 180}
        format="avif"
      />
    </Paper>
  );
};

export default HomeCarousel;
