import { gql } from '@apollo/client';

const ACEPTA_LOGIN = gql`
  mutation AceptaAuth($rut: String!, $password: String!) {
    aceptaAuth(rut: $rut, password: $password) {
      token
      refreshToken
    }
  }
`;

export default ACEPTA_LOGIN;
