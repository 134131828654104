/* eslint-disable camelcase */
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import { formatDayMonthYearSlash, formatMoney } from '@fingo/lib/helpers';
import { Link as RouterLink } from 'react-router-dom';
import Launch from '@mui/icons-material/Launch';
import CONFIRMING_CREDIT_LINE from '../../../../../features/confirming/graphql/queries/confirming-credit-line';
import CONFIRMING_PAYROLL_SUMMARY_ANALYTICS from '../../../../../features/confirming/graphql/queries/confirming-payroll-summary-analytics';

const NextConfirmingExpirations = () => {
  const { data } = useQuery(CONFIRMING_CREDIT_LINE);
  const result = useMemo(
    () => groupBy(
      orderBy(data?.confirmingAnalytics, ['operation_ExpirationDate']),
      'payrollId',
    ) || {},
    [data],
  );
  const { data: summaryData } = useQuery(CONFIRMING_PAYROLL_SUMMARY_ANALYTICS);
  const { confirmingPayrollSummaryAnalytics: summaryResult } = useMemo(
    () => summaryData || {},
    [summaryData],
  );
  return (
    <Grid
      p={1}
      py={2}
      container
      height={180}
      direction="column"
      width="100%"
      borderRadius="8px"
      boxShadow={3}
    >
      <Grid item height="50%">
        <Typography textAlign="left" ml={2}>
          Próximos vencimientos
        </Typography>
        <Stack
          p={1}
          my={2}
          spacing={1}
          direction="row"
          sx={{ bgcolor: 'gray.A100' }}
        >
          {Object.values(result).slice(0, 3).map((payroll) => (
            <>
              <Stack width="30%">
                <Stack direction="row" alignItems="center">
                  <Link
                    width="100%"
                    component={RouterLink}
                    to={`/app/payroll-profile?payrollId=${payroll[0]?.payrollId}`}
                  >
                    <Stack justifyContent="space-between" direction="row">
                      Nómina {payroll[0]?.payrollId}
                      <Launch fontSize="8" />
                    </Stack>
                  </Link>
                </Stack>
                <Stack justifyContent="space-between" direction="row">
                  <Typography>
                    {formatDayMonthYearSlash(
                      payroll[0]?.operation_ExpirationDate,
                    )}
                  </Typography>
                  <Typography>
                    $
                    {formatMoney(
                      sum(payroll.map((x) => x.totalAmortization)),
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </>
          ))}
        </Stack>
      </Grid>
      <Grid item height="50%">
        <Typography textAlign="left" ml={2}>
          Gestiones necesarias
        </Typography>
        <Stack
          p={2}
          my={2}
          spacing={1}
          direction="row"
          justifyContent="space-between"
          sx={{ bgcolor: 'gray.A100' }}
        >
          <Link component={RouterLink} to="/app/purchases/confirming/summary">
            Tienes {summaryResult?.pendingCessionOperations || 0} proveedores
            que no han cedido sus facturas
            <Launch color="primary" fontSize="8px" />
          </Link>
          <Link component={RouterLink} to="/app/purchases/confirming/funding">
            Tienes {summaryResult?.pendingPayrolls || 0} nóminas no operadas
            <Launch color="primary" fontSize="8px" />
          </Link>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default NextConfirmingExpirations;
