import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { nationalTaxServiceCredentialsDialogVar } from '@fingo/lib/apollo/reactive-variables/alerts';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import getImagePath from '../helpers/images';

const useChileHomeFirstOperationTutorial = () => {
  const history = useHistory();
  const isMobile = useIsMobile('desktop');
  const company = useSelectedCompany();

  const handleSiiCredentialsClick = useCallback(() => {
    nationalTaxServiceCredentialsDialogVar({
      open: true,
      autocomplete: true,
    });
  }, []);

  const handleEvaluationClick = useCallback(() => {
    history.push('/app/sales/factoring/available');
  }, [history]);

  const handleOperationClick = useCallback(() => {
    history.push('/app/sales/factoring/offers');
  }, [history]);

  const handleTransferClick = useCallback(() => {
    history.push('/app/sales/factoring/cessions');
  }, [history]);

  const steps = useMemo(
    () => [
      {
        id: 'sii-credentials-step',
        title: 'Ingresa tus credenciales SII',
        description: 'Para acceder a los productos',
        imagePath: getImagePath('credential-banner', isMobile),
        onClick: handleSiiCredentialsClick,
      },
      {
        id: 'evaluation-step',
        title: 'Evalúa facturas',
        description: 'Para solicitar financiamiento',
        imagePath: getImagePath('evaluation-banner', isMobile),
        onClick: handleEvaluationClick,
      },
      {
        id: 'operation-step',
        title: 'Cede tus facturas aprobadas',
        description: 'Indicando la fecha de pago',
        imagePath: getImagePath('cession-banner', isMobile),
        onClick: handleOperationClick,
      },
      {
        id: 'transfer-step',
        title: 'Espera tu giro',
        description: 'Y revisa como avanza tu operación',
        imagePath: getImagePath('transfered-banner', isMobile),
        onClick: handleTransferClick,
      },
    ],
    [history, isMobile],
  );

  const {
    siiCredentials = [],
    hasOfferedInvoices = false,
    hasOperations = false,
    hasTransferedInvoices = false,
  } = company || {};
  const hasValidSiiCredentials = siiCredentials.length > 0;

  const activeStep = useMemo(() => {
    if (hasTransferedInvoices) return 4;
    if (hasOperations) return 3;
    if (hasOfferedInvoices) return 2;
    if (hasValidSiiCredentials) return 1;
    return 0;
  }, [
    hasValidSiiCredentials,
    hasOfferedInvoices,
    hasOperations,
    hasTransferedInvoices,
    company,
  ]);

  return { steps, activeStep };
};

export default useChileHomeFirstOperationTutorial;
