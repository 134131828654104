import useGetUser from '@fingo/lib/hooks/useGetUser';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { COUNTRY_TUTORIALS } from '../constants/home';

const useHomeTutorial = () => {
  const user = useGetUser();
  const country = useGetCountryFromUrl();

  const tutorials = user?.userseentutorialSet;
  const countryTutorials = COUNTRY_TUTORIALS[country.name] || [];

  const currentTutorial = countryTutorials.find(
    (tutorial) => !tutorials.some(
      (userTutorial) => userTutorial.tutorialCode === tutorial.code,
    ),
  );
  if (!currentTutorial?.code) { return { currentTutorial: countryTutorials.at(-1) }; }
  return { currentTutorial };
};

export default useHomeTutorial;
