/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const ALERT_COMPANY_BALANCE_NOT_EQUAL = gql`
  mutation AlertCompanyBalanceNotEqual($rut: String!) {
    alertCompanyBalanceNotEqual(rut: $rut) {
      success
    }
  }
`;
