import { gql } from '@apollo/client';
import {
  EvaluationRequestFields,
  InvoiceIdFields,
  PreofferFields,
} from '../invoices/fragments';

const REQUEST_PRE_OFFERS_EVALUATION = gql`
  mutation RequestPreOfferEvaluation(
    $requestingPlatformId: Int
    $invoiceIds: [ID]!
  ) {
    requestPreOfferEvaluation(
      requestingPlatformId: $requestingPlatformId
      invoiceIds: $invoiceIds
    ) {
      invoicesEvaluated {
        ...InvoiceIdFields
        preoffer {
          ...PreofferFields
          preofferevaluationrequest {
            ...EvaluationRequestFields
          }
        }
      }
    }
  }
  ${InvoiceIdFields}
  ${PreofferFields}
  ${EvaluationRequestFields}
`;

export default REQUEST_PRE_OFFERS_EVALUATION;
