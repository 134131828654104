import { gql } from '@apollo/client';
import { PurchaseOrderFragment } from '@fingo/lib/graphql/fragments';

const SEND_ORDER_TO_COLLECTION = gql`
  mutation SendOrderToCollection(
    $purchaseOrderIds: [String]!
    $inCollection: Boolean = false
    $getRates: Boolean = true
  ) {
    sendOrderToCollection(purchaseOrderIds: $purchaseOrderIds) {
      purchaseOrders {
        ...PurchaseOrderFragment
      }
    }
  }
  ${PurchaseOrderFragment}
`;

export default SEND_ORDER_TO_COLLECTION;
