import { gql } from '@apollo/client';
import {
  InvoiceFilesFields,
  sharedInvoiceInput,
  sharedInvoiceInputFields,
  TypeConnectionFields,
} from '@fingo/lib/graphql/fragments';
import MoneyFields from '@fingo/lib/graphql/fragments/money/money-fields';

const INVOICE_FILES = gql`
  query invoiceFiles(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          id
          folio
          amountWithIva {
            ...MoneyFields
          }
          ... InvoiceFilesFields
        }
      }

    }
  }
  ${TypeConnectionFields} 
  ${InvoiceFilesFields}
  ${MoneyFields}
`;

export default INVOICE_FILES;
