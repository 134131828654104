import React from 'react';
import AuthPaper from '../components/papers/AuthPaper';

const Login = () => (
  <AuthPaper
    width={{ xs: '90%', sm: 500 }}
    mx="auto"
    py={4}
    sx={{
      position: 'absolute',
      borderRadius: '20px',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  />
);

export default Login;
