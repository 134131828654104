import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';

const HomeStepIcon = (props) => {
  const { active, completed, icon } = props;

  if (completed) {
    return <CheckIcon color="primary" />;
  }

  return (
    <Typography
      variant="small_3"
      fontWeight={500}
      color={active ? 'common.white' : 'gray.A400'}
    >
      0{icon}
    </Typography>
  );
};

HomeStepIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  icon: PropTypes.number.isRequired,
};

export default HomeStepIcon;
