import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { logIn } from '@fingo/lib/apollo/helpers/auth';
import { AceptaLogo, BottomWaveLogin } from '@fingo/lib/assets';
import TermsAndConditionsDialog from '@fingo/lib/components/dialogs/TermsAndConditionsDialog';
import {
  formatRut,
  fullRutToOnlyHyphen,
  getFormFieldError,
  validRut,
} from '@fingo/lib/helpers';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ACEPTA_LOGIN from '../../graphql/mutations/acepta-login';

const useStyles = makeStyles(() => ({
  aceptaLogo: {
    width: 150,
    height: 24,
  },
  dialogContent: {
    marginBottom: '5rem',
  },
  buttonAndErrorContainer: {
    marginTop: '1rem',
  },
  button: {
    height: 35,
    width: 150,
  },
  textField: {
    marginBottom: '1rem',
  },
  label: {
    fontWeight: 500,
    marginBottom: '0.5rem',
  },
  termsMessageContainer: {
    padding: '0.5rem',
    zIndex: 100,
  },
  bottomWave: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
  },
}));

const AceptaLoginDialog = ({ open, onClose }) => {
  const history = useHistory();
  const [loginAceptaGql] = useMutation(ACEPTA_LOGIN);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [termsOpen, setTermsOpen] = useState(false);
  const [formData, setFormData] = useState({
    rut: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
  });

  const onFailedLogin = (error) => {
    setLoading(false);
    const { message } = error;
    if (message === 'failed login attempts limit exceded') {
      setFormError(
        'Demasiados intentos fallidos, Usuario bloqueado, vuelve a intentar más tarde.',
      );
    } else if (
      message === 'Error: Inicio de sesión con credenciales de ACEPTA inválido'
    ) {
      setFormError(message);
    } else {
      setFormError(
        'Acepta tardó en responder, intenta nuevamente o contáctate con el equipo de soporte',
      );
    }
  };

  const doAceptaLogin = async (rut, password) => {
    const params = { variables: { rut: fullRutToOnlyHyphen(rut), password } };
    const response = await loginAceptaGql(params);
    const userData = response.data.aceptaAuth;
    logIn(userData);
    // firmar tyc  en el backend si no ha firmado y entra con acepta
  };

  const onSuccessLogin = () => {
    setLoading(false);
    history.push('/app/sales');
  };

  const handleAceptaAuthSubmit = async () => {
    const { rut, password } = formData;
    setLoading(true);
    try {
      await doAceptaLogin(rut.value, password.value);
      onSuccessLogin();
    } catch (error) {
      onFailedLogin(error);
    }
    return null;
  };

  const handleTextField = (e) => {
    let { value } = e.target;
    const { name, type } = e.target;
    if (name === 'rut') value = formatRut(value);
    const fieldError = getFormFieldError(value, type, name);
    setFormData((oldState) => ({
      ...oldState,
      [name]: { ...oldState[name], error: fieldError.message, value },
    }));
  };
  const { rut, password } = formData;

  const loginButtonEnabled = validRut(rut.value) && password.value !== '';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <TermsAndConditionsDialog open={termsOpen} setOpen={setTermsOpen} />
      <DialogTitle id="alert-dialog-title">
        <img src={AceptaLogo} alt="logo" className={classes.aceptaLogo} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.label}>Rut</Typography>
        <TextField
          name="rut"
          variant="outlined"
          type="text"
          value={rut.value}
          onChange={handleTextField}
          error={Boolean(rut.error)}
          helperText={rut.error}
          className={classes.textField}
          fullWidth
        />
        <Typography className={classes.label}>Contraseña</Typography>
        <TextField
          name="password"
          variant="outlined"
          type="password"
          onChange={handleTextField}
          error={Boolean(password.error)}
          helperText={password.error}
          fullWidth
        />
        <div className={classes.buttonAndErrorContainer}>
          <Typography
            variant="caption"
            component="div"
            color="error"
            gutterBottom
          >
            {formError}
          </Typography>
          <Button
            className={classes.button}
            onClick={handleAceptaAuthSubmit}
            color="primary"
            variant="contained"
            disabled={loading || !loginButtonEnabled}
            endIcon={loading ? <CircularProgress size={20} /> : null}
            id="acepta-login-confirm"
          >
            Iniciar sesión
          </Button>
        </div>
        <div className={classes.termsMessageContainer}>
          <Typography variant="caption" align="center">
            Al iniciar sesión, declaras haber leído y aceptado nuestros
            <Link onClick={() => setTermsOpen(true)} to="">
              <Typography variant="caption" color="primary">
                {' términos y condiciones'}
              </Typography>
            </Link>
          </Typography>
        </div>
      </DialogContent>
      <img
        src={BottomWaveLogin}
        className={classes.bottomWave}
        alt="Bottom decorative wave"
      />
    </Dialog>
  );
};

AceptaLoginDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AceptaLoginDialog;
