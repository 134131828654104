import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ExecutiveAvatar from '../avatars/ExecutiveAvatar';
import ExecutiveContactInformation from '../ExecutiveContactInformation';
import ExecutiveContactButton from '../buttons/ExecutiveContactButton';

const ExecutiveDesktopPaper = () => {
  const company = useSelectedCompany();
  const { t } = useTranslation();
  const executive = company?.executiveAssigned;

  const content = useMemo(() => {
    if (executive) {
      return (
        <>
          <Typography variant="small_2">
            Hola, soy tu ejecutivo y estoy para ayudarte
          </Typography>
          <Typography variant="small_2" fontWeight={600}>
            ¡contáctame!
          </Typography>
        </>
      );
    }
    return (
      <Typography
        variant="small_2"
        fontWeight={600}
        maxWidth={{ desktop: 280 }}
      >
        Debes ingresar tus credenciales del {t('National Tax Service acronym')}{' '}
        para que podamos asignarte un ejecutivo
      </Typography>
    );
  }, [executive]);
  if (executive?.executive?.showContactInfoToClients) {
    return (
      <Paper
        component={Stack}
        px={2}
        py={1.5}
        alignItems="center"
        direction="row"
        height={1}
        spacing={2}
        sx={{ borderRadius: 2 }}
      >
        <ExecutiveAvatar executive={executive} />
        <Stack flexGrow={1} spacing={1}>
          <Stack direction="row" alignItems="center" width="90%">
            <Typography variant="medium_2" fontWeight={600} color="gray.A700">
              {executive ? executive.completeName : 'Aún no tienes ejecutivo/a'}
            </Typography>
            <ExecutiveContactInformation executive={executive} />
          </Stack>
          <Stack direction="row">
            <Box component={Stack}>{content}</Box>
            <ExecutiveContactButton executive={executive} />
          </Stack>
        </Stack>
      </Paper>
    );
  }
  return <></>;
};

export default ExecutiveDesktopPaper;
