import React from 'react';
import { isRouteDisabled } from '@fingo/lib/helpers/routes';
import Home from '../views/App/Home';
import TellUsYourRut from '../views/App/TellUsYourRut';
import Home2 from '../features/home/views/Home';

const homeRoutes = (user, showTyc) => [
  {
    id: 'nav-home',
    label: 'Home',
    path: '/app/home',
    component: user.isStaff ? <Home2 /> : <Home />,
    disabled: isRouteDisabled(user),
    show: user && !showTyc,
    showInNavbar: true,
    subRoutes: [],
  },
  {
    id: 'tell-us-your-info',
    label: 'Completa los datos de tu empresa',
    path: '/app/tell-us-your-rut',
    component: <TellUsYourRut />,
    disabled: false,
    show: true,
    showInNavbar: false,
  },
];

export default homeRoutes;
