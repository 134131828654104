import { gql } from '@apollo/client';
import ShoppingFields from '../fragments/shopping-fields';

const PREOFFER_SHOPPING_CART = gql`
  query preofferShoppingCart($invoicesId: [Int]!) {
    preofferShoppingCart(invoicesId: $invoicesId) {
      ... ShoppingFields
    }
  }
  ${ShoppingFields}
`;

export default PREOFFER_SHOPPING_CART;
