import { gql } from '@apollo/client';
import {
  CollectionActionsFields,
  CollectionManagerBasicFields,
  CollectionPriorityFields,
} from '../collection/fragments';
import { UserIdentification } from '../user/fragments';
import MoneyFields from './money/money-fields';


export const PendingCollectionManagerFragment = gql`
  fragment PendingCollectionManagerFragment on CollectionManagerType {
    ...CollectionManagerBasicFields
    collectedObjectAmount {
      ...MoneyFields
    }
    collectionPriority {
      ...CollectionPriorityFields
    }
    collectedObjectDateToPay
    collectedObjectDocumentIdentifier
    actions {
      ...CollectionActionsFields
      author {
        ...UserIdentification
      }
    }
  }
  ${CollectionManagerBasicFields}
  ${MoneyFields}
  ${CollectionPriorityFields}
  ${CollectionActionsFields}
  ${UserIdentification}
`;
