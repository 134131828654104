import { gql } from '@apollo/client';
import { PurchaseOrderFragment } from '../fragments';
import {
  CompanyDebtsSummary,
  ReceiverDebtsSummary,
} from '../customers/fragment';

const ORDERING_PURCHASE_ORDERS = gql`
  query purchaseOrders(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [String]
    $companyId: Int
    $orderBy: String
    $inSimulation: Boolean
    $inOffer: Boolean
    $inOperation: Boolean
    $inEvaluation: Boolean
    $inOperationActive: Boolean
    $finishedOperations: Boolean
    $inPendingInvoices: Boolean = false
    $availableForTransfer: Boolean = false
    $inCollection: Boolean = false
    $getRates: Boolean = true
    $globalFilter: String
    $purchaseOrderSecretKey: String
    $status: [String]
  ) {
    purchaseOrders(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id_In: $id_In
      companyId: $companyId
      orderBy: $orderBy
      inSimulation: $inSimulation
      inOffer: $inOffer
      inOperation: $inOperation
      inEvaluation: $inEvaluation
      inOperationActive: $inOperationActive
      finishedOperations: $finishedOperations
      inPendingInvoices: $inPendingInvoices
      availableForTransfer: $availableForTransfer
      inCollection: $inCollection
      globalFilter: $globalFilter
      purchaseOrderSecretKey: $purchaseOrderSecretKey
      status: $status
    ) {
      totalCount
      totalPages
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...PurchaseOrderFragment
          company {
            masterEntity {
              ...CompanyDebtsSummary
            }
          }
          purchaser {
            ...ReceiverDebtsSummary
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${PurchaseOrderFragment}
`;

export default ORDERING_PURCHASE_ORDERS;
