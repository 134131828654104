import React from 'react';
import { FingoPaper, Sidebar } from '@fingo/lib/components/layout';
import GroupIcon from '@mui/icons-material/Group';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import ReceiptLongOutlined from '@mui/icons-material/ReceiptLongOutlined';
import ShoppingBagOutlined from '@mui/icons-material/ShoppingBagOutlined';
import { hasCollectionAvailable } from '@fingo/lib/helpers/plans';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { KobrosPerson } from '@fingo/lib/assets';
import Box from '@mui/material/Box';
import KobrosText from '@fingo/lib/components/text/KobrosText';
import { isRouteDisabled } from '@fingo/lib/helpers/routes';
import { Sales, Factoring, Ordering, Clients } from '../views/App/sales';
import {
  AvailableOrdering,
  OfferOrdering,
} from '../views/App/sales/orderingRoutes';
import MySales from '../views/App/sales/salesRoutes/MySales';
import MyClients from '../views/App/sales/clientsRoutes';
import ActiveOperations from '../views/App/sales/orderingRoutes/ActiveOperations';
import HistoricOperations from '../views/App/sales/orderingRoutes/HistoricOperations';
import {
  Collection,
  Debtor,
  Debtors,
  CollectionLanding,
  CollectionContactUs,
  CollectionSummary,
} from '../features/collection/pages';
import AvailableFactoring from '../features/factoring/views/AvailableFactoring';
import EvaluationInvoice from '../features/factoring/views/EvaluationInvoice';
import AvailableOffers from '../features/factoring/views/AvailableOffers';
import CessionStatus from '../features/factoring/views/CessionStatus';

const summaryTabs = (user) => [
  {
    id: 'summary',
    label: 'Resumen de cobranza',
    path: 'summary',
    component: <Collection />,
    disabled: isRouteDisabled(user),
    show: true,
    showInSidebar: true,
  },
];

const newSummaryTabs = (user) => [
  {
    id: 'summary',
    label: 'Resumen de cobranza',
    path: 'summary',
    component: <CollectionSummary />,
    disabled: isRouteDisabled(user),
    show: true,
    showInSidebar: true,
  },
];

const collectionTabs = (user) => [
  {
    id: 'debtors',
    label: 'Clientes',
    path: 'debtors',
    component: <Debtors />,
    disabled: isRouteDisabled(user),
    show: true,
    showInSidebar: true,
  },
];

const collectionLandingTabs = (user) => [
  {
    id: 'kobros-landing-info',
    label: '¿Qué es koBros?',
    path: 'landing-info',
    component: <CollectionLanding />,
    disabled: isRouteDisabled(user),
    show: true,
    showInSidebar: false,
  },
  {
    id: 'collection-contact-us',
    label: 'Contáctanos',
    path: 'landing-contact',
    component: <CollectionContactUs />,
    disabled: isRouteDisabled(user),
    show: true,
    showInSidebar: true,
  },
];

const salesSubRoutes = (user, country) => [
  {
    id: 'sales-side-factoring',
    label: 'Financiamiento de facturas',
    path: 'factoring',
    icon: <DescriptionOutlined />,
    component: <Factoring />,
    disabled: isRouteDisabled(user),
    show: true,
    showInSidebar: true,
  },
  {
    id: 'sales-side-ordering',
    label: 'Financiamiento de OC',
    path: 'ordering',
    icon: <ShoppingBagOutlined />,
    component: <Ordering />,
    disabled: isRouteDisabled(user),
    show: country?.name !== 'Mexico',
    showInSidebar: true,
  },
  {
    id: 'sales-side-accountable',
    label: 'Cuentas por cobrar',
    path: 'accountable',
    icon: <ReceiptLongOutlined />,
    component: <Sales />,
    disabled: false,
    show: country?.name !== 'Mexico',
    showInSidebar: true,
  },
  {
    id: 'sales-side-clients',
    label: 'Clientes',
    path: 'clients',
    icon: <GroupIcon />,
    component: <Clients />,
    disabled: isRouteDisabled(user),
    show: true,
    showInSidebar: true,
  },
  {
    id: 'sales/collection',
    label: <KobrosText />,
    path: 'sales/collection',
    icon: <Box component="img" height="24px" src={KobrosPerson} />,
    disabled: isRouteDisabled(user),
    show: country?.name === 'Chile',
    component: user.selectedCompany?.hasSiiCredentials ? (
      <Redirect to="/app/sales/collection/debtors" />
    ) : (
      <Redirect to="/app/sales/collection/collection-landing" />
    ),
    showInSidebar: true,
  },
  {
    id: 'collection',
    path: 'collection',
    disabled: isRouteDisabled(user),
    show: true,
    showInSidebar: true,
    component: [
      {
        id: 'collection-summary',
        label: 'Resumen',
        path: 'home',
        icon: null,
        component: <FingoPaper tabs={newSummaryTabs(user)} />,
        disabled: isRouteDisabled(user) || !user.selectedCompany?.hasSiiCredentials,
        show: true,
        showInSidebar: true,
      },
      {
        id: 'collection-debtors',
        label: 'Lista de clientes',
        path: 'debtors',
        icon: null,
        component: <FingoPaper tabs={collectionTabs(user)} />,
        disabled: isRouteDisabled(user) || !user.selectedCompany?.hasSiiCredentials,
        show: true,
        showInSidebar: true,
      },
      {
        id: 'collection-landing',
        label: '¿Qué es koBros?',
        path: 'collection-landing',
        icon: null,
        component: <FingoPaper tabs={collectionLandingTabs(user)} />,
        disabled: false,
        show: true,
        showInSidebar: true,
      },
      {
        id: 'collection-report',
        label: 'Reportería',
        path: 'collection-report',
        icon: null,
        component: <FingoPaper tabs={summaryTabs(user)} />,
        disabled: isRouteDisabled(user) || !user.selectedCompany?.hasSiiCredentials,
        show: true,
        showInSidebar: true,
      },
    ],
  },
];

export const salesRoutes = (user, showTyc, country) => [
  {
    id: 'nav-sales',
    label: 'Mis ventas',
    path: '/app/sales',
    component: <Sidebar options={salesSubRoutes(user, country)} />,
    disabled: false,
    show: user && !showTyc,
    showInNavbar: true,

    subRoutes: salesSubRoutes(user, country),
  },
  {
    id: 'debtor-profile',
    label: 'Perfil de Cliente',
    path: '/app/debtor/:debtorId',
    component: <Debtor />,
    disabled: isRouteDisabled(user),
    show: hasCollectionAvailable(user),
    showInNavbar: false,
    subRoutes: [],
  },
];

export const factoringTabs = (countryName) => [
  {
    id: 'factoring-tab-available',
    label: {
      xs: 'Disponibles',
      md: 'Facturas disponibles',
    },
    path: 'available',
    component: <AvailableFactoring />,
    disabled: false,
    show: true,
    showInSidebar: true,
  },
  {
    id: 'factoring-tab-evaluation',
    label: {
      xs: 'Evaluación',
      md: 'En evaluación',
    },
    path: 'evaluation',
    component: <EvaluationInvoice />,
    disabled: false,
    show: true,
    showInSidebar: true,
  },
  {
    id: 'factoring-tab-offers',
    label: {
      xs: 'Aprobadas',
      md: 'Facturas aprobadas',
    },
    path: 'offers',
    component: <AvailableOffers />,
    disabled: false,
    show: true,
    showInSidebar: true,
  },
  {
    id: 'factoring-tab-cessions',
    label: {
      md: {
        Mexico: 'Resumen de operaciones',
        Chile: 'Estado de operaciones',
      }[countryName],
      xs: 'Operaciones',
    },
    path: 'cessions',
    component: <CessionStatus />,
    disabled: false,
    show: true,
    showInSidebar: true,
  },
];

export const orderingTabs = [
  {
    id: 'ordering-tab-available',
    label: 'Financiamiento de OC',
    path: 'available',
    component: <AvailableOrdering />,
    disabled: false,
    show: true,
    showInSidebar: true,
  },
  {
    id: 'ordering-tab-offers',
    label: 'OC con oferta',
    path: 'offer',
    component: <OfferOrdering />,
    disabled: false,
    show: true,
    showInSidebar: true,
  },
  {
    id: 'ordering-tab-active',
    label: {
      xs: 'Activas',
      md: 'Operaciones activas',
    },
    path: 'active',
    component: <ActiveOperations />,
    disabled: false,
    show: true,
    showInSidebar: true,
  },
  {
    id: 'ordering-tab-finished',
    label: {
      xs: 'Terminadas',
      md: 'Operaciones terminadas',
    },
    path: 'finished',
    component: <HistoricOperations />,
    disabled: false,
    show: true,
    showInSidebar: true,
  },
];

export const salesTabs = [
  {
    id: 'my-sales',
    label: 'Cuentas por cobrar',
    path: 'my-sales',
    component: <MySales />,
    disabled: false,
    show: true,
    showInSidebar: true,
  },
];

export const clientsTabs = [
  {
    id: 'contacts',
    label: 'Clientes',
    path: 'contacts',
    component: <MyClients />,
    disabled: false,
    show: true,
    showInSidebar: true,
  },
];
