import { gql } from '@apollo/client';

const CREATE_AUTOMATIC_CESSION_CONFIG = gql`
  mutation CreateAutomaticCessionConfig(
    $automaticCessionInfos: [AutomaticCessionInfo]!
    $bankAccountId: Int!
    $companyId: Int!
    $cessionOption: String!
    $ratificationOption: String!
    $notRatificationOption: String!
    $email: String
    $phoneNumber: String
  ) {
    createAutomaticCessionConfig(
      automaticCessionInfos: $automaticCessionInfos
      bankAccountId: $bankAccountId
      companyId: $companyId
      cessionOption: $cessionOption
      ratificationOption: $ratificationOption
      notRatificationOption: $notRatificationOption
      email: $email
      phoneNumber: $phoneNumber
    ) {
      success
      message
      automaticCessionConfig {
        id
      }
    }
  }
`;

export default CREATE_AUTOMATIC_CESSION_CONFIG;
