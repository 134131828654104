import { gql } from '@apollo/client';
import { PurchaseOrderFragment } from '../fragments';

const ASSIGN_INVOICES_TO_PURCHASE_ORDERS = gql`
  mutation AssignInvoicesToPurchaseOrders(
    $orderingAssignmentRequest: [OrderingAssginmentRequest]!
    $purchaseOrderIds: [ID]!
    $commission: Int!
    $isExternal: Boolean!
    $bankAccountId: ID!
    $getRates: Boolean = true
    $inCollection: Boolean = false
  ) {
    assignInvoicesToPurchaseOrders(
      orderingAssignmentRequest: $orderingAssignmentRequest
      purchaseOrderIds: $purchaseOrderIds
      commission: $commission
      isExternal: $isExternal
      bankAccountId: $bankAccountId
    ) {
      purchaseOrders {
        ...PurchaseOrderFragment
      }
    }
  }
  ${PurchaseOrderFragment}
`;

export default ASSIGN_INVOICES_TO_PURCHASE_ORDERS;
