import { gql } from '@apollo/client';
import { TypeConnectionFields } from '@fingo/lib/graphql/fragments';

const defaultFragment = `
  companyId: $companyId
  currency: $currency
  availableForOrdering: $availableForOrdering
  availableForTransfer: $availableForTransfer
  availableForReintegration: $availableForReintegration
  availableForReplacement: $availableForReplacement
  recommended: $recommended
  globalFilter: $globalFilter
  first: $first
`;

const COUNT_FACTORING = gql`
  query CountFactoring(
    $companyId: Int
    $currency: String
    $availableForOrdering: Boolean = false
    $availableForTransfer: Boolean = false
    $availableForReintegration: Boolean = false
    $availableForReplacement: Boolean = false
    $recommended: Boolean = false
    $globalFilter: String = ""
    $first: Int = 1
    $evaluatingIssuedGte: Date
  ) {
    preoffers: invoices(
      availableForFinancing: "AVAILABLE",
      preoffer_Preofferevaluationrequest_Isnull: true,
      ${defaultFragment}
    ) {
      ... TypeConnectionFields
      edges {
        node {
          id
        }
      }
    }
    offers: invoices(
      hasValidOffer: true
      ${defaultFragment}
    ) {
      ... TypeConnectionFields
      edges {
        node {
          id
        }
      }
    }
    cessions: invoices(
      hasBeenCeded: true
      ${defaultFragment}
    ) {
      ... TypeConnectionFields
      edges {
        node {
          id
        }
      }
    }
    evaluations: invoices(
      preoffer_Preofferevaluationrequest_Status_In: ["Evaluating", "Rejected", "PendingDocuments", "DocumentsReviewed"]
      dateIssued_Gte: $evaluatingIssuedGte
      ${defaultFragment}
    ) {
      ... TypeConnectionFields
      edges {
        node {
          id
        }
      }
    }
  }
  ${TypeConnectionFields}
`;

export default COUNT_FACTORING;
