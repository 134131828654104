import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PropTypes from 'prop-types';
import TitleComponent from './TitleComponent';
import { legalRepresentativesInput } from './constants/contract-chip-inputs';

const LegalRepresentativesComponent = ({ legalRepresentatives, availableRepresentatives,
  addLegalRepresentative,
  removeLegalRepresentative, updateLegalRepresentative,
}) => {
  const [selectedRep, setSelectedRep] = useState('');

  const createEmptyLegalRepresentative = () => ({
    id: legalRepresentatives.length || 0,
    label: 'SR',
    name: '',
    rut: '',
    nationality: 'chileno',
    profession: '',
    email: '',
    civilStatus: 'single',
    mainDisabled: false,
    selected: true,
  });

  const handleAddLegalRepresentative = () => {
    if (selectedRep === 'empty') {
      addLegalRepresentative(createEmptyLegalRepresentative());
    } else {
      const newRep = availableRepresentatives?.find((rep) => rep.id === selectedRep);
      if (newRep) {
        newRep.selected = true;
        addLegalRepresentative(newRep);
      }
    }
    setSelectedRep('');
  };

  const handleLegalRepresentativeInfo = (eventId, eventValue, legalRepresentativeId) => {
    updateLegalRepresentative(legalRepresentativeId, { [eventId]: eventValue });
  };

  return (
    <>
      <TitleComponent number="2" label="Representantes Legales:" />
      <Stack direction="row" spacing={2} alignItems="center" sx={{ px: 2 }}>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel>Seleccionar</InputLabel>
          <Select
            value={selectedRep}
            onChange={(e) => setSelectedRep(e.target.value)}
            label="Seleccionar"
          >
            <MenuItem value="empty">Agregar vacío</MenuItem>
            {availableRepresentatives?.filter((rep) => !rep.selected).map((rep) => (
              <MenuItem key={rep.id} value={rep.id}>{rep.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <AddCircleIcon color="primary" onClick={handleAddLegalRepresentative} sx={{ cursor: 'pointer' }} />
      </Stack>

      <Stack alignItems="center" spacing={2}>
        {legalRepresentatives?.filter((lr) => lr.selected).map((lr, index) => (
          <Stack direction="column" key={lr.id}>
            <TitleComponent number={index + 1}>
              <Divider width="75%" />
              <Tooltip title={`Eliminar Representante Legal ${lr.name}`}>
                <RemoveCircleIcon
                  color="primary"
                  onClick={() => removeLegalRepresentative(lr.id)}
                  sx={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </TitleComponent>
            <Stack alignItems="center" spacing={2} sx={{ marginTop: 2 }}>
              {legalRepresentativesInput.map((rowsInput) => (
                <Stack direction="row" spacing={2} sx={{ width: '100%' }} key={`legal-representative-row-${rowsInput[0].index}`}>
                  {rowsInput.map((input) => (
                    <input.component
                      key={`legal-representative-input-${input.id}-${lr.id}`}
                      input={input}
                      obj={lr}
                      handleInfoFunc={handleLegalRepresentativeInfo}
                    />
                  ))}
                </Stack>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

LegalRepresentativesComponent.propTypes = {
  legalRepresentatives: PropTypes.arrayOf(PropTypes.shape).isRequired,
  availableRepresentatives: PropTypes.arrayOf(PropTypes.shape).isRequired,
  addLegalRepresentative: PropTypes.func.isRequired,
  removeLegalRepresentative: PropTypes.func.isRequired,
  updateLegalRepresentative: PropTypes.func.isRequired,
};

export default LegalRepresentativesComponent;
