import { useCallback, useMemo } from 'react';
import { nationalTaxServiceCredentialsDialogVar } from '@fingo/lib/apollo/reactive-variables/alerts';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import { hasAllRequiredDocuments } from '@fingo/lib/helpers/preoffer-conditions';
import {
  openCreditBuroFormDialogVar,
  openUploadCreditLineDocumentsDialogVar,
} from '../graphql/reactive-variables';
import getImagePath from '../helpers/images';

const useMexicoHomeFirstCreditLineTutorial = () => {
  const isMobile = useIsMobile('desktop');
  const company = useSelectedCompany();

  const handleSiiCredentialsClick = useCallback(() => {
    nationalTaxServiceCredentialsDialogVar({
      open: true,
      autocomplete: true,
    });
  }, []);

  const handleBuroClick = useCallback(() => {
    openCreditBuroFormDialogVar(true);
  }, []);

  const handleDocumentClick = useCallback(() => {
    openUploadCreditLineDocumentsDialogVar(true);
  }, []);

  const steps = useMemo(
    () => [
      {
        id: 'ciec-step',
        title: 'Ingresa la CIEC',
        imagePath: getImagePath('mexico-credentials-banner', isMobile),
        onClick: handleSiiCredentialsClick,
      },
      {
        id: 'buro-step',
        title: 'Autorizar solicitud de Buró',
        imagePath: getImagePath('mexico-buro-banner', isMobile),
        onClick: handleBuroClick,
      },
      {
        id: 'document-step',
        title: 'Subir documentos',
        imagePath: getImagePath('mexico-upload-documents-banner', isMobile),
        onClick: handleDocumentClick,
      },
      {
        id: 'credit-line-step',
        title: 'Espera la evaluación de tu linea',
        imagePath: getImagePath('mexico-credit-line-banner', isMobile),
        onClick: () => null,
      },
    ],
    [isMobile],
  );

  const { siiCredentials = [], masterEntity = null } = company || {};
  const hasValidSiiCredentials = siiCredentials.length > 0;
  const hasBuroAuthorization = masterEntity?.buroauthorizationformdocumentSet?.length > 0;
  const hasAllDocuments = hasAllRequiredDocuments(company);
  const hasCreditLine = masterEntity?.creditlines?.edges?.length > 0;

  const activeStep = useMemo(() => {
    if (hasCreditLine) return 4;
    if (hasAllDocuments) return 3;
    if (hasBuroAuthorization) return 2;
    if (hasValidSiiCredentials) return 1;
    return 0;
  }, [
    hasValidSiiCredentials,
    hasBuroAuthorization,
    hasAllDocuments,
    hasCreditLine,
    company,
  ]);

  return { steps, activeStep };
};

export default useMexicoHomeFirstCreditLineTutorial;
