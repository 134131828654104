import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FingoSnackBar } from '@fingo/lib/components/snackBars';
import {
  formatMoney,
  openUrlAndDownload,
  formatDayMonthYearSlash,
} from '@fingo/lib/helpers';
import {
  useBooleanState,
  useGetInvoice,
  useGetUser,
  useIsMobile,
} from '@fingo/lib/hooks';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Cached from '@mui/icons-material/Cached';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import OperativeStepperDialog from '@fingo/lib/components/dialogs/OperativeStepperDialog';
import CompanyContactList from '@fingo/lib/components/lists/CompanyContactList';
import InvoiceStatusMailPreview from '@fingo/lib/components/emailPreviews/InvoiceStatusPreview';
import dayjs from '@fingo/lib/config/dayjs';
import DownloadInvoiceDocumentButton from '@fingo/lib/components/buttons/DownloadInvoiceDocumentButton';
import { MoneyType } from '@fingo/lib/propTypes';
import { useTranslation } from 'react-i18next';
import traceToLabel from '@fingo/lib/components/traces/traceText';
import { ChatDialog } from '@fingo/lib/components/chat';
import TraceStatus from '../../../components/supplierAutomate/traceStatus';
import ProfileCard from '../../../components/supplierAutomate/ProfileCards';
import ProfileTable from '../../../components/supplierAutomate/ProfileTable';
import CessionTable from './CessionTable';
import FETCH_TRACE from '../../../graphql/mutations/fetch-trace';
import SEND_INVOICE_STATUS_MAIL from '../../../graphql/mutations/send-invoice-status-mail';
import USER_INVOICE_PROFILE from '../../../graphql/queries/user-invoice-profile';

const stepToTitle = {
  0: 'Selecciona el tipo de correo',
  1: 'A quien quieres enviar',
  2: 'Confirmar',
};
const options = [{ value: 'unused', label: 'Notificar estado de la factura' }];

const getMostRecentEvent = (objects) => {
  // eslint-disable-next-line max-len
  const maxDate = objects.reduce((prev, current) => (dayjs(prev.eventTime).isAfter(dayjs(current.eventTime)) ? prev : current)).eventTime;
  return objects.filter((obj) => obj.eventTime === maxDate)[0];
};

const getLabelText = (invoice) => {
  if (invoice.traces.length === 0) {
    if (invoice.hasExecutiveMerit) {
      return 'Con mérito';
    }
    return `${invoice.daysToExecutiveMerit} días para el mérito`;
  }
  const event = getMostRecentEvent(invoice.traces);
  const label = traceToLabel(invoice, event.eventCode, event, true);
  if (typeof label.label === 'function') {
    return label.label();
  }
  return label.label;
};

const ReferenceHeader = ({ amount, fromInvoice, toInvoice }) => {
  const invoice = fromInvoice || toInvoice;

  return (
    <Typography variant="body2">
      {invoice.dteType.name}{' '}
      <Link
        component={MuiLink}
        underline="hover"
        to={{
          pathname: '/app/invoice',
          search: `?invoiceId=${invoice.id}`,
        }}
        color="primary"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          '&:hover': {
            color: (theme) => `${theme.palette.primary.main} !important`,
          },
        }}
      >
        #
        {invoice.folio.length > 10
          ? `${invoice.folio.substring(0, 10)}...`
          : invoice.folio}
      </Link>
      <Typography variant="body2">${formatMoney(amount)}</Typography>
    </Typography>
  );
};

ReferenceHeader.propTypes = {
  amount: MoneyType.isRequired,
  fromInvoice: PropTypes.shape({
    id: PropTypes.number,
    dteType: PropTypes.shape({
      name: PropTypes.string,
    }),
    folio: PropTypes.number,
  }),
  toInvoice: PropTypes.shape({
    id: PropTypes.number,
    dteType: PropTypes.shape({
      name: PropTypes.string,
    }),
    folio: PropTypes.number,
  }),
};

ReferenceHeader.defaultProps = {
  fromInvoice: null,
  toInvoice: null,
};

const InvoiceProfile = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const invoiceId = searchParams.get('invoiceId');
  const invoiceData = useGetInvoice(invoiceId, USER_INVOICE_PROFILE);
  const currentUser = useGetUser();
  const blurred = useMemo(
    () => currentUser.requireChangePassword && currentUser.demo,
    [currentUser],
  );
  const [snackBarOpen, toggleSnackBar] = useBooleanState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [selectedContactsIds, setSelectedContactsIds] = useState([]);
  const [open, toggleOpen] = useBooleanState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const direction = isMobile ? 'column' : 'row';
  const [chatOpen, toggleChat] = useBooleanState(Boolean(searchParams.get('chatOpen')));
  const [sendMail, { loading: loadingMail }] = useMutation(
    SEND_INVOICE_STATUS_MAIL,
    {
      variables: {
        invoiceId,
        contactsIds: selectedContactsIds,
        emailType: invoiceData && getLabelText(invoiceData),
        userId: currentUser.id,
      },
      onCompleted: () => {
        setSelectedContactsIds([]);
        setSelectedOption('');
        toggleOpen();
        setCurrentStep(0);
        setSnackBarMessage('Correo enviado con éxito');
        toggleSnackBar();
      },
    },
  );
  const [fetchTrace, { loading: loadingTraces }] = useMutation(FETCH_TRACE, {
    variables: {
      invoiceId: invoiceData?.id,
    },
    onError: () => {},
  });
  const summaryHeaders = [
    [
      {
        label: 'Monto:',
        getValue: (invoice) => `$${formatMoney(invoice.amountWithIva)}`,
      },
      {
        label: 'Emisor:',
        getValue: (invoice) => `${invoice.company.masterEntity.name}\n${invoice.company.masterEntity.displayNationalIdentifier}`,
      },
      {
        label: 'Deudor:',
        getValue: (invoice) => `${invoice.receiver.name}\n${invoice.receiver.displayNationalIdentifier}`,
      },
    ],
    [
      {
        label: 'Fecha emisión:',
        getValue: (invoice) => `${formatDayMonthYearSlash(invoice.dateIssued)}`,
        lightHeader: true,
      },
      {
        label: `Fecha recepción ${t('National Tax Service acronym')}:`,
        getValue: (invoice) => {
          if (invoice.receptionDate) {
            return `${formatDayMonthYearSlash(invoice.receptionDate)}`;
          }
          return `${formatDayMonthYearSlash(invoice.dateIssued)}`;
        },
      },
      { label: 'Pago:', getValue: (invoice) => invoice.paymentMethod },
      {
        label: 'Comentarios último estado:',
        getValue: (invoice) => (invoice.uniqueDocumentFinanceState.length
          ? invoice.uniqueDocumentFinanceState[0]?.comments
          : 'Sin Comentarios'),
      },
    ],
  ];
  const referenceGetValue = useCallback(
    (reference) => <ReferenceHeader {...reference} />,
    [],
  );
  const referencesHeaders = [
    [
      {
        label: '',
        getValue: referenceGetValue,
      },
    ],
  ];
  const needsCreditNote = (invoice) => {
    if (!invoice) {
      return null;
    }
    const rejected = invoice.siiStatus.includes('REJECTED');
    const totalCredit = invoice.creditNotes
      .map((cn) => cn.amountWithIva)
      .reduce((a, b) => a + b, 0);
    return rejected && totalCredit < invoice.amountWithIva;
  };
  if (!invoiceData) return null;
  return (
    <>
      <OperativeStepperDialog
        open={open}
        onClose={toggleOpen}
        firstStepOptions={options}
        setOptions={setSelectedOption}
        selectedOption={selectedOption}
        selectedContactsIds={selectedContactsIds}
        setSelectedContactsIds={setSelectedContactsIds}
        managersAndAttachs={[]}
        setManagersAndAttachs={() => {}}
        masterEntityTarget={invoiceData.receiver}
        SecondStepComponent={CompanyContactList}
        stepToTitle={stepToTitle}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        onSubmit={sendMail}
        loadingSubmit={loadingMail}
        lastStepComponent={(
          <InvoiceStatusMailPreview
            invoiceId={Number(invoiceData.id)}
            userId={Number(currentUser.id)}
            emailType={
              invoiceData?.traces ? getLabelText(invoiceData) : 'Proceso'
            }
          />
        )}
      />
      <FingoSnackBar
        open={snackBarOpen}
        setOpen={toggleSnackBar}
        message={snackBarMessage}
        severity="error"
      />
      {chatOpen && <ChatDialog open={chatOpen} onClose={toggleChat} invoice={invoiceData} />}
      <Typography component="span" variant="h3" color="primary.main">
        {`${invoiceData.dteType.name} ${invoiceData.folio}`}
      </Typography>
      <Stack direction={direction} spacing={2} sx={{ paddingTop: '15px' }}>
        <Stack direction="column" spacing={2} flex={0.65}>
          <ProfileCard
            titleActions={[
              {
                id: 'invoice-export-xml',
                title: 'Descargar XML',
                icon: (
                  <DownloadInvoiceDocumentButton
                    invoice={invoiceData}
                    showTitle
                    sx={{ size: 'small' }}
                    documentType="XML"
                  />
                ),
                hide: !currentUser.selectedCompany.navBarTitles.has(
                  'my_purchases',
                ),
              },
              {
                id: 'invoice-export-pdf',
                title: 'Descargar PDF',
                icon: (
                  <DownloadInvoiceDocumentButton
                    invoice={invoiceData}
                    showTitle
                    sx={{ size: 'small' }}
                    documentType="PDF"
                  />
                ),
              },
              {
                id: 'send-mails',
                title: 'Enviar mail',
                icon: currentUser.selectedCompany.navBarTitles.has(
                  'my_purchases',
                ) && <EmailIcon fontSize="medium" color="primary" />,
                onClick: () => {
                  toggleOpen();
                },
                hide: !currentUser.selectedCompany.navBarTitles.has(
                  'my_purchases',
                ),
              },
            ]}
          >
            <ProfileTable headersList={summaryHeaders} data={[invoiceData]} />
          </ProfileCard>
          <Stack direction={direction} spacing={2}>
            <Stack direction="column" spacing={2} flex={0.7}>
              <ProfileCard primaryTitle="Cesiones" blurred={blurred}>
                <CessionTable cessions={invoiceData.cessions} />
              </ProfileCard>
            </Stack>
            <Stack direction="column" spacing={2} flex={0.3}>
              <ProfileCard
                blurred={blurred}
                primaryTitle="Documentos Relacionados"
                customActions={(
                  <>
                    {invoiceData && needsCreditNote(invoiceData) && (
                      <Grid paddingX="40px">
                        <Typography variant="caption" color="text.primary">
                          <span role="img" aria-label="eyes">
                            👀
                          </span>{' '}
                          Recuerda <b>emitir la nota de crédito</b> asociada a
                          esta factura y evitar el cobro del iva
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}
              >
                <ProfileTable
                  data={[
                    ...invoiceData.intermediateReferencesFrom,
                    ...invoiceData.intermediateReferencesTo,
                  ]}
                  headersList={referencesHeaders}
                />
              </ProfileCard>
            </Stack>
          </Stack>
        </Stack>
        <Stack flex={0.35}>
          <ProfileCard
            primaryTitle="Traza"
            blurred={blurred}
            titleActions={[
              {
                id: 'invoice-refresh-trace',
                icon: <Cached fontSize="large" />,
                onClick: fetchTrace,
                loading: loadingTraces,
              },
            ]}
            top
            customActions={
              invoiceData.receiver.company?.instructions && (
                <CardActions>
                  <Tooltip
                    title={`Instructivo de ${invoiceData.receiver.name}`}
                  >
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => openUrlAndDownload(
                        invoiceData.receiver.company.instructions,
                      )}
                      id="invoice-show-instructions"
                    >
                      ¿Problemas con la facturación?
                    </Button>
                  </Tooltip>
                </CardActions>
              )
            }
          >
            <TraceStatus invoice={invoiceData} />
          </ProfileCard>
        </Stack>
      </Stack>
    </>
  );
};

export default InvoiceProfile;
