import { gql } from '@apollo/client';

const CONTACTS = gql`
  query contacts(
    $masterEntity_In: [ID]
    $orderBy: String
    $first: Int
    $offset: Int
    $contactType: String
    $showHidden: Boolean
  ) {
    getContacts(
      masterEntity_In: $masterEntity_In
      orderBy: $orderBy
      first: $first
      offset: $offset
      contactType_Code: $contactType
      showHidden: $showHidden
    ) {
      edges {
        node {
          id
          name
          position
          email
          phoneNumber
          source
          contactType {
            code
          }
          masterEntity {
            id
          }
          userInstance {
            id
            activeInvitations {
              companyFrom {
                id
                rut
              }
            }
          }
        }
      }
    }
  }
`;

export default CONTACTS;
