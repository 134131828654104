import { gql } from '@apollo/client';

const SOPHIA_GRAPHS = gql`
  query SophiaGraphs(
    $accumulated: Boolean!
    $group: String!
    $companyId: Int!
  ) {
    sophiaPurchasesGraph(
      accumulated: $accumulated
      group: $group
      companyId: $companyId
    ) {
      money
      year
      month
      dateIssued
    }
    sophiaSalesGraph(
      accumulated: $accumulated
      group: $group
      companyId: $companyId
    ) {
      money
      year
      month
      dateIssued
    }
  }
`;

export default SOPHIA_GRAPHS;
