import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { StepTypography } from '@fingo/lib/components/typography';
import FileInput from '@fingo/lib/components/inputs/FileInput';
import UPLOAD_INVOICE_DOCUMENTS from '@fingo/lib/graphql/mutations/upload-invoice-documents';
import { useSnackBars } from '@fingo/lib/hooks';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ShoppingCartStepperNavigation from '../../ShoppingCartStepperNaviagtion';
import ShoppingCartSummaryActionButton from '../../ShoppingCartSummary/ShoppingCartSummaryActionButton';

const UploadDocumentsStep = ({ prevStep, invoices }) => {
  const [uploadInvoiceDocuments] = useMutation(UPLOAD_INVOICE_DOCUMENTS);
  const [loadingStates, setLoadingStates] = useState({});
  const { addAlert } = useSnackBars();

  const handleFileChange = useCallback(
    (event, invoice) => {
      const file = event.currentTarget.files[0];
      setLoadingStates((prevState) => ({
        ...prevState,
        [invoice.id]: true,
      }));
      uploadInvoiceDocuments({
        variables: {
          invoiceId: invoice.id,
          inputUploadFiles: [
            {
              documentType: 'XML',
              file,
            },
          ],
        },
        onCompleted: () => {
          setLoadingStates((prevState) => ({
            ...prevState,
            [invoice.id]: false,
          }));
          addAlert({
            id: 'upload-invoice-document-success',
            message: 'Archivo cargado exitósamente!',
            severity: 'success',
            color: 'success',
          });
        },
        onError: () => {
          addAlert({
            id: 'upload-invoice-xml-error',
            message:
              'Hubo un error al subir el XML. Revisa que el archivo corresponda a la factura.',
            severity: 'error',
            color: 'error',
          });
          setLoadingStates((prevState) => ({
            ...prevState,
            [invoice.id]: false,
          }));
        },
      });
    },
    [uploadInvoiceDocuments],
  );

  const allXMLUploaded = useMemo(
    () => invoices.every((invoice) => invoice.hasXMLDocument),
    [invoices],
  );

  return (
    <>
      <StepTypography stepNumber="3" variant="h6" fontWeight="bold" mb={2}>
        Sube el xml para cada factura que desees ceder:
      </StepTypography>
      <Typography variant="body1">Folio</Typography>
      <Stack spacing={2} maxHeight={240} overflow="auto">
        {invoices?.map((invoice) => (
          <Stack
            key={invoice.folio}
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">{invoice.folio}</Typography>
            <FileInput
              variant="filled"
              value={invoice.hasXMLDocument}
              placeholder={`Sube el XML de la factura ${invoice.folio}`}
              accept=".xml"
              loading={loadingStates[invoice.id]}
              onChange={(event) => handleFileChange(event, invoice)}
              sx={{ maxWidth: 340 }}
            />
          </Stack>
        ))}
      </Stack>
      <ShoppingCartStepperNavigation onBack={prevStep}>
        <ShoppingCartSummaryActionButton
          id="confirm-upload-documents-step-button"
          label="Siguiente"
          disabled={!allXMLUploaded}
        />
      </ShoppingCartStepperNavigation>
    </>
  );
};

UploadDocumentsStep.propTypes = {
  prevStep: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      folio: PropTypes.string,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          type: PropTypes.shape({
            id: PropTypes.string,
            code: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
};

UploadDocumentsStep.defaultProps = {
  invoices: [],
};

export default UploadDocumentsStep;
