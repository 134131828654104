import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { formatText } from '@fingo/lib/helpers/text-parser';
import { useSelectedCompany, useGetUser } from '@fingo/lib/hooks';
import { nationalTaxServiceCredentialsDialogVar } from '@fingo/lib/apollo/reactive-variables/alerts';
import LaunchIcon from '@mui/icons-material/Launch';
import { hasCollectionAvailable } from '@fingo/lib/helpers/plans';
import { KobrosAskForDemoButton, KobrosDownloadGuideButton } from '../../../components/buttons';
import CollectionIconGrid from '../components/CollectionIconGrid';
import { DEFAULT_COLLECTION_PLAN_ID } from '../constants/collection';
import ACTIVATE_COLLECTION_PLAN from '../graphql/mutations/activate-collection-plan';

const CollectionLanding = () => {
  const selectedCompany = useSelectedCompany();
  const user = useGetUser();
  useEffect(() => {
    if (!selectedCompany?.hasSiiCredentials) {
      nationalTaxServiceCredentialsDialogVar({
        open: true,
        autocomplete: true,
      });
    }
  }, [selectedCompany?.hasSiiCredentials]);
  const [acceptTerms] = useMutation(ACTIVATE_COLLECTION_PLAN, {
    variables: {
      planId: DEFAULT_COLLECTION_PLAN_ID,
    },
  });
  useEffect(() => {
    if (selectedCompany && !hasCollectionAvailable(user)) {
      acceptTerms();
    }
  }, [user, selectedCompany]);
  return (
    <Box
      paddingX={4}
      paddingY={3}
    >
      <Grid container>
        <Grid item xs={12} md={5}>
          <Stack spacing={2}>
            <Typography>
              <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>koBros es tu mejor {' '}
                <Typography sx={{ fontSize: 40, fontWeight: 'bold' }} component="span" color="secondary.main">
                  asesor de cobranza
                </Typography>
              </Typography>
            </Typography>
            <Typography lineHeight={1.5} variant="h2">{formatText('**koBros** es una herramienta que pone a tu disposición un **analista de riesgo con inteligencia artificial** que te ayuda a tomar **mejores decisiones con tus clientes**, cobrar de forma **eficiente** y contar con los **flujos** cuando los necesitas.')}</Typography>
            <Typography lineHeight={1.5} variant="h2">¡Descubre koBros hoy!</Typography>
            <Stack
              sx={{
                paddingTop: 1,
              }}
              direction={{
                xs: 'column',
                md: 'row',
              }}
              spacing={2}
            >
              <KobrosAskForDemoButton text="Solicitar más info" />
              <KobrosDownloadGuideButton text="Manual de uso" />
            </Stack>
            {!selectedCompany?.hasSiiCredentials && (
              <Stack direction="row" spacing={1}>
                <Typography>
                  <Typography sx={{ fontSize: 25, fontWeight: 'bold' }}>Ingresa tus credenciales para poder usar {' '}
                    <Typography sx={{ fontSize: 25, fontWeight: 'bold' }} component="span" color="secondary.main">
                      koBros
                    </Typography>
                  </Typography>
                </Typography>
                <LaunchIcon />
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          paddingX={{
            xs: 0,
            md: 4,
          }}
          marginY={{
            xs: 3,
            md: 0,
          }}
        >
          <CollectionIconGrid />
        </Grid>
      </Grid>
    </Box>
  );
};
export default CollectionLanding;
