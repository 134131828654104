import { gql } from '@apollo/client';

const UPLOAD_CERTIFICATE = gql`
  mutation UploadDigitalCertificate(
    $certificatePassword: String!
    $companyId: Int!
    $digitalCertificate: Upload!
    $credentialProviderName: String!
  ) {
    uploadDigitalCertificate(
      certificatePassword: $certificatePassword
      companyId: $companyId
      digitalCertificate: $digitalCertificate
      credentialProviderName: $credentialProviderName
    ) {
      digitalCertificate {
        id
      }
    }
  }
`;

export default UPLOAD_CERTIFICATE;
