import { gql } from '@apollo/client';

const BANK_ACCOUNT_ACTIVE_CHANGE = gql`
  mutation bankAccountActiveChange($bankId: Int!) {
    bankAccountActiveChange(bankId: $bankId) {
      bankAccount {
        id
        accountEmail
        accountName
        accountNumber
        accountRut
        accountType
        bankName
        active
        currency {
          id
          isoCode
        }
      }
    }
  }
`;

export default BANK_ACCOUNT_ACTIVE_CHANGE;
