import { useReactiveVar } from '@apollo/client';
import LazyImage from '@fingo/lib/components/images/LazyImage';
import useIsLogged from '@fingo/lib/hooks/useIsLogged';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { SIGN_IN } from '../../constants/auth';
import { selectedAuthTypeVar } from '../../graphql/reactive-variables';
import GoogleSignButton from '../buttons/GoogleSignButton';
import LoginForm from '../forms/LoginForm';
import RegisterForm from '../forms/RegisterForm';
import AuthTabs from '../tabs/AuthTabs';

const AuthPaper = ({
  logo = 'images/logos/fingo-logo.webp',
  landingTag = null,
  redirectTo = '/app/home',
  ...props
}) => {
  const isLogged = useIsLogged();
  const selectedAuthType = useReactiveVar(selectedAuthTypeVar);

  if (isLogged) return <Redirect to={redirectTo} />;
  return (
    <Paper
      component={Stack}
      elevation={4}
      alignItems="center"
      justifyContent="center"
      spacing={2}
      py={{ mobile: 3, desktop: 0 }}
      px={{ desktop: 2, lg: 0 }}
      sx={{ borderRadius: 3 }}
      {...props}
    >
      <LazyImage
        imagePath={logo || 'images/logos/fingo-logo.webp'}
        alt="Fingo logo"
        width={170}
        format="avif"
      />
      <AuthTabs />
      <Stack
        alignItems="center"
        spacing={1.5}
        sx={{ width: { mobile: '70%', tablet: 280 } }}
        mx="auto"
      >
        {selectedAuthType === SIGN_IN ? (
          <LoginForm redirectTo={redirectTo} landingTag={landingTag} />
        ) : (
          <RegisterForm landingTag={landingTag} />
        )}
        <Divider sx={{ width: '100%', mt: '0px !important' }} />
        <GoogleSignButton />
      </Stack>
    </Paper>
  );
};

AuthPaper.propTypes = {
  logo: PropTypes.string,
  landingTag: PropTypes.string,
  redirectTo: PropTypes.string,
};

AuthPaper.defaultProps = {
  logo: null,
  landingTag: null,
  redirectTo: '/app/home',
};

export default AuthPaper;
