import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InputField } from '@fingo/lib/components/inputs';
import { createQueryString } from '@fingo/lib/helpers/text-parser';
import { useSnackBars } from '@fingo/lib/hooks';
import { INITIAL_VALUES, VALIDATION_SCHEMA } from './collection-demo-dialog';
import CalendarBook from '../CalendarBook';
import { demoUrl } from '../../constants/collection';
import REQUEST_COLLECTION_PLAN_DEMO from '../../graphql/mutations/request-collection-plan-demo';

function CollectionDemoForm() {
  const [requestCollectionPlanDemo] = useMutation(REQUEST_COLLECTION_PLAN_DEMO);
  const [openCalendarBook, setOpenCalendarBook] = useState(false);
  const { addAlert } = useSnackBars();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const { firstName, lastName, email, phoneNumber } = values;
    await requestCollectionPlanDemo({
      variables: {
        name: `${firstName} ${lastName}`,
        email: email.toLowerCase(),
        phoneNumber,
      },
    });
    setFormData(values);
    setOpenCalendarBook(true);
    setSubmitting(false);
    addAlert({
      id: 'request-more-info',
      message: 'Te enviaremos información al correo que ingresaste',
      severity: 'success',
      color: 'success',
    });
  };

  const generatePath = () => {
    const queryParams = {
      name: formData.firstName ? `${formData.firstName} ${formData.lastName}`.trim() : '',
      email: formData.email,
    };
    return `${demoUrl}?${createQueryString(queryParams)}`;
  };

  return (
    openCalendarBook ? (
      <Stack direction="column">
        <Typography variant="h6" align="center" paddingBottom={2}>
          Elige en el calendario la fecha qué más te acomode para que te enseñemos a usar koBros:
        </Typography>
        <CalendarBook path={generatePath()} />
      </Stack>
    ) : (
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack
              flexDirection="column"
              alignItems="center"
              maxWidth={400}
              m="auto"
              spacing={1}
            >
              <InputField
                id="demo-kobros-name"
                name="firstName"
                label="Nombre"
                color="secondary"
                sx={{ width: { xs: 150, smartphone: 250, md: 400 } }}
              />
              <InputField
                id="demo-kobros-last-name"
                name="lastName"
                label="Apellido"
                color="secondary"
                sx={{ width: { xs: 150, smartphone: 250, md: 400 } }}
              />
              <InputField
                id="demo-kobros-phone-number"
                name="phoneNumber"
                label="Número de teléfono"
                color="secondary"
                startAdornment="+56"
                sx={{ width: { xs: 150, smartphone: 250, md: 400 } }}
              />
              <InputField
                id="demo-kobros-email"
                name="email"
                label="Email"
                color="secondary"
                sx={{ width: { xs: 150, smartphone: 250, md: 400 } }}
              />
              <Button
                id="request-demo-kobros"
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
                sx={{
                  fontSize: { xs: 12, md: 14 },
                  width: { xs: 140, smartphone: 250 },
                  height: { xs: 30, md: 41 },
                  borderRadius: 20,
                  mt: 1,
                }}
              >
                {isSubmitting ? (
                  <CircularProgress size="small" color="secondary" />
                ) : (
                  'Solicitar demo'
                )}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    )
  );
}

export default CollectionDemoForm;
