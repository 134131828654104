import { gql } from '@apollo/client';
import { UserFields } from '../fragments';

const LOGIN = gql`
  mutation TokenAuth(
    $email: String!
    $password: String!
    $utmCampaign: String
  ) {
    tokenAuth(email: $email, password: $password, utmCampaign: $utmCampaign) {
      payload
      refreshExpiresIn
      token
      refreshToken
      user {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

export default LOGIN;
