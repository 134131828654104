import React from 'react';
import PropTypes from 'prop-types';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import HomeStepIcon from '../step-icons/HomeStepIcon';

const HomeStepLabel = ({ title, description, StepIconComponent }) => (
  <StepLabel
    StepIconComponent={StepIconComponent}
    sx={{
      gap: { mobile: 3, desktop: 1, lg: 1 },
      py: 0,
      flexDirection: { desktop: 'column', lg: 'row' },
      '& .Mui-active': {
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.04)',
        },
      },
      '& .MuiStepLabel-iconContainer': {
        p: 0,
        border: '2px solid',
        borderRadius: '50%',
        borderColor: 'gray.A400',
        backgroundColor: 'transparent',
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.Mui-active': {
          borderColor: {
            mobile: 'primary.main',
            lg: 'common.white',
          },
          backgroundColor: { mobile: 'primary.main' },
        },
        '&.Mui-completed': {
          borderColor: 'primary.main',
          backgroundColor: 'primary.A100',
        },
      },
      '& .MuiStepLabel-label': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: { desktop: 'center', lg: 'flex-start' },
        color: 'gray.A400',
        '&.Mui-active': {
          color: { mobile: 'gray.A700', lg: 'common.white' },
          '& > :first-of-type': {
            color: { mobile: 'primary.main', lg: 'common.white' },
          },
        },
        '&.Mui-completed': {
          color: { mobile: 'gray.A700', lg: 'gray.A600' },
          '& > :first-of-type': {
            color: { mobile: 'gray.A700', lg: 'primary.main' },
          },
        },
      },
    }}
  >
    <Typography
      variant="small_2"
      fontWeight={700}
      textAlign={{ desktop: 'center', lg: 'left' }}
    >
      {title}
    </Typography>
    {description && (
      <Typography
        variant="small_1"
        textAlign={{ desktop: 'center', lg: 'left' }}
      >
        {description}
      </Typography>
    )}
  </StepLabel>
);

HomeStepLabel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  StepIconComponent: PropTypes.elementType,
};

HomeStepLabel.defaultProps = {
  description: null,
  StepIconComponent: HomeStepIcon,
};

export default HomeStepLabel;
