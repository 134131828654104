import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import TranslationProvider from '@fingo/lib/components/translation/TranslationProvider';
import SnackbarAlert from '@fingo/lib/contexts/snack-bar';
import MuiProvider from './providers/MuiProvider';
import ApolloProvider from './providers/ApolloProvider';
import IntercomProvider from './providers/IntercomProvider';
import App from './App';
import UtmCampaignProvider from './providers/UtmCampaignProvider';
import GoogleOAuthProvider from './providers/GoogleOAuthProvider';
import GoogleTagManagerProvider from './providers/GoogleTagManagerProvider';

const GlobalProviders = ({ children }) => (
  <MuiProvider>
    <ApolloProvider>
      <GoogleTagManagerProvider>
        <IntercomProvider>
          <GoogleOAuthProvider>{children}</GoogleOAuthProvider>
        </IntercomProvider>
      </GoogleTagManagerProvider>
    </ApolloProvider>
  </MuiProvider>
);

GlobalProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

const ContextProviders = ({ children }) => (
  <TranslationProvider>
    <UtmCampaignProvider>
      <SnackbarAlert />
      {children}
    </UtmCampaignProvider>
  </TranslationProvider>
);

ContextProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

const Fingo = () => (
  <React.StrictMode>
    <GlobalProviders>
      <ContextProviders>
        <App />
      </ContextProviders>
    </GlobalProviders>
  </React.StrictMode>
);

render(<Fingo />, document.getElementById('root'));
