import { gql } from '@apollo/client';

const GET_PREFERENCES = gql`
  query GetUserPreferences($country: ID!) {
    getUserPreferences(country: $country) {
      mail {
        id
        emailName
        isUnsubscribed
        displayName
      }
      whatsapp {
        id
        code
        isUnsubscribed
      }
    }
  }
`;

export default GET_PREFERENCES;
