import { gql } from '@apollo/client';
import { CompanyFields } from '../fragments';
import { CompanyDocumentsFields } from './fragment';

export const SET_NEW_PHONE = gql`
  mutation newPhone($newPhone: String!, $companyId: String!) {
    addCompanyPhoneNumber(newPhone: $newPhone, companyId: $companyId) {
      success
      message
    }
  }
`;

export const UPLOAD_COMPANY_DOCUMENT = gql`
  mutation UploadCompanyDocument(
    $companyId: ID!
    $file: Upload!
    $documentType: String!
    $year: Int
  ) {
    uploadCompanyDocument(
      companyId: $companyId
      file: $file
      documentType: $documentType
      year: $year
    ) {
      company {
        id
        documents {
          ...CompanyDocumentsFields
        }
      }
    }
  }
  ${CompanyDocumentsFields}
`;

export const GENERATE_PORTFOLIO_URL = gql`
  mutation GeneratePortfolioSummary(
    $companyId: Int!
    $product: String!
    $documentFormat: PortfolioFormatEnum!
    $portfolioType: PortfolioTypeEnum
    $startDate: Date
    $endDate: Date
    $currency: String
  ) {
    generatePortfolioSummary(
      companyId: $companyId
      product: $product
      documentFormat: $documentFormat
      portfolioType: $portfolioType
      startDate: $startDate
      endDate: $endDate
      currency: $currency
    ) {
      url
    }
  }
`;

export const REGISTER_MX_COMPANY = gql`
  mutation RegisterMxCompany($companyRfc: String!, $password: String!) {
    registerMxCompany(companyRfc: $companyRfc, password: $password) {
      response
      socialReason
    }
  }
`;

export const ADD_COMPANY_TO_SUPER_USER = gql`
  mutation addCompany($companyRut: String!) {
    addCompanyToSuperuser(companyRut: $companyRut) {
      user {
        id
        selectedCompany {
          ...CompanyFields
        }
      }
    }
  }
  ${CompanyFields}
`;

export const UPLOAD_CONTACTS_EXCEL = gql`
  mutation uploadContactsExcel($inputUploadFiles: Upload!, $companyId: Int!) {
    uploadContactsExcel(
      inputUploadFiles: $inputUploadFiles
      companyId: $companyId
    ) {
      message
      messageType
    }
  }
`;

export const RESOLVE_COMPANY_EVALUATION_RESTRICTIONS = gql`
  mutation resolveCompanyEvaluationRestrictions(
    $restrictions: [CompanyEvaluationRestrictionsInputType]!
  ) {
    resolveCompanyEvaluationRestrictions(restrictions: $restrictions) {
      companyEvaluationRestrictions {
        id
      }
    }
  }
`;

export const CHANGE_COMPANY_EVALUATION_RESTRICTION_STATUS = gql`
  mutation changeCompanyEvaluationRestrictionStatus(
    $restriction: Int!
    $action: String!
  ) {
    changeCompanyEvaluationRestrictionStatus(
      restriction: $restriction
      action: $action
    ) {
      companyEvaluationRestriction {
        id
      }
    }
  }
`;

export const UPDATE_BUREAUS = gql`
  mutation updateBureaus(
    $ids: [String]!
    $updateDicom: Boolean!
    $updateBusinessProfile: Boolean!
    $updateCurrentBehaviour: Boolean!
    $delay: Boolean!
  ) {
    updateBureaus(
      ids: $ids
      updateDicom: $updateDicom
      updateBusinessProfile: $updateBusinessProfile
      updateCurrentBehaviour: $updateCurrentBehaviour
      delay: $delay
    ) {
      success
    }
  }
`;

export const FETCH_PRESIGNED_URL = gql`
  mutation fetchPresignedUrl($globalId: String!, $fileAttributeLabel: String) {
    fetchPresignedUrl(
      globalId: $globalId
      fileAttributeLabel: $fileAttributeLabel
    ) {
      url
    }
  }
`;

export const CREATE_COMPANY_RESTRICTION = gql`
  mutation createCompanyRestriction(
    $requestRestriction: [CompanyRestrictionInputType]
  ) {
    createCompanyRestriction(requestRestriction: $requestRestriction) {
      success
      message
    }
  }
`;

export const CREATE_MASTER_ENTITY = gql`
  mutation createMasterEntity($rutWithDv: String!) {
    createMasterEntity(rutWithDv: $rutWithDv) {
      masterEntity {
        id
      }
    }
  }
`;

export const GENERATE_COMPANY_FRAMEWORK_CONTRACT = gql`
  mutation generateCompanyFrameworkContract(
    $companyId: String!
    $personeriaComments: String!
    $contractInfo: CompanyContractInfoInput!
    $legalRepresentatives: [CompanyLegalRepresentativesInput]
    $endorsements: [CompanyEndorsementsInput]
    $contractRequestId: String
  ) {
    generateCompanyFrameworkContract(
      companyId: $companyId
      contractInfo: $contractInfo
      legalRepresentatives: $legalRepresentatives
      endorsements: $endorsements
      personeriaComments: $personeriaComments
      contractRequestId: $contractRequestId
    ) {
      frameworkContract {
        id
        globalAppId
        createdAt
        lastFile
      }
    }
  }
`;

export const UPLOAD_EXTERNAL_CONTRACT = gql`
  mutation uploadExternalContract(
    $legalRepresentativeName: String!
    $legalRepresentativeEmail: String!
    $file: Upload!
    $contractRequestId: Int
    $contractName: String
  ) {
    uploadExternalContract(
      legalRepresentativeName: $legalRepresentativeName
      legalRepresentativeEmail: $legalRepresentativeEmail
      file: $file
      contractRequestId: $contractRequestId
      contractName: $contractName
    ) {
      companyFrameworkContractRequest {
        id
        currentContractUploadRequest {
          id
          status
          approvedRequest
          documentToUpload {
            ...CompanyDocumentsFields
          }
        }
      }
    }
  }
  ${CompanyDocumentsFields}
`;

export const RESOLVE_CONTRACT_UPLOAD_REQUEST = gql`
  mutation resolveContractUploadRequest(
    $contractUploadRequestId: String!
    $approved: Boolean!
    $comment: String
  ) {
    resolveContractUploadRequest(
      contractUploadRequestId: $contractUploadRequestId
      approved: $approved
      comment: $comment
    ) {
        contractUploadRequest {
          id
          status
          documentToUpload {
            ...CompanyDocumentsFields
          }
          contractRequest {
            id
            status
          }
      }
    }
  }
  ${CompanyDocumentsFields}
`;

export const SEND_TO_REVISION_CONTRACT = gql`
  mutation sendToRevisionContract(
    $contractRequestId: Int
    $file: Upload!
  ) {
    sendToRevisionContract(
      file: $file
      contractRequestId: $contractRequestId
    ) {
      companyFrameworkContractRequest {
        id
        status
        companyContractType
        currentContractUploadRequest {
          id
          status
          approvedRequest
          documentToUpload {
            ...CompanyDocumentsFields
          }
        }
      }
  }
}
  ${CompanyDocumentsFields}
`;

export const CHECK_CONTRACT_STATUS = gql`
  mutation checkContractStatus($contractRequestId: String!) {
    checkContractStatus(contractRequestId: $contractRequestId) {
    contractRequest {
        id
        status
        companyContractType
        currentContractUploadRequest {
          id
          status
          approvedRequest
          documentToUpload {
            ...CompanyDocumentsFields
          }
        }
      }
    }
}
    ${CompanyDocumentsFields}
`;
export const CREATE_CONTRACT_REQUEST = gql`
  mutation createContractRequest($companyId: Int!) {
    createContractRequest(companyId: $companyId) {
      contractRequest {
        id
        status
        companyContractType
      }
    }
  }
`;

export const SOLVE_CONTRACT_REQUEST = gql`
  mutation solveContractRequest($contractRequestId: Int!) {
    solveContractRequest(contractRequestId: $contractRequestId) {
      contractRequest {
        id
        status
        companyContractType
      }
    }
  }
`;
