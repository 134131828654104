import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import EDIT_BANK_ACCOUNT from '@fingo/lib/graphql/mutations/edit-bank-account';
import { formatRut } from '@fingo/lib/helpers';
import GET_USER from '@fingo/lib/graphql/queries/get-user';
import { useAvailableBanks, useBankAccountTypes, useGetUser } from '@fingo/lib/hooks';
import {
  Typography,
  TextField,
  FormControl,
  Button,
  IconButton,
  Select,
  MenuItem,
  Grid,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import makeStyles from '@mui/styles/makeStyles';
import ValidationTextField from '@fingo/lib/components/inputs/ValidationTextFieldInput';
import getErrorMessage from './helper';

const useStyles = makeStyles({
  label: {
    marginBottom: '0.5rem',
  },
  input: {
    width: '100%',
    marginBottom: '1rem',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  error: {
    marginBottom: '0.5rem',
    marginLeft: '0.5rem',
  },
});

const EditAccount = ({ cancel, onAccountEdit, setDialogAlert, accounts, account }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { selectedCompany } = useGetUser();
  const country = useGetCountryFromUrl();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { defaultMatches: true });
  const { accountTypes, loadingAccountTypes } = useBankAccountTypes(country.id);
  const { banks } = useAvailableBanks(country.id);
  const [editBankAccount, { loading }] = useMutation(
    EDIT_BANK_ACCOUNT,
    {
      refetchQueries: [GET_USER],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        onAccountEdit(data.editBankAccount.bankAccount);
        setDialogAlert({
          message: 'Cuenta actualizada exitosamente',
          severity: 'success',
          show: true,
          timeout: 10000,
        });
      },
      onError: () => {
        onAccountEdit(null);
      },
    },
  );
  const [formData, setFormData] = useState({
    number: { value: parseInt(account.accountNumber, 10), error: '' },
    name: { value: account.accountName, error: '' },
    email: { value: account.accountEmail, error: '' },
    bank: { value: account.bankName, error: '' },
    accountType: { value: account.accountType, error: '' },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((oldData) => ({ ...oldData, [name]: { ...oldData[name], value } }));
    if (name === 'bank' || name === 'accountType') {
      setDialogAlert({
        message: '',
        severity: '',
        show: false,
      });
    }
  };

  const handleEdit = () => {
    const { number, name, email, bank, accountType } = formData;
    const errorMessage = getErrorMessage(formData, accounts);
    if (errorMessage) {
      setDialogAlert({
        message: errorMessage,
        severity: 'error',
        show: true,
      });
      return;
    }
    setDialogAlert({
      message: '',
      severity: '',
      show: false,
    });
    editBankAccount({
      variables: {
        id: account.id,
        bankName: bank.value,
        accountEmail: email.value,
        accountName: name.value,
        accountNumber: number.value,
        accountType: accountType.value,
      },
    });
  };

  return (
    <>
      <Grid container alignItems="center" sx={{ marginBottom: '1rem' }}>
        <Grid item xs={2}>
          <IconButton onClick={cancel} size="large">
            <ArrowBackIos />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography color="primary" variant="h6">Edita tu cuenta bancaria</Typography>
        </Grid>
      </Grid>
      <Typography className={classes.label}><b>Rut</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="rut"
        value={formatRut(selectedCompany.rut)}
        disabled
      />
      <Typography className={classes.label}><b>Titular de la cuenta</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="name"
        value={formData.name.value}
        error={formData.name.error !== ''}
        helperText={formData.name.error}
        onChange={handleChange}
      />
      <Typography className={classes.label}><b>Número de la cuenta</b></Typography>
      <TextField
        className={classes.input}
        variant="outlined"
        name="number"
        type="number"
        value={formData.number.value}
        error={formData.number.error !== ''}
        helperText={formData.number.error}
        onChange={handleChange}
      />
      <Typography className={classes.label}><b>Banco</b></Typography>
      <FormControl variant="outlined" fullWidth>
        <Select
          className={classes.input}
          name="bank"
          value={formData.bank.value}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>Elige un banco</MenuItem>
          {banks?.getBankOptionsByCountry.map((bank) => (
            <MenuItem key={bank.name} value={bank.name}>{bank.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography className={classes.label}><b>Tipo de cuenta</b></Typography>
      <FormControl variant="outlined" fullWidth>
        <Select
          className={classes.input}
          name="accountType"
          value={formData.accountType.value}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>Elige un tipo de cuenta</MenuItem>
          {!loadingAccountTypes && accountTypes?.getBankAccountTypesByCountry.map((type) => (
            <MenuItem key={type.name} value={type.name}>{type.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography className={classes.label}><b>Email</b></Typography>
      <ValidationTextField
        className={classes.input}
        variant="outlined"
        name="email"
        value={formData.email.value}
        onChange={handleChange}
        validationtype="email"
      />
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            alignSelf: isMobile ? 'center' : 'flex-end',
          }}
          size="small"
          onClick={handleEdit}
          endIcon={loading ? <CircularProgress size={16} /> : null}
          disabled={loading}
        >
          Editar cuenta
        </Button>
      </div>
    </>
  );
};

EditAccount.propTypes = {
  cancel: PropTypes.func.isRequired,
  onAccountEdit: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    bankName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
  })).isRequired,
  setDialogAlert: PropTypes.func.isRequired,
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    accountEmail: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditAccount;
