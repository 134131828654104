import { gql } from '@apollo/client';
import { NotificationMenuFields, UserFields } from '../fragments';

const GET_USER = gql`
  query GetUser {
    getUser {
      ...UserFields
      ...NotificationMenuFields
    }
  }
  ${UserFields}
  ${NotificationMenuFields}
`;

export default GET_USER;
