import { gql } from '@apollo/client';
import { UserFields } from '../fragments';
import { UserIdentification } from './fragments';

export const SII_CREDENTIAL_EXPERIMENT = gql`
  mutation SiiCredentialExperiment($companyRut: String!) {
    siiCredentialExperiment(companyRut: $companyRut) {
      success
    }
  }
`;

export const UPDATE_ACEPTA_USER_AND_ACCEPT_TERMS = gql`
  mutation updateAceptaUserAndAcceptTerms(
    $email: String!
    $phoneNumber: String!
    $password: String!
    $companyRutAcceptingTerms: String!
  ) {
    updateAceptaUserAndAcceptTerms(
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      companyRutAcceptingTerms: $companyRutAcceptingTerms
    ) {
      token
      refreshToken
      user {
        email
        firstName
        lastName
        companyName
        phoneNumber
        companies {
          id
          rut
          invoiceProvider {
            id
            name
            integrated
            iconUrl
          }
        }
      }
      actualCompanyRut
      companies {
        rut
        name
        acceptedTerms
      }
    }
  }
`;

export const REGISTER_DEMO_STEP = gql`
  mutation registerDemoStep($clickedButton: String!) {
    registerDemoStep(clickedButton: $clickedButton) {
      response
    }
  }
`;

export const REGISTER_MOBILE_LOGIN = gql`
  mutation RegisterMobileLogin($companyRut: Int!) {
    registerMobileLogin(companyRut: $companyRut) {
      success
    }
  }
`;

export const UPDATE_CONTACT_INFO = gql`
  mutation UpdateContactInfo($email: String!, $phoneNumber: String!) {
    updateContactInfo(email: $email, phoneNumber: $phoneNumber) {
      success
      message
    }
  }
`;

export const MARK_TUTORIAL_SEEN = gql`
  mutation MarkTutorialSeen($tutorialCode: String!) {
    markTutorialAsSeen(tutorialCode: $tutorialCode) {
      user {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

export const ADD_BANNED_COMPANY = gql`
  mutation addBannedCompany($companyRut: String!) {
    addBannedCompany(companyRut: $companyRut) {
      success
      message
    }
  }
`;

export const ERASE_BANNED_COMPANY = gql`
  mutation eraseBannedCompany($companyRut: String!) {
    eraseBannedCompany(companyRut: $companyRut) {
      success
      message
    }
  }
`;

export const REGISTER_USER_ACTIONS = gql`
  mutation CreateUserTrackMetric($actions: [UserActionType]!) {
    createUserTrackMetric(actions: $actions) {
      success
    }
  }
`;

export const USER_INTERACTION_REGISTER = gql`
  mutation userInteractionRegister($action: UserInteractionInputType!) {
    userInteractionRegister(action: $action) {
      interaction {
        id
      }
    }
  }
`;

export const REGISTER_SENEGOCIA = gql`
  mutation SenegociaRegister(
    $token: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $phoneNumber: String!
    $isOrdering: Boolean!
    $hasDummyPassword: Boolean!
  ) {
    senegociaRegister(
      token: $token
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      phoneNumber: $phoneNumber
      isOrdering: $isOrdering
      hasDummyPassword: $hasDummyPassword
    ) {
      response
    }
  }
`;

export const GET_USER_TOKEN = gql`
  mutation getUserToken($userId: Int!, $tokenMinutes: Int) {
    getUserToken(userId: $userId, tokenMinutes: $tokenMinutes) {
      token
    }
  }
`;

export const ASSIGN_EXECUTIVE_TO_COMPANY = gql`
  mutation assignExecutiveToCompany(
    $userId: String!
    $assignationType: String!
    $masterEntityId: String!
  ) {
    assignExecutiveToCompany(
      userId: $userId
      assignationType: $assignationType
      masterEntityId: $masterEntityId
    ) {
      masterEntity {
        id
        company {
          id
          executiveAssigned {
            ...UserIdentification
          }
        }
      }
    }
  }
  ${UserIdentification}
`;

export const SEND_EMAIL_VERIFICATION_MAIL = gql`
  mutation sendEmailVerificationMail {
    sendEmailVerificationMail {
      success
    }
  }
`;

export const SEND_PHONE_VERIFICATION_CODE = gql`
  mutation {
    sendPhoneVerificationCode {
      user {
        id
        hasActivePhoneVerificationCode @client
        phoneverification {
          id
          expirationDate
        }
      }
    }
  }
`;

export const VERIFY_PHONE = gql`
  mutation verifyPhone($code: String!) {
    verifyPhone(code: $code) {
      user {
        id
        isPhoneVerified
      }
    }
  }
`;

export const UPLOAD_CONTACTABILITY_DOCUMENT_UPLOAD = gql`
  mutation contactabilityDocumentUpload($upload: Upload!) {
    contactabilityDocumentUpload(upload: $upload) {
      request {
        id
      }
    }
  }
`;

export const UPDATE_SELECTED_COMPANY_BY_RUT = gql`
  mutation updateSelectedCompanyByRut($companyRut: String!) {
    updateSelectedCompanyByRut(companyRut: $companyRut) {
      user {
        id
      }
    }
  }
`;
