import { gql } from '@apollo/client';

const CREATE_BANK_ACCOUNT = gql`
  mutation CreateBankAccount(
    $accountEmail: String!
    $accountName: String!
    $accountNumber: String!
    $accountRut: String!
    $accountType: String!
    $bankName: String!
    $currency: String
  ) {
    createBankAccount(
      accountEmail: $accountEmail
      accountName: $accountName
      accountNumber: $accountNumber
      accountRut: $accountRut
      accountType: $accountType
      bankName: $bankName
      currency: $currency
    ) {
      bankAccountCreated {
        id
        accountEmail
        accountName
        accountNumber
        accountRut
        accountType
        bankName
        active
        currency {
          id
          isoCode
        }
      }
    }
  }
`;

export default CREATE_BANK_ACCOUNT;
