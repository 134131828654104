import { gql } from '@apollo/client';
import {
  MasterEntityBasicField,
  RiskBlacklistFields,
} from '../customers/fragment';
import { UserIdentification } from '../user/fragments';

const CollectionMasterEntityFragment = gql`
  fragment CollectionMasterEntityFragment on MasterEntityType {
    ...MasterEntityBasicField
    riskBlacklist {
      ...RiskBlacklistFields
    }
    debtor {
      id
      collectionPriority(ownerId: $inCollection) {
        id
        debtor {
          id
        }
        priority {
          id
          value
        }
      }
      collector {
        ...UserIdentification
      }
    }
  }
  ${MasterEntityBasicField}
  ${UserIdentification}
  ${RiskBlacklistFields}
`;

export default CollectionMasterEntityFragment;
