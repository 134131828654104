import { gql } from '@apollo/client';
import { UserIdentification } from '@fingo/lib/graphql/user/fragments';

const COLLECT = gql`
  mutation Collect($collectionManagerIds: [Int]!, $paymentDatetime: DateTime!) {
    collect(
      collectionManagerIds: $collectionManagerIds
      paymentDatetime: $paymentDatetime
    ) {
      collectionManagers {
        id
        collector {
          ...UserIdentification
        }
        collectionPriority {
          value
        }
        actions {
          id
          comment
          createdAt
          actionType
          contacts {
            id
            name
            position
            email
            phoneNumber
          }
          author {
            ...UserIdentification
          }
        }
      }
    }
  }
  ${UserIdentification}
`;

export default COLLECT;
