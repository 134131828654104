import { gql } from '@apollo/client';
import { CompanyDocumentsFields } from '@fingo/lib/graphql/customers/fragment';
import CollectionMasterEntityFragment from '@fingo/lib/graphql/fragments/collection-master-entity';

const KOBROS_GET_MASTER_ENTITY = gql`
  query kobrosGetMasterEntity($masterEntityId: Int!, $inCollection: Int) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      ...CollectionMasterEntityFragment
      debtor {
        id
        classification {
          id
          code
        }
      }
      contacts(first: 1, showHidden: false) {
        edges {
          node {
            id
            name
            position
            email
            phoneNumber
          }
        }
      }
      company {
        id
        rut
        documents {
          ...CompanyDocumentsFields
        }
      }
    }
  }
  ${CompanyDocumentsFields}
  ${CollectionMasterEntityFragment}
`;

export default KOBROS_GET_MASTER_ENTITY;
