import React from 'react';
import PropTypes from 'prop-types';
import { StepConnector, StepContext } from '@mui/material';

const ArrowStepConnector = ({ activeStep }) => {
  const { index } = React.useContext(StepContext);
  const actualStep = index - 1;
  const isCompleted = actualStep < activeStep;
  const isActive = actualStep === activeStep;
  const isNextCompleted = index < activeStep;
  const isNextActive = index === activeStep;

  return (
    <StepConnector
      sx={(theme) => ({
        position: 'relative',
        height: 72,
        flex: 'unset',
        '& .MuiStepConnector-line': {
          display: 'none',
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '50%',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '38px 0px 38px 20px',
          borderColor: (() => {
            if (isCompleted && isNextCompleted) {
              return `transparent transparent transparent ${theme.palette.primary.main}`;
            }
            if (!isCompleted && !isNextCompleted) {
              return `transparent transparent transparent ${theme.palette.grey.A400}`;
            }
            return 'transparent';
          })(),
          zIndex: 0,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '50%',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '34px 0px 34px 18px',
          borderColor: (() => {
            if (isCompleted && (isNextCompleted || isNextActive)) {
              return `transparent transparent transparent ${theme.palette.primary.A100}`;
            }
            if (isActive && !isNextCompleted) {
              return `transparent transparent transparent ${theme.palette.primary.main}`;
            }
            return `transparent transparent transparent ${theme.palette.common.white}`;
          })(),
          zIndex: 1,
        },
      })}
    />
  );
};

ArrowStepConnector.propTypes = {
  activeStep: PropTypes.number.isRequired,
};

export default ArrowStepConnector;
