import { gql } from '@apollo/client';

const REQUEST_COLLECTION_PLAN_DEMO = gql`
  mutation RequestCollectionPlanDemo(
    $name: String!
    $phoneNumber: String!
    $email: String!
  ) {
    requestCollectionPlanDemo(
      name: $name
      phoneNumber: $phoneNumber
      email: $email
    ) {
      success
    }
  }
`;

export default REQUEST_COLLECTION_PLAN_DEMO;
