import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Typography, keyframes } from '@mui/material';

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const HomeButton = ({ id, startIcon, mostUsed, onClick, label }) => (
  <Button
    id={id}
    variant="home"
    onClick={onClick}
    startIcon={startIcon}
    sx={{
      height: { mobile: 108, desktop: 88 },
      position: 'relative',
    }}
    fullWidth
  >
    {mostUsed && (
      <Typography
        variant="small_2"
        position="absolute"
        top={{ mobile: '-20px', desktop: '-10px' }}
        right={{ mobile: '4px', desktop: '-12px' }}
        py={0.5}
        px={1.5}
        textTransform="uppercase"
        fontWeight={500}
        bgcolor="gray.A700"
        color="common.white"
        borderRadius={2}
        sx={{
          animation: `${scaleAnimation} 2s ease-in-out infinite`,
        }}
      >
        más usado
      </Typography>
    )}
    {label}
  </Button>
);

HomeButton.propTypes = {
  id: PropTypes.string.isRequired,
  startIcon: PropTypes.node,
  mostUsed: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
};

HomeButton.defaultProps = {
  startIcon: null,
  mostUsed: false,
};

export default HomeButton;
