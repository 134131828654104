import { useQuery } from '@apollo/client';
import { preofferSelectedQuery } from '../../apollo/cache/localQuery';
import PREOFFER_SHOPPING_CART from '../../graphql/shopping/queries/preoffer-shopping-cart';

const usePreofferShoppingCart = () => {
  const { data: { selectedPreofferIds } } = useQuery(preofferSelectedQuery);
  const { data: shoppingCartData, loading } = useQuery(
    PREOFFER_SHOPPING_CART,
    { variables: { invoicesId: selectedPreofferIds } },
  );
  const thisShoppingCartData = shoppingCartData?.preofferShoppingCart;
  return {
    shoppingCartData: thisShoppingCartData,
    disabledShowCart: false,
    loading,
  };
};

export default usePreofferShoppingCart;
