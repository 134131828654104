import React from 'react';
import PropTypes from 'prop-types';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const HomeSummaryListItem = ({ id, amount, onClick, icon, count, label }) => {
  const isMobile = useIsMobile('desktop');

  return (
    <ListItem
      key={id}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
      }}
      disableGutters
    >
      {!isMobile && <ListItemIcon sx={{ minWidth: 32 }}>{icon}</ListItemIcon>}
      <Typography
        variant="xlarge_1"
        fontWeight={500}
        color="gray.main"
        width={40}
        textAlign="right"
      >
        {count}
      </Typography>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          variant: 'small_2',
          fontWeight: 600,
          color: 'gray.main',
          width: 77,
          onClick,
          mr: 'auto',
          sx: {
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        }}
      />
      <Typography variant="medium_2" color="gray.A800">
        {amount}
      </Typography>
      <IconButton onClick={onClick}>
        <KeyboardArrowRightIcon color="primary" />
      </IconButton>
    </ListItem>
  );
};

HomeSummaryListItem.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  count: PropTypes.number.isRequired,
  amount: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HomeSummaryListItem;
