import { gql } from '@apollo/client';

const GET_COLLECTION_PRIORITIES_OPTIONS = gql`
  query GetCollectionPrioritiesOptions {
    getCollectionPrioritiesOptions {
      value
      label
    }
  }
`;

export default GET_COLLECTION_PRIORITIES_OPTIONS;
