import { useMutation } from '@apollo/client';
import { useMemo } from 'react';
import OTP_LOGIN from '../graphql/mutations/otp-login';

const useSendOtp = (params, email, onCompleted) => {
  const [otpLogin, { loading, error }] = useMutation(
    OTP_LOGIN,
    {
      variables: {
        clientId: params.clientId,
        email,
      },
      onCompleted,
    },
  );
  const errorMessage = useMemo(() => {
    if (error) {
      return 'Este usuario no ha sido registrado por Office Banking. Reintente usando el correo correcto.';
    }
    return '';
  }, [error]);
  return [otpLogin, loading, errorMessage];
};

export default useSendOtp;
