import React from 'react';
import { numberToMoney } from '@fingo/lib/helpers';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useCreditLine from '@fingo/lib/hooks/useCreditLine';

const CreditLineSlider = () => {
  const creditLine = useCreditLine();

  const hasCreditLine = !!creditLine?.limitAmount;
  const rate = hasCreditLine
    ? creditLine.creditUsed / creditLine.limitAmount
    : 0;
  const value = Math.min(rate, 1) * 100;
  const currency = creditLine?.currency?.isoCode;

  const creditUsed = hasCreditLine
    ? numberToMoney(creditLine.creditUsed, currency)
    : '$--';

  const availableAmount = hasCreditLine
    ? numberToMoney(creditLine.limitAmount - creditLine.creditUsed, currency)
    : '$--';

  return (
    <Stack alignItems="center" width={1} spacing={1}>
      <Slider
        variant="determinate"
        value={value}
        sx={{
          borderRadius: 4,
          height: 12,
          p: 0,
          cursor: 'default',
          '& .MuiSlider-rail': { bgcolor: 'gray.A200' },
          '& .MuiSlider-thumb': {
            height: 13,
            width: 13,
            '&::after': { display: 'none' },
          },
        }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width={1}
      >
        <Typography variant="small_3" fontWeight={600} color="gray.A700">
          {creditUsed}
        </Typography>
        {!hasCreditLine && (
          <Typography variant="small_2" fontWeight={600} color="gray.A400">
            Aún no tiene linea
          </Typography>
        )}
        <Typography variant="small_3" fontWeight={600} color="gray.A700">
          {availableAmount}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CreditLineSlider;
