import SelectableImageCard from '@fingo/lib/components/cards/SelectableImageCard';
import ItemsGrid from '@fingo/lib/components/grids/ItemsGrid';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React from 'react';

const CredentialProviderMiniSelector = ({
  integratedProviders,
  selectedProvider,
  onProviderClick,
}) => (
  <Stack direction="column" alignItems="center" spacing={2}>
    <ItemsGrid
      items={integratedProviders}
      xsValue={0}
      renderItem={(provider) => (
        <SelectableImageCard
          item={{
            id: provider.id,
            imageUrl: provider.iconUrl,
            label: provider.name,
          }}
          onClick={() => onProviderClick(provider)}
          isSelected={selectedProvider?.id === provider.id}
          sx={{
            height: 40,
            width: 80,
            display: 'flex',
            padding: 0,
            margin: 0,
          }}
        />
      )}
    />
  </Stack>
);

CredentialProviderMiniSelector.propTypes = {
  integratedProviders: PropTypes.arrayOf(
    PropTypes.shape({
      iconUrl: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onProviderClick: PropTypes.func.isRequired,
  selectedProvider: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    iconUrl: PropTypes.string,
    integrated: PropTypes.bool.isRequired,
    requiresEmail: PropTypes.bool.isRequired,
    credentialProviderType: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

CredentialProviderMiniSelector.defaultProps = {
  selectedProvider: null,
};

export default CredentialProviderMiniSelector;
