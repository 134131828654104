import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import React, { memo, useMemo } from 'react';
import HomeButtons from '../components/HomeButtons';
import HomeCarousel from '../components/carousels/HomeCarousel';
import HomeContainer from '../components/containers/HomeContainer';
import HomeJoyrideDialog from '../components/dialogs/HomeJoyrideDialog';
import CreditLinePaper from '../components/papers/CreditLinePaper';
import ExecutiveDesktopPaper from '../components/papers/ExecutiveDesktopPaper';
import ExecutiveMobilePaper from '../components/papers/ExecutiveMobilePaper';
import HomeSummaryPaper from '../components/papers/HomeSummaryPaper';
import HomeTitle from '../components/titles/HomeTitle';
import HomeTutorial from '../components/tutorials/HomeTutorial';

const Home2 = () => {
  const isMobile = useIsMobile();
  const country = useGetCountryFromUrl();

  const content = useMemo(() => {
    if (country.name === 'Chile') {
      return (
        <Box
          display="grid"
          gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 446px' }}
          gridTemplateRows={{ mobile: '305px 1fr 1fr', desktop: '180px 1fr' }}
          gap={{ mobile: 3, desktop: 4 }}
        >
          <Box
            id="home-carousel"
            gridColumn={{ mobile: '1', desktop: '1 / 2' }}
            gridRow={{ mobile: '1', desktop: '1 / 2' }}
            height={{ desktop: 180 }}
          >
            <HomeCarousel />
          </Box>
          <Box
            id="home-summary-paper"
            gridColumn={{ mobile: '1', desktop: '2 / 3' }}
            gridRow={{ mobile: '2', desktop: '1 / 3' }}
          >
            <HomeSummaryPaper />
          </Box>
          <Box
            id="home-executive-paper"
            gridColumn={{ mobile: '1', desktop: '1 / 2' }}
            gridRow={{ mobile: '3', desktop: '2 / 3' }}
            height={{ desktop: 135 }}
          >
            {isMobile ? <ExecutiveMobilePaper /> : <ExecutiveDesktopPaper />}
          </Box>
        </Box>
      );
    }
    return (
      <Stack
        direction={{ mobile: 'column-reverse', desktop: 'row' }}
        spacing={4}
        width={1}
      >
        <Stack flexGrow={1} spacing={4}>
          <Box id="home-carousel" height={{ desktop: 180 }}>
            <HomeCarousel />
          </Box>
          <Box id="home-executive-paper" height={{ desktop: 135 }}>
            {isMobile ? <ExecutiveMobilePaper /> : <ExecutiveDesktopPaper />}
          </Box>
        </Stack>
        <Stack spacing={4} width={{ mobile: 1, desktop: 446 }}>
          <CreditLinePaper />
        </Stack>
      </Stack>
    );
  }, [country, isMobile]);
  return (
    <>
      <HomeContainer>
        <HomeTitle />
        <HomeButtons />
        <Divider sx={{ borderColor: 'secondary.A400' }} />
        <HomeTutorial />
        {content}
      </HomeContainer>
      <HomeJoyrideDialog />
    </>
  );
};

export default memo(Home2);
