import React from 'react';
import { t } from 'i18next';
import UploadDocumentButton from '@fingo/lib/components/buttons/UploadDocumentButton';
import AmountWithIvaCell from '@fingo/lib/components/cells/AmountWithIvaCell';
import CompanyCell from '@fingo/lib/components/cells/CompanyCell';
import DateCell from '@fingo/lib/components/cells/DateCell';
import FolioCell from '@fingo/lib/components/cells/FolioCell';
import PendingDocumentsManagerCell from '@fingo/lib/components/cells/PendingDocumentsManagerCell';
import formatDecimal from '@fingo/lib/helpers/formatters';
import DateToPayEditCell from '@fingo/lib/components/dataGridCells/DateToPayEditCell';
import TransferConditionCell from '../components/cells/TransferConditionCell';

export const HEADERS_BY_COUNTRY = {
  Mexico: [
    'folio',
    'dteType_Code',
    'dateIssued',
    'receiver_sortable',
    'amountWithIva',
    'offer_MonthlyRate',
    'offer_DefaultRate',
    'dateToPay',
    'uploadDocuments',
  ],
  Chile: [
    'folio',
    'dteType_Code',
    'dateIssued',
    'receiver_sortable',
    'amountWithIva',
    'offer_MonthlyRate',
    'offer_DefaultRate',
    'offer_pendingDocuments',
    'offerTransferCondition',
    'dateToPay',
    'uploadDocuments',
  ],
};

export const COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
    disableColumnMenu: true,
    width: 105,
    renderCell: ({ row }) => <FolioCell invoice={row} />,
  },
  {
    field: 'dteType_Code',
    headerName: t('dte'),
    sortable: false,
    disableColumnMenu: true,
    width: 70,
    valueGetter: ({ row }) => row.dteType.name,
  },
  {
    field: 'receiver_sortable',
    headerName: 'Receptor',
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => <CompanyCell company={row.receiver} />,
  },
  {
    field: 'dateIssued',
    headerName: 'Emisión',
    disableColumnMenu: true,
    width: 105,
    renderCell: ({ row }) => <DateCell date={row.dateIssued} />,
  },
  {
    field: 'amountWithIva',
    headerName: 'Monto',
    disableColumnMenu: true,
    width: 110,
    renderCell: ({ row }) => <AmountWithIvaCell row={row} />,
  },
  {
    field: 'offer_MonthlyRate',
    headerName: 'Tasa',
    sortable: false,
    disableColumnMenu: true,
    width: 75,
    valueGetter: ({ row }) => {
      const { offer } = row;
      return formatDecimal(offer.monthlyRate);
    },
  },
  {
    field: 'offer_DefaultRate',
    headerName: 'Mora',
    sortable: false,
    disableColumnMenu: true,
    width: 60,
    valueGetter: ({ row }) => {
      const { offer } = row;
      return formatDecimal(offer.defaultRate);
    },
  },
  {
    field: 'dateToPay',
    headerName: 'Fecha de pago',
    sortable: false,
    disableColumnMenu: true,
    width: 145,
    renderEditCell: (params) => <DateToPayEditCell params={params} />,
    renderCell: (params) => <DateToPayEditCell params={params} />,
  },
  {
    field: 'offer_pendingDocuments',
    headerName: 'Condiciones de giro',
    type: 'string',
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    width: 140,
    renderCell: ({ row }) => (
      <PendingDocumentsManagerCell invoice={row} type="Operations" />
    ),
  },
  {
    field: 'offerTransferCondition',
    headerName: 'Ratificación',
    type: 'string',
    sortable: true,
    disableColumnMenu: true,
    width: 120,
    renderCell: ({ row }) => <TransferConditionCell offer={row.offer} />,
  },
  {
    field: 'uploadDocuments',
    type: 'actions',
    width: 65,
    renderCell: ({ row }) => <UploadDocumentButton invoiceId={row.id} />,
  },
];
