import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import useBooleanState from '@fingo/lib/hooks/useBooleanState';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import MARK_TUTORIAL_SEEN from '../graphql/mutations/mark-tutorial-as-seen';

const useTutorial = (tutorialCode, isReady = true) => {
  const [runGuide, toggleRunGuide] = useBooleanState(false);
  const user = useGetUser();
  const tutorials = user?.userseentutorialSet;

  const [markTutorialSeen] = useMutation(MARK_TUTORIAL_SEEN, {
    variables: { tutorialCode },
  });

  useEffect(() => {
    const selectedTutorial = tutorials.filter(
      (tutorial) => tutorial.tutorialCode === tutorialCode && tutorial.seen,
    );
    if (!selectedTutorial.length && isReady) {
      toggleRunGuide();
      markTutorialSeen();
    }
  }, [tutorials, tutorialCode, toggleRunGuide, isReady]);

  return [runGuide, toggleRunGuide];
};

export default useTutorial;
