import React from 'react';
import { useQuery } from '@apollo/client';
import { offerDateToPaySelectedQuery, offerSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { formatDayMonthYear } from '@fingo/lib/helpers';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MY_OFFERS from '../../../features/factoring/graphql/queries/my-offers';

const BySignatureOfferCartMessage = () => {
  const { data: { selectedOfferIds } } = useQuery(offerSelectedQuery);
  const { data: { selectedOfferDateToPay } } = useQuery(offerDateToPaySelectedQuery);
  const { data: offers } = useQuery(
    MY_OFFERS,
    {
      variables: { id_In: selectedOfferIds, first: 100 },
      skip: !selectedOfferIds.length,
    },
  );

  const paragraphs = [
    {
      title: 'Las siguientes facturas serán operadas',
      text: offers?.invoices.edges.map(({ node }) => [`Folio ${node.folio}`, `Fecha de pago: ${formatDayMonthYear(selectedOfferDateToPay[node.id])}`]),
    },
    {
      title: 'Al presionar el botón tu ejecutivo se pondrá en contacto contigo para firmar el contrato (o anexo de contrato) para finalizar la operación.',
      text: [],
    },
  ];
  return (
    <Grid container item>
      <Typography variant="h2" align="center" sx={{ width: '100%' }}>
        ¡Quiero ceder derechos de cobro y operar!
      </Typography>
      {paragraphs.map(({ title, text }) => (
        <React.Fragment key={title}>
          <Typography variant="h6" sx={{ width: '100%', mt: 2 }} lineHeight={1.6} fontWeight="500">
            {title}
          </Typography>
          {text?.map((t) => (
            <Box sx={{ mb: 1 }}>
              <Typography key={t[0]} variant="body1" sx={{ width: '100%' }} lineHeight={1.3}>{t[0]}</Typography>
              <Typography key={t[1]} variant="body1" sx={{ width: '100%' }} lineHeight={1.3}>{t[1]}</Typography>
            </Box>
          ))}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default BySignatureOfferCartMessage;
