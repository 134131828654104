import { gql } from '@apollo/client';

const OAUTH_LOGIN = gql`
  mutation oauth2Auth(
    $idToken: String!
    $provider: String!
    $utmCampaign: String
  ) {
    oauth2Auth(
      idToken: $idToken
      provider: $provider
      utmCampaign: $utmCampaign
    ) {
      payload
      refreshExpiresIn
      token
      refreshToken
    }
  }
`;

export default OAUTH_LOGIN;
