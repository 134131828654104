/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

const HomeJoyrideTooltip = (props) => {
  const {
    backProps,
    continuous,
    index,
    primaryProps,
    step,
    tooltipProps,
    size,
  } = props;
  const theme = useTheme();

  return (
    <Box
      component={Stack}
      spacing={1}
      {...tooltipProps}
      sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '16px',
        maxWidth: '300px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        position: 'relative',
      }}
    >
      {step.title && <Typography variant="medium_1">{step.title}</Typography>}
      <Typography variant="small_2">{step.content}</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {index > 0 && (
          <Button
            variant="text"
            {...backProps}
            sx={{
              ...theme.typography.medium_1,
              color: theme.palette.primary.main,
              textDecoration: 'underline',
            }}
          >
            Anterior
          </Button>
        )}
        {continuous && (
          <Button
            variant="text"
            {...primaryProps}
            sx={{
              ...theme.typography.medium_1,
              color: theme.palette.primary.main,
              textDecoration: 'underline',
              backgroundColor: theme.palette.common.white,
              ml: index > 0 ? 'inherit' : 'auto',
            }}
          >
            {index === size - 1 ? 'Listo' : 'Siguiente'}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default HomeJoyrideTooltip;
