import React from 'react';
import { useSelectedCompany } from '@fingo/lib/hooks';
import GET_SIMPLE_MASTER_ENTITIES from '@fingo/lib/graphql/queries/get-simple-master-entities';
import Contacts from '../../supplierAutomate/Contacts';

const MyClients = () => {
  const selectedCompany = useSelectedCompany();
  return (
    <Contacts
      queryContacts={GET_SIMPLE_MASTER_ENTITIES}
      queryVariables={{ companyClients: selectedCompany?.masterEntity.id }}
      title="Clientes"
      relation="clients"
    />
  );
};

export default MyClients;
