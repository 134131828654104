import { gql } from '@apollo/client';

const HOME_RECEIVED_DATA = gql`
  query HomeReceivedData(
    $dateIssued_Gte: Date
    $companyId: Int
    $dteType_CountryId: Int
  ) {
    rejectedInvoices: invoices(
      dateIssued_Gte: $dateIssued_Gte
      companyId: $companyId
      siiStatus: ["Rejected"]
    ) {
      totalCount
      aggregate(field: "amount_with_iva")
      edges {
        cursor
        node {
          id
        }
      }
    }
    receivedInvoices: invoices(
      dateIssued_Gte: $dateIssued_Gte
      receiverId: $companyId
      dteType_CountryId: $dteType_CountryId
    ) {
      totalCount
      aggregate(field: "amount_with_iva")
      edges {
        cursor
        node {
          id
        }
      }
    }
    creditNotesInvoices: invoices(
      dateIssued_Gte: $dateIssued_Gte
      companyId: $companyId
      dteType_Code_In: ["61"]
    ) {
      totalCount
      aggregate(field: "amount_with_iva")
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export default HOME_RECEIVED_DATA;
